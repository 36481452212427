export const state = () => ({
  userLocationPasses: [],
})

export const getters = {
  getUserLocationPasses: (state) => {
    return state.userLocationPasses
  },
}

export const mutations = {
  SET_USER_LOCATION_PASSs: (state, userLocationPasses) => {
    state.userLocationPasses = userLocationPasses
  },
}

export const actions = {
  setUserLocationPasses({ commit }, userLocationPasses) {
    commit('SET_USERLOCATIONPASSs', userLocationPasses)
  },

  async setupUserLocationPasses({ commit }) {
    const r = await this.$axios.$get(`/user-location-pass`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting userLocationPass')
    }
    commit('SET_USER_LOCATION_PASSs', r.data)
  },
}
