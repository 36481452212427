export class FundamentalSpaceType {
    id: number | undefined
	name: string 
	spaceTypeList: object | null

	constructor() {
		this.name = ""
		this.spaceTypeList = []
	}
}
