export class PreInvoiceContract {
  id: number | undefined
  preInvoice: object | null
  contract: object | null
  dateFrom: Date | string
  dateTo: Date | string

  constructor() {
    this.preInvoice = null
    this.contract = null
    this.dateFrom = ''
    this.dateTo = ''
  }
}
