export const state = () => ({
  companySpecificss: [],
})

export const getters = {
  getCompanySpecificss: (state) => {
    return state.companySpecificss
  },
}

export const mutations = {
  SET_COMPANY_SPECIFICSs: (state, companySpecificss) => {
    state.companySpecificss = companySpecificss
  },
}

export const actions = {
  setCompanySpecificss({ commit }, companySpecificss) {
    commit('SET_COMPANYSPECIFICSs', companySpecificss)
  },

  async setupCompanySpecificss({ commit }) {
    const r = await this.$axios.$get(`/company-specifics`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting companySpecifics')
    }
    commit('SET_COMPANY_SPECIFICSs', r.data)
  },
}
