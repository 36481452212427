import { ROLE } from '~/config/constants'

export const state = () => ({
  roles: [],
})

export const getters = {
  getRoles: (state, _getters, _rootState, rootGetters) => {
    const getUser = rootGetters['user/getUser']
    const role = getUser && getUser.role && getUser.role.roleId
    if (role === ROLE.SUPER_ADMIN) {
      return state.roles.filter(e => e.roleId !== ROLE.SUPER_ADMIN)
    } else if (role === ROLE.ADMIN) {
      return state.roles.filter(
        (e) => e.roleId !== ROLE.SUPER_ADMIN && e.roleId !== ROLE.ADMIN
      )
    } else {
      return []
    }
  },
}

export const mutations = {
  SET_ROLEs: (state, roles) => {
    state.roles = roles
  },
}

export const actions = {
  setRoles({ commit }, roles) {
    commit('SET_ROLEs', roles)
  },

  async setupRoles({ commit }) {
    const r = await this.$axios.$get(`/role`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting role')
    }
    commit('SET_ROLEs', r.data)
  },
}
