import { OneOfFeeUserLocationPassEntry } from '@/entities/oneOfFeeUserLocationPassEntry.ts'

export const state = () => ({
  oneOfFeeUserLocationPassEntry: new OneOfFeeUserLocationPassEntry(),
})

export const getters = {
  getOneOfFeeUserLocationPassEntry: (state) => {
    return state.oneOfFeeUserLocationPassEntry
  },
}

export const mutations = {
  SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY: (state, payload) => {
    state.oneOfFeeUserLocationPassEntry = payload
  },
}

export const actions = {
  setOneOfFeeUserLocationPassEntry({ commit }, payload) {
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', payload)
  },

  async getOneOfFeeUserLocationPassEntryById({ commit }, id) {
    const r = await this.$axios.$get(
      `/one-of-fee-user-location-pass-entry/${id}`
    )
    if (!r || !r.data) {
      throw new Error('error in response format: oneOfFeeUserLocationPassEntry')
    }
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', r.data)
  },

  async createOneOfFeeUserLocationPassEntry({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', payload)
    }
    const r = await this.$axios.$post(
      `/one-of-fee-user-location-pass-entry`,
      getters.getOneOfFeeUserLocationPassEntry
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: oneOfFeeUserLocationPassEntry'
      )
    }
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', r.data)
  },

  async saveOneOfFeeUserLocationPassEntry({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', payload)
    }
    const r = await this.$axios.$put(
      `/one-of-fee-user-location-pass-entry/${getters.getOneOfFeeUserLocationPassEntry.id}`,
      getters.getOneOfFeeUserLocationPassEntry
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: oneOfFeeUserLocationPassEntry'
      )
    }
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', r.data)
  },

  async deleteOneOfFeeUserLocationPassEntry({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', payload)
    }
    await this.$axios.$delete(
      `/one-of-fee-user-location-pass-entry/${getters.getOneOfFeeUserLocationPassEntry.id}`
    )
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRY', null)
  },
}
