export const state = () => ({
  companyTypes: [],
})

export const getters = {
  getCompanyTypes: (state) => {
    return state.companyTypes
  },
}

export const mutations = {
  SET_COMPANY_TYPES: (state, companyTypes) => {
    state.companyTypes = companyTypes
  },
}

export const actions = {
  setCompanyTypes({ commit }, companyTypes) {
    commit('SET_COMPANYTYPES', companyTypes)
  },

  async setupCompanyTypes({ commit }) {
    const r = await this.$axios.$get(`/company-type`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting companyType')
    }
    commit('SET_COMPANY_TYPES', r.data)
  },
}
