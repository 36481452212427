export const state = () => ({
  oneOfFeeUserLocationPassEntrys: [],
})

export const getters = {
  getOneOfFeeUserLocationPassEntrys: (state) => {
    return state.oneOfFeeUserLocationPassEntrys
  },
}

export const mutations = {
  SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRYS: (
    state,
    oneOfFeeUserLocationPassEntrys
  ) => {
    state.oneOfFeeUserLocationPassEntryS = oneOfFeeUserLocationPassEntrys
  },
}

export const actions = {
  setOneOfFeeUserLocationPassEntrys(
    { commit },
    oneOfFeeUserLocationPassEntrys
  ) {
    commit('SET_ONEOFFEEUSERLOCATIONPASSENTRYS', oneOfFeeUserLocationPassEntrys)
  },

  async setupOneOfFeeUserLocationPassEntrys({ commit, getters }) {
    if (
      !getters.getOneOfFeeUserLocationPassEntrys ||
      getters.getOneOfFeeUserLocationPassEntrys.length === 0
    ) {
      const r = await this.$axios.$get(`/one-of-fee-user-location-pass-entry`)
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting oneOfFeeUserLocationPassEntry'
        )
      }
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS_ENTRYS', r.data)
    }
  },
}
