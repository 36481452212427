export const state = () => ({
  contractExtrass: [],
})

export const getters = {
  getContractExtrass: (state) => {
    return state.contractExtrass
  },
}

export const mutations = {
  SET_CONTRACT_EXTRASS: (state, contractExtrass) => {
    state.contractExtrasS = contractExtrass
  },
}

export const actions = {
  setContractExtrass({ commit }, contractExtrass) {
    commit('SET_CONTRACTEXTRASS', contractExtrass)
  },

  async setupContractExtrass({ commit, getters }) {
    if (
      !getters.getContractExtrass ||
      getters.getContractExtrass.length === 0
    ) {
      const r = await this.$axios.$get(`/contract-extras`)
      if (!r || !r.data) {
        throw new Error('response format error: error getting contractExtras')
      }
      commit('SET_CONTRACT_EXTRASS', r.data)
    }
  },
}
