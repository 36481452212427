// const idSet = new Set()

// export function getConfirmID() {
//   let id = 0
//   do {
//     id = Math.floor(Math.random() * 65536)
//   } while (idSet.has(id))
//   return id
// }

// export default async function confirm(text, id = getConfirmID()) {
//   this.$bus.$emit('open-confirm-dialog', { text, id })
//   return await new Promise((resolve) => {
//     this.$bus.$on('confirm-dialog-done', ({ value, id }) => {
//       idSet.delete(id)
//       resolve({ value, id })
//     })
//   })
// }

import Vue from 'vue'
import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('VueConfirmDialog', VueConfirmDialog.default)
