export const state = () => ({
  supplier: {
    name: '',
  },
})

export const getters = {
  getSupplier: (state) => {
    return state.supplier
  },
}

export const mutations = {
  SET_SUPPLIER: (state, payload) => {
    state.supplier = payload
  },
}

export const actions = {
  setSupplier({ commit }, payload) {
    commit('SET_SUPPLIER', payload)
  },

  async getSupplierById({ commit }, id) {
    const r = await this.$axios.$get(`/supplier/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: supplier')
    }
    commit('SET_SUPPLIER', r.data)
  },

  async createSupplier({ commit }, payload) {
    if (!payload) {
      throw new Error('payload not sent')
    }
    const r = await this.$axios.$post(`/supplier`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: supplier')
    }
    commit('SET_SUPPLIER', r.data)
    return r.data
  },

  async saveSupplier({ commit, getters }, payload) {
    if (payload) {
      commit('SET_SUPPLIER', payload)
    }
    const r = await this.$axios.$put(
      `/supplier/${getters.getSupplier.id}`,
      getters.getSupplier
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: supplier')
    }
    commit('SET_SUPPLIER', r.data)
    return r.data
  },

  async deleteSupplier({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_SUPPLIER', payload)
    await this.$axios.$delete(`/supplier/${getters.getSupplier.id}`)
    commit('SET_SUPPLIER', null)
  },
}
