import { TaxRate } from '@/entities/taxRate.ts'

export const state = () => ({
  taxRate: new TaxRate(),
})

export const getters = {
  getTaxRate: (state) => {
    return state.taxRate
  },
}

export const mutations = {
  SET_TAX_RATE: (state, payload) => {
    state.taxRate = payload
  },
}

export const actions = {
  setTaxRate({ commit }, payload) {
    commit('SET_TAX_RATE', payload)
  },

  async getTaxRateById({ commit }, id) {
    const r = await this.$axios.$get(`/tax-rate/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: taxRate')
    }
    commit('SET_TAX_RATE', r.data)
  },

  async createTaxRate({ commit, getters }, payload) {
    if (payload) {
      commit('SET_TAX_RATE', payload)
    }
    const r = await this.$axios.$post(`/tax-rate`, getters.getTaxRate)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: taxRate')
    }
    commit('SET_TAX_RATE', r.data)
  },

  async saveTaxRate({ commit, getters }, payload) {
    if (payload) {
      commit('SET_TAX_RATE', payload)
    }
    const r = await this.$axios.$put(
      `/tax-rate/${getters.getTaxRate.id}`,
      getters.getTaxRate
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: taxRate')
    }
    commit('SET_TAX_RATE', r.data)
  },

  async deleteTaxRate({ commit, getters }, payload) {
    if (payload) {
      commit('SET_TAX_RATE', payload)
    }
    await this.$axios.$delete(`/tax-rate/${getters.getTaxRate.id}`)
    commit('SET_TAX_RATE', null)
  },
}
