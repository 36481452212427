import { PreInvoiceOneOfFee } from '@/entities/preInvoiceOneOfFee.ts'

export const state = () => ({
  preInvoiceOneOfFee: new PreInvoiceOneOfFee(),
})

export const getters = {
  getPreInvoiceOneOfFee: (state) => {
    return state.preInvoiceOneOfFee
  },
}

export const mutations = {
  SET_PRE_INVOICE_ONE_OF_FEE: (state, payload) => {
    state.preInvoiceOneOfFee = payload
  },
}

export const actions = {
  setPreInvoiceOneOfFee({ commit }, payload) {
    commit('SET_PRE_INVOICE_ONE_OF_FEE', payload)
  },

  async getPreInvoiceOneOfFeeById({ commit }, id) {
    const r = await this.$axios.$get(`/pre-invoice-one-of-fee/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: preInvoiceOneOfFee')
    }
    commit('SET_PRE_INVOICE_ONE_OF_FEE', r.data)
  },

  async createPreInvoiceOneOfFee({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE_ONE_OF_FEE', payload)
    }
    const r = await this.$axios.$post(
      `/pre-invoice-one-of-fee`,
      getters.getPreInvoiceOneOfFee
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: preInvoiceOneOfFee'
      )
    }
    commit('SET_PRE_INVOICE_ONE_OF_FEE', r.data)
  },

  async savePreInvoiceOneOfFee({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE_ONE_OF_FEE', payload)
    }
    const r = await this.$axios.$put(
      `/pre-invoice-one-of-fee/${getters.getPreInvoiceOneOfFee.id}`,
      getters.getPreInvoiceOneOfFee
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: preInvoiceOneOfFee')
    }
    commit('SET_PRE_INVOICE_ONE_OF_FEE', r.data)
  },

  async deletePreInvoiceOneOfFee({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE_ONE_OF_FEE', payload)
    }
    await this.$axios.$delete(
      `/pre-invoice-one-of-fee/${getters.getPreInvoiceOneOfFee.id}`
    )
    commit('SET_PRE_INVOICE_ONE_OF_FEE', null)
  },
}
