export const state = () => ({
  invoices: [],
})

export const getters = {
  getInvoices: (state) => {
    return state.invoices
  },
}

export const mutations = {
  SET_INVOICES: (state, invoices) => {
    state.invoices = invoices
  },
}

export const actions = {
  setInvoices({ commit }, invoices) {
    commit('SET_INVOICES', invoices)
  },

  async setupInvoices({ commit, getters }) {
    if (!getters.getInvoices || getters.getInvoices.length === 0) {
      const { data } = await this.$axios.$get(`/invoice`)
      data.forEach((el) => {
        try {
          if (el.dateCreated) {
            el.dateCreated = el.dateCreated.split('T')[0]
          }
        } catch (error) {
          console.error(error)
        }
        try {
          if (el.dueDate) {
            el.dueDate = el.dueDate.split('T')[0]
          }
        } catch (error) {
          console.error(error)
        }
      })
      commit('SET_INVOICES', data)
    }
  },
}
