import { Company } from '@/entities/company.ts'

export const state = () => ({
  company: new Company(),

  contracts: [],
  // For new payments, fetch
  paymentPreinvoices: [],
  paymentInvoices: [],
})

export const getters = {
  getCompany: (state) => {
    return state.company
  },

  getCompanyContracts: (state) => state.contracts,

  getPaymentPreinvoices: (state) => state.paymentPreinvoices,

  getPaymentInvoices: (state) => state.paymentInvoices,
}

export const mutations = {
  SET_COMPANY: (state, payload) => {
    state.company = payload
  },

  SET_CONTRACTS: (state, contracts) => {
    state.contracts = contracts
  },

  SET_PAYMENT_PRE_INVOICES: (state, preInvoices) => {
    state.paymentPreinvoices = preInvoices
  },

  SET_PAYMENT_INVOICES: (state, invoices) => {
    state.paymentInvoices = invoices
  },
}

export const actions = {
  setCompany({ commit, dispatch }, payload) {
    dispatch('resetCompany')
    commit('SET_COMPANY', payload)
  },

  async getCompanyByID(_, id) {
    const r = await this.$axios.$get(`/company/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: company')
    }
    return r.data
  },

  resetCompany({ commit }) {
    commit('SET_COMPANY', null)
    commit('SET_CONTRACTS', [])
    commit('SET_PAYMENT_PRE_INVOICES', [])
    commit('SET_PAYMENT_INVOICES', [])
  },

  async setupCompanyByID({ commit, dispatch, getters }, id) {
    if (getters.getCompany?.id === id) {
      return getters.getCompany
    }
    dispatch('resetCompany')
    const r = await this.$axios.$get(`/company/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: company')
    }
    commit('SET_COMPANY', r.data)
    return r.data
  },

  async createCompany({ commit }, payload) {
    const { data } = await this.$axios.$post(`/company`, payload)

    commit('SET_COMPANY', data)
    return data
  },

  async saveCompany({ commit }, payload) {
    const { data } = await this.$axios.$put(`/company/${payload.id}`, payload)
    commit('SET_COMPANY', data)
    return data
  },

  async deleteCompany({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_COMPANY', payload)
    await this.$axios.$delete(`/company/${getters.getCompany.id}`)
    commit('SET_COMPANY', null)
  },

  async setCompanyIfNotSet({ dispatch, getters }, companyID) {
    if (!companyID) {
      throw new Error('company id not set')
    }
    if (getters.getCompany?.id !== companyID) {
      await dispatch('setupCompanyByID', companyID)
    }
  },

  async setupContractsByCompanyID({ commit, dispatch }, companyID) {
    await dispatch('setCompanyIfNotSet', companyID)
    const { data } = await this.$axios.$get(
      `/company/${companyID}/contract/summary`
    )
    console.log(data)
    commit('SET_CONTRACTS', data)
  },

  async setupPaymentPreinvoices({ commit, dispatch }, companyID) {
    await dispatch('setCompanyIfNotSet', companyID)
    const { data } = await this.$axios.$get(
      `/company/${companyID}/pre-invoice-for-payment`
    )
    for (const el of data) {
      if (typeof el.dueDate === 'string' && el.dueDate.length > 10) {
        el.dueDate = el.dueDate.split('T')[0]
      }
      if (typeof el.issueDate === 'string' && el.issueDate.length > 10) {
        el.issueDate = el.issueDate.split('T')[0]
      }
      el.notPaid = el.amount - el.paidAmount
    }
    commit('SET_PAYMENT_PRE_INVOICES', data)
  },
  async setupPaymentInvoices({ commit, dispatch }, companyID) {
    await dispatch('setCompanyIfNotSet', companyID)
    const { data } = await this.$axios.$get(
      `/company/${companyID}/invoice-for-payment`
    )
    for (const el of data) {
      if (typeof el.dueDate === 'string' && el.dueDate.length > 10) {
        el.dueDate = el.dueDate.split('T')[0]
      }
      if (typeof el.issueDate === 'string' && el.issueDate.length > 10) {
        el.issueDate = el.issueDate.split('T')[0]
      }
      el.notPaid = el.amount - el.paidAmount
    }
    commit('SET_PAYMENT_INVOICES', data)
  },
}
