export class BaseTimeUnit {
  id: number | undefined
  name: string
  days: number
  spacePeriodList: object | null

  constructor() {
    this.name = ''
    this.days = 0
    this.spacePeriodList = []
  }
}
