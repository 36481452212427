export class UserPost {
  id: number | undefined
  title: string
  longText: string
  user: object | null
  creationDate: object | null
  location: object | null
  onlyFriends: boolean

  constructor() {
    this.title = ''
    this.longText = ''
    this.user = null
    this.creationDate = null
    this.location = null
    this.onlyFriends = false
  }
}
