export class LocationPass {
    id: number | undefined
	location: object | null
	passType: object | null
	daysUsed: number 
	price: object | null
	userLocationPassList: object | null

	constructor() {
		this.location = null
		this.passType = null
		this.daysUsed = 0
		this.price = null
		this.userLocationPassList = []
	}
}
