export const state = () => ({
  preInvoiceOneOfFees: [],
})

export const getters = {
  getPreInvoiceOneOfFees: (state) => {
    return state.preInvoiceOneOfFees
  },
}

export const mutations = {
  SET_PRE_INVOICE_ONE_OF_FEES: (state, preInvoiceOneOfFees) => {
    state.preInvoiceOneOfFeeS = preInvoiceOneOfFees
  },
}

export const actions = {
  setPreInvoiceOneOfFees({ commit }, preInvoiceOneOfFees) {
    commit('SET_PREINVOICEONEOFFEES', preInvoiceOneOfFees)
  },

  async setupPreInvoiceOneOfFees({ commit, getters }) {
    if (
      !getters.getPreInvoiceOneOfFees ||
      getters.getPreInvoiceOneOfFees.length === 0
    ) {
      const r = await this.$axios.$get(`/pre-invoice-one-of-fee`)
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting preInvoiceOneOfFee'
        )
      }
      commit('SET_PRE_INVOICE_ONE_OF_FEES', r.data)
    }
  },
}
