/**
 * @Jokic
 * Ovaj deo treba da sluzi kao cache.
 */
export const state = () => ({
  contracts: [],
})

export const getters = {
  getContracts: (state) => {
    return state.contracts
  },
}

export const mutations = {
  SET_CONTRACTS: (state, contracts) => {
    state.contracts = contracts
  },
}

export const actions = {
  setContracts({ commit }, contracts) {
    commit('SET_CONTRACTS', contracts)
  },

  async setupContracts({ commit }) {
    const { data } = await this.$axios.$get(`/contract`)
    commit('SET_CONTRACTS', data)
  },
}
