import { SpacePeriod } from '@/entities/spacePeriod.ts'

export const state = () => ({
  spacePeriod: new SpacePeriod(),
})

export const getters = {
  getSpacePeriod: (state) => {
    return state.spacePeriod
  },
}

export const mutations = {
  SET_SPACE_PERIOD: (state, payload) => {
    state.spacePeriod = payload
  },
}

export const actions = {
  setSpacePeriod({ commit }, payload) {
    commit('SET_SPACE_PERIOD', payload)
  },

  async getSpacePeriodById({ commit }, id) {
    const r = await this.$axios.$get(`/space-period/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: spacePeriod')
    }
    commit('SET_SPACE_PERIOD', r.data)
  },

  async createSpacePeriod({ commit, getters }, payload) {
    if (payload) {
      commit('SET_SPACE_PERIOD', payload)
    }
    const r = await this.$axios.$post(`/space-period`, getters.getSpacePeriod)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: spacePeriod')
    }
    commit('SET_SPACE_PERIOD', r.data)
  },

  async saveSpacePeriod({ commit, getters }, payload) {
    if (payload) {
      commit('SET_SPACE_PERIOD', payload)
    }
    const r = await this.$axios.$put(
      `/space-period/${getters.getSpacePeriod.id}`,
      getters.getSpacePeriod
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: spacePeriod')
    }
    commit('SET_SPACE_PERIOD', r.data)
  },

  async deleteSpacePeriod({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_SPACE_PERIOD', payload)
    await this.$axios.$delete(`/space-period/${getters.getSpacePeriod.id}`)
    commit('SET_SPACE_PERIOD', null)
  },
}
