import { Payment } from '@/entities/payment'

export const state = () => ({
  payment: new Payment(),
})

export const getters = {
  getPayment: (state) => state.payment,
}

export const mutations = {
  SET_PAYMENT: (state, payload) => {
    state.payment = payload
  },
}

export const actions = {
  setPayment({ commit }, payload) {
    commit('SET_PAYMENT', payload)
  },

  async createPaymentByCompanyID({ commit }, { companyID, payment }) {
    const r = await this.$axios.$post(`/company/${companyID}/payment`, payment)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: invoice')
    }
    commit('SET_PAYMENT', r.data)
    return r.data
  },

  async deletePaymentByID(_, id) {
    await this.$axios.$delete(`/payment/${id}/payment`)
  },
}
