export const state = () => ({
  costCategory: {
    name: '',
  },
})

export const getters = {
  getCostCategory: (state) => {
    return state.costCategory
  },
}

export const mutations = {
  SET_COST_CATEGORY: (state, payload) => {
    state.costCategory = payload
  },
}

export const actions = {
  setCostCategory({ commit }, payload) {
    commit('SET_COST_CATEGORY', payload)
  },

  async getCostCategoryById({ commit }, id) {
    const r = await this.$axios.$get(`/cost-category/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: costCategory')
    }
    commit('SET_COST_CATEGORY', r.data)
  },

  async createCostCategory({ commit }, payload) {
    if (!payload) {
      throw new Error('payload not sent')
    }
    const r = await this.$axios.$post(`/cost-category`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: costCategory')
    }
    commit('SET_COST_CATEGORY', r.data)
    return r.data
  },

  async saveCostCategory({ commit, getters }, payload) {
    if (payload) {
      commit('SET_COST_CATEGORY', payload)
    }
    const r = await this.$axios.$put(
      `/cost-category/${getters.getCostCategory.id}`,
      getters.getCostCategory
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: costCategory')
    }
    commit('SET_COST_CATEGORY', r.data)
    return r.data
  },

  async deleteCostCategory({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_COST_CATEGORY', payload)
    await this.$axios.$delete(`/cost-category/${getters.getCostCategory.id}`)
    commit('SET_COST_CATEGORY', null)
  },
}
