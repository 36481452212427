import io from 'socket.io-client'
import VueSocketIOExt from 'vue-socket.io-extended'
import Vue from 'vue'

const socket = io(process.env.WS_API as string, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionAttempts: Infinity,
  autoConnect: true,
  forceJSONP: true,
  upgrade: true,
})

export function initSocketUser() {
  const token = localStorage.getItem(process.env.TOKEN_NAME as string)
  socket.emit('userInit', {
    bearer: token,
  })
}
socket.on('connect', initSocketUser)
socket.on('reconnect_attempt', initSocketUser)

declare module 'vue/types/vue' {
  interface Vue {
    socket: VueSocketIOExt
  }
}

// localStorage.debug = '*'

Vue.use(VueSocketIOExt, socket)
