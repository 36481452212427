export default function (ctx) {
  const { $axios, $bus, redirect } = ctx
  $axios.onRequest((config) => {
    let token = localStorage.getItem(process.env.TOKEN_NAME)
    if (!token) {
      token = sessionStorage.getItem(process.env.TOKEN_NAME)
    }
    config.headers.Authorization = token
  })

  $axios.onResponseError((error) => {
    if (error?.response?.status === 403) {
      redirect('/login')
      return
    }
    const notification = {
      message:
        error?.response?.data?.errorMessage || 'We encountered an error. ',
      type: 'error',
    }
    $bus.$emit('send-notification', notification)
  })

  $axios.onResponse((response) => {
    if (response.status === 403) {
      location.href = '/login'
      return
    }
    if (response?.errorMessage || response.status >= 400) {
      const notification = {
        message: response?.data?.errorMessage || 'We encountered an error. ',
        type: 'error',
      }
      $bus.$emit('send-notification', notification)
    }
  })
}
