import { Company } from './company'
import { Currency } from './currency'

export class PreInvoice {
  id?: number
  number: string
  dueDate: Date | string
  dateCreated: Date | string
  invoiceStatus: object | null
  taxRate: object | null
  discount: number
  total: object | null
  amount?: number
  company?: Company
  currency?: Currency
  currencyPreInvoiceId?: string

  constructor() {
    this.number = ''
    this.dueDate = ''
    this.dateCreated = ''
    this.invoiceStatus = null
    this.taxRate = null
    this.discount = 0
    this.total = null
    this.currencyPreInvoiceId = ''
  }
}
