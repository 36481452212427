export const state = () => ({
  notifications: {},
})

export const getters = {
  getNotifications: (state) => state.notifications,
  getNotificationsMessagesNotSeen: (state) =>
    (state.notifications && state.notifications.notSeenMessages) || 0,
  getNotificationsFriendRequestsNotSeen: (state) =>
    (state.notifications && state.notifications.notSeenFriendRequest) || 0,
}

export const mutations = {
  SET_NOTIFICATIONS(state, n) {
    state.notifications = n
  },

  ADD_NOTIFICATION_MESSAGE(state, n) {
    if (!state.notifications) {
      state.notifications = {
        notSeenFriendRequest: 0,
        notSeenMessages: 0,
      }
    }

    state.notifications.notSeenMessages++
  },
}

export const actions = {
  setNotifications({ commit }, n) {
    commit('SET_NOTIFICATIONS', n)
  },

  async setupNotifications({ commit }) {
    const { data } = await this.$axios.$get(`/notifications`)
    commit('SET_NOTIFICATIONS', data)
  },

  addNotificationMessage({ commit }, n) {
    commit('ADD_NOTIFICATION_MESSAGE', n)
  },
}
