import { OneOfFeeUserLocationPass } from '@/entities/oneOfFeeUserLocationPass.ts'

export const state = () => ({
  oneOfFeeUserLocationPass: new OneOfFeeUserLocationPass(),
})

export const getters = {
  getOneOfFeeUserLocationPass: (state) => {
    return state.oneOfFeeUserLocationPass
  },
}

export const mutations = {
  SET_ONE_OF_FEE_USER_LOCATION_PASS: (state, payload) => {
    state.oneOfFeeUserLocationPass = payload
  },
}

export const actions = {
  setOneOfFeeUserLocationPass({ commit }, payload) {
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', payload)
  },

  async getOneOfFeeUserLocationPassById({ commit }, id) {
    const r = await this.$axios.$get(`/one-of-fee-user-location-pass/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: oneOfFeeUserLocationPass')
    }
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', r.data)
  },

  async createOneOfFeeUserLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', payload)
    }
    const r = await this.$axios.$post(
      `/one-of-fee-user-location-pass`,
      getters.getOneOfFeeUserLocationPass
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: oneOfFeeUserLocationPass'
      )
    }
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', r.data)
  },

  async saveOneOfFeeUserLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', payload)
    }
    const r = await this.$axios.$put(
      `/one-of-fee-user-location-pass/${getters.getOneOfFeeUserLocationPass.id}`,
      getters.getOneOfFeeUserLocationPass
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: oneOfFeeUserLocationPass'
      )
    }
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', r.data)
  },

  async deleteOneOfFeeUserLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', payload)
    }
    await this.$axios.$delete(
      `/one-of-fee-user-location-pass/${getters.getOneOfFeeUserLocationPass.id}`
    )
    commit('SET_ONE_OF_FEE_USER_LOCATION_PASS', null)
  },
}
