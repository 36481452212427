import { SPACE_TYPES } from '@/config/constants'

export const state = () => ({
  spaceTypes: [],
  roomTypes: [],
})

export const getters = {
  getSpaceTypes: (state) => {
    return state.spaceTypes
  },
  getRoomTypes: (state) => {
    return state.roomTypes
  },
}

export const mutations = {
  SET_SPACE_TYPES: (state, spaceTypes) => {
    state.spaceTypes = spaceTypes
  },
  SET_ROOM_TYPES: (state, roomTypes) => {
    state.roomTypes = roomTypes
  },
}

export const actions = {
  setSpaceTypes({ commit }, spaceTypes) {
    commit('SET_SPACE_TYPES', spaceTypes)
  },

  async setupSpaceTypes({ commit }) {
    const r = await this.$axios.$get(`/space-type`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting spaceType')
    }
    commit('SET_SPACE_TYPES', r.data)
  },

  async setupRoomTypes({ commit }) {
    const r = await this.$axios.$get(
      `/fundamental-space-type/${SPACE_TYPES.ROOM}/space-type`
    )
    if (!r || !r.data) {
      throw new Error('response format error: error getting spaceType')
    }
    commit('SET_ROOM_TYPES', r.data)
  },
}
