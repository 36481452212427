import { StatusMember } from '@/entities/statusMember.ts'

export const state = () => ({
  statusMember: new StatusMember(),
})

export const getters = {
  getStatusMember: (state) => {
    return state.statusMember
  },
}

export const mutations = {
  SET_STATUS_MEMBER: (state, payload) => {
    state.statusMember = payload
  },
}

export const actions = {
  setStatusMember({ commit }, payload) {
    commit('SET_STATUS_MEMBER', payload)
  },

  async getStatusMemberById({ commit }, id) {
    const r = await this.$axios.$get(`/status-member/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: statusMember')
    }
    commit('SET_STATUS_MEMBER', r.data)
  },

  async createStatusMember({ commit, getters }, payload) {
    if (payload) {
      commit('SET_STATUS_MEMBER', payload)
    }
    const r = await this.$axios.$post(`/status-member`, getters.getStatusMember)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: statusMember')
    }
    commit('SET_STATUS_MEMBER', r.data)
  },

  async saveStatusMember({ commit, getters }, payload) {
    if (payload) {
      commit('SET_STATUS_MEMBER', payload)
    }
    const r = await this.$axios.$put(
      `/status-member/${getters.getStatusMember.id}`,
      getters.getStatusMember
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: statusMember')
    }
    commit('SET_STATUS_MEMBER', r.data)
  },

  async deleteStatusMember({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_STATUS_MEMBER', payload)
    await this.$axios.$delete(`/status-member/${getters.getStatusMember.id}`)
    commit('SET_STATUS_MEMBER', null)
  },
}
