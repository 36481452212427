export const state = () => ({
  spacePeriodPackagePrices: [],
})

export const getters = {
  getSpacePeriodPackagePrices: (state) => {
    return state.spacePeriodPackagePrices
  },
}

export const mutations = {
  SET_SPACE_PERIOD_PACKAGE_PRICES: (state, spacePeriodPackagePrices) => {
    state.spacePeriodPackagePrices = spacePeriodPackagePrices
  },
}

export const actions = {
  setSpacePeriodPackagePrices({ commit }, spacePeriodPackagePrices) {
    commit('SET_SPACE_PERIOD_PACKAGE_PRICES', spacePeriodPackagePrices)
  },

  async setupSpacePeriodPackagePricesByPackageID({ commit }) {
    const { data } = await this.$axios.$get(`/space-period-package-price`)
    commit('SET_SPACE_PERIOD_PACKAGE_PRICES', data)
  },
}
