export const state = () => ({
  baseTimeUnits: [],
})

export const getters = {
  getBaseTimeUnits: (state) => {
    return state.baseTimeUnits
  },
}

export const mutations = {
  SET_BASE_TIME_UNITs: (state, baseTimeUnits) => {
    state.baseTimeUnits = baseTimeUnits
  },
}

export const actions = {
  setBaseTimeUnits({ commit }, baseTimeUnits) {
    commit('SET_BASETIMEUNITs', baseTimeUnits)
  },

  async setupBaseTimeUnits({ commit }) {
    const r = await this.$axios.$get(`/base-time-unit`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting baseTimeUnit')
    }
    commit('SET_BASE_TIME_UNITs', r.data)
  },
}
