export class SpacePeriodPricing {
    id: number | undefined
	price: object | null
	spacePeriod: object | null
	space: object | null

	constructor() {
		this.price = null
		this.spacePeriod = null
		this.space = null
	}
}
