import { Friend } from '@/entities/friend.ts'

export const state = () => ({
  friend: new Friend(),
})

export const getters = {
  getFriend: (state) => {
    return state.friend
  },
}

export const mutations = {
  SET_FRIEND: (state, payload) => {
    state.friend = payload
  },
}

export const actions = {
  setFriend({ commit }, payload) {
    commit('SET_FRIEND', payload)
  },

  async addFriend({ commit }, payload) {
    if (!payload) {
      throw new Error('Payload must be provided')
    }
    if (payload.id <= 0) {
      throw new Error('User you are adding must have id')
    }
    try {
      const r = await this.$axios.$post(
        `/user-receiver/${payload.id}/friend`,
        null
      )
      if (!r || !r.data) {
        throw new Error('error in response format on creating: friend')
      }
      commit('SET_FRIEND', r.data)
      return r.data
    } catch (error) {
      console.error(error)
    }
  },

  async acceptFriend({ commit }, user) {
    if (!user) {
      throw new Error('Payload must be provided')
    }
    if (user.id <= 0) {
      throw new Error('User you are adding must have id')
    }
    const { data } = await this.$axios.$put(`/user/${user.id}/friend`)
    commit('SET_FRIEND', data)
    return data
  },

  async deleteFriend({ commit }, user) {
    if (!user) {
      throw new Error('Payload must be provided')
    }
    if (user.id <= 0) {
      throw new Error('User you are deleting must have id')
    }
    await this.$axios.$delete(`/user/${user.id}/friend`)
    commit('SET_FRIEND', null)
  },
}
