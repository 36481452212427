import { Company } from './company'
import { Currency } from './currency'

export class Invoice {
  id?: number
  number: string
  dueDate: Date | string
  dateCreated: Date | string
  invoiceStatus: object | null
  taxRate: any
  discount: number
  total: any
  amount?: number
  company?: Company
  currency?: Currency
  leftForPaymentTaxRate?: any
  leftForPaymentTaxBase?: number
  advancePaymentTaxRate?: any
  advancePaymentTaxBase?: number
  advancePaymentNumber?: string
  advancePaymentDate?: Date | string
  currencyPreInvoiceId?: string

  constructor() {
    this.number = ''
    this.dueDate = ''
    this.dateCreated = ''
    this.invoiceStatus = null
    this.taxRate = null
    this.discount = 0
    this.total = null
    this.advancePaymentTaxRate = null
    this.advancePaymentTaxBase = 0
    this.advancePaymentNumber = ''
    this.advancePaymentDate = ''
    this.currencyPreInvoiceId = ''
  }
}
