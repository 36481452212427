import { CompanySpecifics } from '@/entities/companySpecifics.ts'

export const state = () => ({
  companySpecifics: new CompanySpecifics(),
})

export const getters = {
  getCompanySpecifics: (state) => {
    return state.companySpecifics
  },
}

export const mutations = {
  SET_COMPANY_SPECIFICS: (state, payload) => {
    state.companySpecifics = payload
  },
}

export const actions = {
  setCompanySpecifics({ commit }, payload) {
    commit('SET_COMPANY_SPECIFICS', payload)
  },

  async getCompanySpecificsById({ commit }, id) {
    const r = await this.$axios.$get(`/company-specifics/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: companySpecifics')
    }
    commit('SET_COMPANY_SPECIFICS', r.data)
  },

  async createCompanySpecifics({ commit, getters }, payload) {
    if (payload) {
      commit('SET_COMPANY_SPECIFICS', payload)
    }
    const r = await this.$axios.$post(
      `/company-specifics`,
      getters.getCompanySpecifics
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: companySpecifics')
    }
    commit('SET_COMPANY_SPECIFICS', r.data)
  },

  async saveCompanySpecifics({ commit, getters }, payload) {
    if (payload) {
      commit('SET_COMPANY_SPECIFICS', payload)
    }
    const r = await this.$axios.$put(
      `/company-specifics/${getters.getCompanySpecifics.id}`,
      getters.getCompanySpecifics
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: companySpecifics')
    }
    commit('SET_COMPANY_SPECIFICS', r.data)
  },

  async deleteCompanySpecifics({ commit, getters }, payload) {
    if (
      !getters.getCompanySpecifics ||
      !!getters.getCompanySpecifics.id ||
      !getters.getCompanySpecifics.id <= 0
    ) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_COMPANY_SPECIFICS', payload)
    await this.$axios.$delete(
      `/company-specifics/${getters.getCompanySpecifics.id}`
    )
    commit('SET_COMPANY_SPECIFICS', null)
  },
}
