








import { Vue, Component } from 'vue-property-decorator'

@Component
export default class EmptyLayout extends Vue {
  beforeCreate() {
    localStorage.removeItem(process.env.TOKEN_NAME)
    sessionStorage.clear()
  }

  ns: any[] = []

  yielder = {
    fired: false,
    fn: this.showNotifications,
    timeout: 750,
  }

  addNotification(n) {
    for (const notification of this.ns) {
      if (notification.message === n.message) {
        return
      }
      console.log('notification.message = ', notification.message)
      console.log('n.message = ', n.message)
      console.log('---')
    }
    this.ns.push(n)
  }

  fireNotification(n) {
    this.addNotification(n)
    if (this.yielder.fired) {
      return
    }
    this.yielder.fired = true
    setTimeout(() => {
      this.yielder.fn()
      this.yielder.fired = false
      this.ns = []
    }, this.yielder.timeout)
  }

  showNotifications() {
    for (const n of this.ns) {
      this.$toast.open(n)
    }
  }

  mounted() {
    this.$socket.client.emit('userDisconnect')
    this.$socket.client.disconnect()

    this.$bus.$on('send-notification', (notification) => {
      this.fireNotification(notification)
    })
  }
}
