export const state = () => ({
  invoiceContracts: [],
  invoiceOneOfFees: [],
  invoicePostPaids: [],
  invoicePreInvoices: [],
})

export const getters = {
  getInvoiceContracts: (state) => state.invoiceContracts,
  getInvoiceOneOfFees: (state) => state.invoiceOneOfFees,
  getInvoicePostPaids: (state) => state.invoicePostPaids,
  getInvoicePreInvoices: (state) => state.invoicePreInvoices,
}

export const mutations = {
  SET_INVOICE_CONTRACTS: (state, payload) => {
    state.invoiceContracts = payload
  },

  SET_INVOICE_ONE_OF_FEES: (state, payload) => {
    state.invoiceOneOfFees = payload
  },

  SET_INVOICE_POSTPAIDS: (state, payload) => {
    state.invoicePostPaids = payload
  },

  SET_INVOICE_PRE_INVOICES: (state, payload) => {
    state.invoicePreInvoices = payload
  },
}

export const actions = {
  async setupInvoiceContracts({ commit }) {
    const { data } = await this.$axios.$get(`/invoice/contract-period`)
    commit('SET_INVOICE_CONTRACTS', data)
  },

  async setupInvoiceOneOfFees({ commit }) {
    const { data } = await this.$axios.$get(`/invoice/one-of-fee-period`)
    commit('SET_INVOICE_ONE_OF_FEES', data)
  },

  async setupPreinvoiceContracts({ commit }) {
    const { data } = await this.$axios.$get(`/pre-invoice/contract-period`)
    commit('SET_INVOICE_CONTRACTS', data)
  },

  async setupPreinvoiceOneOfFees({ commit }) {
    const { data } = await this.$axios.$get(`/pre-invoice/one-of-fee-period`)
    commit('SET_INVOICE_ONE_OF_FEES', data)
  },

  async setupInvoicePostPaids({ commit }) {
    const { data } = await this.$axios.$get(`/invoice/post-paid`)
    commit('SET_INVOICE_POSTPAIDS', data)
  },

  async setupInvoicePreInvoices({ commit }) {
    const { data } = await this.$axios.$get(`/invoice/pre-invoice`)
    commit('SET_INVOICE_PRE_INVOICES', data)
  },
}
