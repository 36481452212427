// vuetify.options.js
import colors from 'vuetify/es5/util/colors'
// import BaseIcon from '@/components/Base/BaseIcon.vue'

import icons from '@/config/icons'

export default {
  breakpoint: {},
  icons: {
    values: icons,
  },
  lang: {},
  rtl: false,
  theme: {
    themes: {
      dark: {
        primary: '#2746E6',
        accent: colors.white,
        secondary: colors.grey.lighten3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: 'black',
        success: colors.green.accent3,
        navbar: colors.grey.darken4,
      },
      light: {
        primary: '#2948a8',
        accent: colors.grey.darken3,
        secondary: colors.black,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.red.base,
        success: colors.green.darken1,
      },
    },
  },
}
