import { ContractDepositStatus } from '@/entities/contractDepositStatus.ts'

export const state = () => ({
  contractDepositStatus: new ContractDepositStatus(),
})

export const getters = {
  getContractDepositStatus: (state) => {
    return state.contractDepositStatus
  },
}

export const mutations = {
  SET_CONTRACT_DEPOSIT_STATUS: (state, payload) => {
    state.contractDepositStatus = payload
  },
}

export const actions = {
  setContractDepositStatus({ commit }, payload) {
    commit('SET_CONTRACT_DEPOSIT_STATUS', payload)
  },

  async getContractDepositStatusById({ commit }, id) {
    const r = await this.$axios.$get(`/contract-deposit-status/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: contractDepositStatus')
    }
    commit('SET_CONTRACT_DEPOSIT_STATUS', r.data)
  },

  async createContractDepositStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_DEPOSIT_STATUS', payload)
    }
    const r = await this.$axios.$post(
      `/contract-deposit-status`,
      getters.getContractDepositStatus
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: contractDepositStatus'
      )
    }
    commit('SET_CONTRACT_DEPOSIT_STATUS', r.data)
  },

  async saveContractDepositStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_DEPOSIT_STATUS', payload)
    }
    const r = await this.$axios.$put(
      `/contract-deposit-status/${getters.getContractDepositStatus.id}`,
      getters.getContractDepositStatus
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: contractDepositStatus'
      )
    }
    commit('SET_CONTRACT_DEPOSIT_STATUS', r.data)
  },

  async deleteContractDepositStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_DEPOSIT_STATUS', payload)
    }
    await this.$axios.$delete(
      `/contract-deposit-status/${getters.getContractDepositStatus.id}`
    )
    commit('SET_CONTRACT_DEPOSIT_STATUS', null)
  },
}
