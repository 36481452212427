export const state = () => ({
  oneOfFees: [],
  postPaids: [],
})

export const getters = {
  getOneOfFees: (state) => {
    return state.oneOfFees
  },

  getPostPaids: (state) => state.postPaids,
}

export const mutations = {
  SET_ONE_OF_FEES: (state, oneOfFees) => {
    state.oneOfFees = oneOfFees
  },

  SET_POSTPAIDS: (state, postPaids) => {
    state.postPaids = postPaids
  },
}

export const actions = {
  setOneOfFees({ commit }, oneOfFees) {
    commit('SET_ONEOFFEES', oneOfFees)
  },

  async setupOneOfFees({ commit }) {
    const { data } = await this.$axios.$get(`/one-of-fee`)
    commit('SET_ONE_OF_FEES', data)
  },

  async setupOneOfFeesByCompanyId({ commit }, id) {
    const { data } = await this.$axios.$get(`/company/${id}/one-of-fee/summary`)
    commit('SET_ONE_OF_FEES', data)
  },

  async setupPostPaidsByCompanyID({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/post-paid/summary`
    )
    commit('SET_POSTPAIDS', data)
  },
}
