export class ParkingPricing {
    id: number | undefined
	price: object | null
	parkingType: object | null
	location: object | null
	parkingSpotList: object | null

	constructor() {
		this.price = null
		this.parkingType = null
		this.location = null
		this.parkingSpotList = []
	}
}
