import { ExtrasCategory } from '@/entities/extrasCategory.ts'

export const state = () => ({
  extrasCategory: new ExtrasCategory(),
})

export const getters = {
  getExtrasCategory: (state) => {
    return state.extrasCategory
  },
}

export const mutations = {
  SET_EXTRAS_CATEGORY: (state, payload) => {
    state.extrasCategory = payload
  },
}

export const actions = {
  setExtrasCategory({ commit }, payload) {
    commit('SET_EXTRAS_CATEGORY', payload)
  },

  async getExtrasCategoryById({ commit }, id) {
    const r = await this.$axios.$get(`/extras-category/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: extrasCategory')
    }
    commit('SET_EXTRAS_CATEGORY', r.data)
  },

  async createExtrasCategory({ commit, getters }, payload) {
    if (payload) {
      commit('SET_EXTRAS_CATEGORY', payload)
    }
    const r = await this.$axios.$post(
      `/extras-category`,
      getters.getExtrasCategory
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: extrasCategory')
    }
    commit('SET_EXTRAS_CATEGORY', r.data)
  },

  async saveExtrasCategory({ commit, getters }, payload) {
    if (payload) {
      commit('SET_EXTRAS_CATEGORY', payload)
    }
    const r = await this.$axios.$put(
      `/extras-category/${getters.getExtrasCategory.id}`,
      getters.getExtrasCategory
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: extrasCategory')
    }
    commit('SET_EXTRAS_CATEGORY', r.data)
  },

  async deleteExtrasCategory({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_EXTRAS_CATEGORY', payload)
    await this.$axios.$delete(
      `/extras-category/${getters.getExtrasCategory.id}`
    )
    commit('SET_EXTRAS_CATEGORY', null)
  },
}
