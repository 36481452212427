import { Location } from '@/entities/location.ts'

export const state = () => ({
  location: new Location(),
})

export const getters = {
  getLocation: (state) => {
    return state.location
  },
}

export const mutations = {
  SET_LOCATION: (state, payload) => {
    state.location = payload
  },
}

export const actions = {
  setLocation({ commit }, payload) {
    commit('SET_LOCATION', payload)
  },

  async saveLocationPhoto(_, { locationID, photo }) {
    const fd = new FormData()
    fd.append('file', photo)
    await this.$axios.$post(`/location/${locationID}/avatar`, fd)
  },

  async getLocationById({ commit }, id) {
    const r = await this.$axios.$get(`/location/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: location')
    }
    commit('SET_LOCATION', r.data)
  },

  async createLocation({ commit, getters }, payload) {
    if (payload) {
      commit('SET_LOCATION', payload)
    }
    const r = await this.$axios.$post(`/location`, getters.getLocation)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: location')
    }
    commit('SET_LOCATION', r.data)
    return r.data
  },

  async saveLocation({ commit, getters }, payload) {
    if (payload) {
      commit('SET_LOCATION', payload)
    }
    const r = await this.$axios.$put(
      `/location/${getters.getLocation.id}`,
      getters.getLocation
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: location')
    }
    commit('SET_LOCATION', r.data)
    return r.data
  },

  async deleteLocation({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_LOCATION', payload)
    await this.$axios.$delete(`/location/${getters.getLocation.id}`)
    commit('SET_LOCATION', null)
  },
}
