export const state = () => ({
  spacePeriodPricings: [],
})

export const getters = {
  getSpacePeriodPricings: (state) => {
    return state.spacePeriodPricings
  },
}

export const mutations = {
  SET_SPACE_PERIOD_PRICINGS: (state, spacePeriodPricings) => {
    state.spacePeriodPricings = spacePeriodPricings
  },
}

export const actions = {
  setSpacePeriodPricings({ commit }, spacePeriodPricings) {
    commit('SET_SPACE_PERIOD_PRICINGS', spacePeriodPricings)
  },

  async setupSpacePeriodPricings({ commit }) {
    const r = await this.$axios.$get(`/space-period-pricing`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting spacePeriodPricing')
    }
    commit('SET_SPACE_PERIOD_PRICINGS', r.data)
  },
}
