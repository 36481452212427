/* eslint-disable no-labels */
import Vue from 'vue'

// Token
const TOKEN_EOF = 0
const TOKEN_YEAR = 1
const TOKEN_MONTH = 2
const TOKEN_DAY = 3
const TOKEN_HOUR = 4
const TOKEN_MIN = 5
const TOKEN_SEC = 6
const TOKEN_SPLIT = 99

class Token {
  constructor(public value: string, public token: number) {}
}

interface Lexer {
  lex(): void
  next(): Token
}

interface Reader {
  next(): string
  prev(): string
  peak(): string
  reset(): void
}

function getISO(value: string | Date | number): string {
  if (typeof value === 'string' && Date.parse(value)) {
    return new Date(value).toISOString()
  } else if (typeof value === 'number') {
    return new Date(value).toISOString()
  } else if (value instanceof Date) {
    return value.toISOString()
  }
  return ''
}

function fmt(value: string | Date | number, fmt?: string): string {
  if (!fmt) {
    fmt = process.env.FDATE_FMT as string
  }
  const v = getISO(value)
  if (!v) {
    return v
  }
  const p = new DateParser(fmt, v)
  return p.parse()
}

class DateParser {
  #lexer: Lexer
  constructor(private fmt: string, private value: string) {
    this.#lexer = new DateLexer(fmt, value)
    this.#lexer.lex()
  }

  parse(): string {
    let agg = ''
    while (true) {
      const tkn = this.#lexer.next()
      if (tkn.token === TOKEN_EOF) {
        break
      }
      agg += tkn.value
    }
    return agg
  }
}

class StringReader implements Reader {
  #index = 0
  constructor(private s: string) {}

  next(): string {
    if (this.#index < 0 || this.#index >= this.s.length) {
      return ''
    }
    return this.s[this.#index++]
  }

  prev(): string {
    if (this.#index < 0 || this.#index >= this.s.length) {
      return ''
    }
    return this.s[--this.#index]
  }

  peak(): string {
    const c = this.next()
    this.prev()
    return c
  }

  reset(): void {
    this.#index = 0
  }
}

class ReverseStringReader implements Reader {
  private reader: Reader
  constructor(val: string) {
    this.reader = new StringReader(val.split('').reverse().join(''))
  }

  next(): string {
    return this.reader.next()
  }

  prev(): string {
    return this.reader.prev()
  }

  peak(): string {
    return this.reader.peak()
  }

  reset(): void {
    throw new Error('Method not implemented.')
  }
}

class DateLexer {
  #reverseYearReader: Reader
  #reverseMonthReader: Reader
  #reverseDayReader: Reader
  #reverseHourReader: Reader
  #reverseMinReader: Reader
  #reverseSecReader: Reader

  #fReader: Reader

  #lYear: string[] = []
  #lMonth: string[] = []
  #lDay: string[] = []
  #lHour: string[] = []
  #lMin: string[] = []
  #lSec: string[] = []

  #lYearReader: Reader
  #lMonthReader: Reader
  #lDayReader: Reader

  #lHourReader: Reader
  #lMinReader: Reader
  #lSecReader: Reader

  #lSplit: string[] = []
  #lSplitReader: Reader

  constructor(private fmt: string, private isoString: string) {
    const ss = isoString.split('T')
    const ds = ss[0].split('-')
    const ts = ss[1].split(':')

    // TODO: Add reverse reader
    this.#reverseYearReader = new ReverseStringReader(ds[0])
    this.#reverseMonthReader = new ReverseStringReader(ds[1])
    this.#reverseDayReader = new ReverseStringReader(ds[2])

    this.#reverseHourReader = new ReverseStringReader(ts[0])
    this.#reverseMinReader = new ReverseStringReader(ts[1])
    this.#reverseSecReader = new ReverseStringReader(ts[2].split('.')[0])

    this.#fReader = new StringReader(fmt)
  }

  lex() {
    loop: while (true) {
      const fmt = this.#fReader.next()
      switch (fmt) {
        case '':
          break loop
        case 'y':
        case 'Y':
          this.#lYear.push(this.#reverseYearReader.next())
          break
        case 'M':
          this.#lMonth.push(this.#reverseMonthReader.next())
          break
        case 'd':
        case 'D':
          this.#lDay.push(this.#reverseDayReader.next())
          break
        case 'h':
        case 'H':
          this.#lHour.push(this.#reverseHourReader.next())
          break
        case 'm':
          this.#lMin.push(this.#reverseMinReader.next())
          break
        case 's':
        case 'S':
          this.#lSec.push(this.#reverseSecReader.next())
          break
        default:
          this.#lSplit.push(fmt)
          break
      }
    }
    this.#fReader.reset()
    this.#lYearReader = new StringReader(this.#lYear.reverse().join(''))
    this.#lMonthReader = new StringReader(this.#lMonth.reverse().join(''))
    this.#lDayReader = new StringReader(this.#lDay.reverse().join(''))
    this.#lSplitReader = new StringReader(this.#lSplit.join(''))
    this.#lHourReader = new StringReader(this.#lHour.reverse().join(''))
    this.#lMinReader = new StringReader(this.#lMin.reverse().join(''))
    this.#lSecReader = new StringReader(this.#lSec.reverse().join(''))
  }

  next(): Token {
    const f = this.#fReader.next()
    switch (f) {
      case '':
        return new Token('', TOKEN_EOF)
      case 'y':
      case 'Y':
        return new Token(this.#lYearReader.next(), TOKEN_YEAR)
      case 'M':
        return new Token(this.#lMonthReader.next(), TOKEN_MONTH)
      case 'd':
      case 'D':
        return new Token(this.#lDayReader.next(), TOKEN_DAY)
      case 'h':
      case 'H':
        return new Token(this.#lHourReader.next(), TOKEN_HOUR)
      case 'm':
        return new Token(this.#lMinReader.next(), TOKEN_MIN)
      case 's':
      case 'S':
        return new Token(this.#lSecReader.next(), TOKEN_SEC)
      default:
        return new Token(this.#lSplitReader.next(), TOKEN_SPLIT)
    }
  }
}

Vue.prototype.$fDate = fmt

declare module 'vue/types/vue' {
  interface Vue {
    $fDate: (value: string | Date | number, fmt?: string) => string
  }
}
