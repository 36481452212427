export const state = () => ({
  locations: [],
})

export const getters = {
  getLocations: (state) => {
    return state.locations
  },
}

export const mutations = {
  SET_LOCATIONS: (state, locations) => {
    state.locations = locations
  },
}

export const actions = {
  setLocations({ commit }, locations) {
    commit('SET_LOCATIONS', locations)
  },

  async setupLocations({ commit }) {
    const r = await this.$axios.$get(`/location`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting location')
    }
    commit('SET_LOCATIONS', r.data)
  },
}
