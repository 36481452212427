import getFile from '@/utils/file-reader'
import { User } from '@/entities/user.ts'
import { Roles } from '~/entities/role'

export const state = () => ({
  user: new User(),
  avatar: '',
})

export const getters = {
  getUser: (state) => {
    return state.user
  },

  getRoleID: (state) =>
    (state.user && state.user.role && state.user.role.roleId) || -1,

  isAdmin: (state) =>
    state.user && state.user.role && state.user.role.roleId === Roles.Admin,

  isSuperAdmin: (state) =>
    state.user &&
    state.user.role &&
    state.user.role.roleId === Roles['Super admin'],
  isTeamMember: (state) =>
    state.user &&
    state.user.role &&
    state.user.role.roleId === Roles['Team member'],

  isCommunityAssistant: (state) =>
    state.user &&
    state.user.role &&
    state.user.role.roleId === Roles['Community assistant'],

  getAvatar: (state) => state.avatar,

  iHaveCompany: (state) => Number(state?.user?.company?.id) > 0,

  getMyCompany: (state, getters) =>
    getters.iHaveCompany ? state.user.company : null,
}

export const mutations = {
  SET_USER: (state, payload) => {
    state.user = payload
  },

  SET_AVATAR: (state, payload) => {
    state.avatar = payload
  },
}

export const actions = {
  setUser({ commit }, payload) {
    commit('SET_USER', payload)
  },

  async createUser(_, payload) {
    if (!payload) {
      throw new Error('Payload must be provided')
    }
    const { data } = await this.$axios.$post(`/user`, payload)
    return data
  },

  async saveUser(_, payload) {
    if (!payload) {
      throw new Error('store (user) saveUser: error. payload must be provided')
    }
    if (payload.id <= 0) {
      throw new Error('can not update payload with id <= 0, saveUser...')
    }
    const { data } = await this.$axios.$put(`/user/${payload.id}`, payload)
    return data
  },

  async deleteUser(_, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    if (payload.id <= 0) {
      throw new Error(
        'error, payload must have id >= 0 to be deleted. delete user'
      )
    }
    await this.$axios.$delete(`/user/${payload.id}`)
  },

  async setupAvatar({ commit, getters }) {
    if (getters.getUser.id) {
      commit('SET_AVATAR', await getFile(`/user/avatar`))
    }
  },
}
