export const state = () => ({
  parkingTypes: [],
})

export const getters = {
  getParkingTypes: (state) => {
    return state.parkingTypes
  },
}

export const mutations = {
  SET_PARKING_TYPES: (state, parkingTypes) => {
    state.parkingTypes = parkingTypes
  },
}

export const actions = {
  setParkingTypes({ commit }, parkingTypes) {
    commit('SET_PARKINGTYPES', parkingTypes)
  },

  async setupParkingTypes({ commit }) {
    const r = await this.$axios.$get(`/parking-type`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting parkingType')
    }
    commit('SET_PARKING_TYPES', r.data)
  },
}
