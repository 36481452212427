import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $bus: Vue
  }
}

export default (_ctx, inject) => {
  const bus = new Vue()
  inject('bus', bus)
}
