export class OneOfFeeUserLocationPass {
  id: number | undefined
  user: object | null
  oneOfFee: object | null
  active: boolean
  dateCreated: Date | string
  locationPass: object | null
  dateFrom: Date | string
  dateTo: Date | string
  oneOfFeeUserLocationPassEntryList: object | null

  constructor() {
    this.user = null
    this.oneOfFee = null
    this.active = false
    this.dateCreated = ''
    this.locationPass = null
    this.dateFrom = ''
    this.dateTo = ''
    this.oneOfFeeUserLocationPassEntryList = []
  }
}
