import Vue from 'vue'

export const state = () => ({
  passTypes: [],
  locationPassType: {},
})

export const getters = {
  getPassTypes: (state) => {
    return state.passTypes
  },

  getPassTypesByLocationID: (state) => (locationID) => {
    if (!state.locationPassType) {
      return []
    }
    return state.locationPassType[locationID] || []
  },
}

export const mutations = {
  SET_PASS_TYPES: (state, passTypes) => {
    state.passTypes = passTypes
  },

  SET_LOCATION_PASS_TYPES(state, { locationID, passTypes }) {
    if (!state.locationPassType) {
      state.locationPassType = {}
    }
    Vue.set(state.locationPassType, locationID, passTypes)
  },
}

export const actions = {
  setPassTypes({ commit }, passTypes) {
    commit('SET_PASSTYPES', passTypes)
  },

  async setupPassTypes({ commit }) {
    const r = await this.$axios.$get(`/pass-type`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting passType')
    }
    commit('SET_PASS_TYPES', r.data)
  },

  async setupPassTypesByLocationID({ commit }, locationID) {
    const { data } = await this.$axios.$get(
      `/location/${locationID}/location-pass`
    )
    commit('SET_LOCATION_PASS_TYPES', {
      locationID,
      passTypes: data,
    })
    return data
  },
}
