import { ParkingSpot } from '@/entities/parkingSpot.ts'

export const state = () => ({
  parkingSpot: new ParkingSpot(),
})

export const getters = {
  getParkingSpot: (state) => {
    return state.parkingSpot
  },
}

export const mutations = {
  SET_PARKING_SPOT: (state, payload) => {
    state.parkingSpot = payload
  },
}

export const actions = {
  setParkingSpot({ commit }, payload) {
    commit('SET_PARKING_SPOT', payload)
  },

  async getParkingSpotById({ commit }, id) {
    const r = await this.$axios.$get(`/parking-spot/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: parkingSpot')
    }
    commit('SET_PARKING_SPOT', r.data)
  },

  async createParkingSpot({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PARKING_SPOT', payload)
    }
    const r = await this.$axios.$post(`/parking-spot`, getters.getParkingSpot)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: parkingSpot')
    }
    commit('SET_PARKING_SPOT', r.data)
  },

  async createParkingSpotByLocationID({ commit }, { locationID, parkingSpot }) {
    if (!locationID || locationID <= 0) {
      throw new Error('error: locationID can not be <= 0')
    }
    if (!parkingSpot) {
      throw new Error('error: parking spot not passed')
    }
    console.log(parkingSpot)
    const r = await this.$axios.$post(
      `/parking-pricing/${parkingSpot.parkingPricing.id}/parking-spot`,
      parkingSpot
    )
    if (!r || !r.data) {
      throw new Error(
        'format error in location, location id, parking-spot response'
      )
    }
    commit('SET_PARKING_SPOT', r.data)
  },

  async saveParkingSpot({ commit }, { parkingSpot }) {
    if (!parkingSpot) {
      commit('SET_PARKING_SPOT', parkingSpot)
    }
    const { data } = await this.$axios.$put(
      `/parking-spot/${parkingSpot.id}`,
      parkingSpot
    )
    commit('SET_PARKING_SPOT', data)
  },

  async deleteParkingSpot({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_PARKING_SPOT', payload)
    await this.$axios.$delete(`/parking-spot/${getters.getParkingSpot.id}`)
    commit('SET_PARKING_SPOT', null)
  },
}
