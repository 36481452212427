export default async function getFile(url) {
  const r = await this.$axios.$get(url, {
    responseType: 'blob',
  })
  const reader = new FileReader()
  reader.readAsDataURL(r)

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(reader.result)
    }
    reader.onerror = function (error) {
      console.log(error)
      reject(error)
    }
  })
}
