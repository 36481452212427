export const state = () => ({
  friends: [],
  friendRequests: [],
  sentFriendRequests: [],
})

export const getters = {
  getFriends: (state) => {
    return state.friends
  },

  getFriendRequests: (state) => state.friendRequests,
  getSentFriendRequests: (state) => state.sentFriendRequests,
}

export const mutations = {
  SET_FRIENDS: (state, friends) => {
    state.friends = friends
  },

  SET_FRIEND_REQUESTS: (state, requests) => {
    state.friendRequests = requests
  },

  SET_SENT_FRIEND_REQUESTS: (state, requests) => {
    state.sentFriendRequests = requests
  },
}

export const actions = {
  setFriends({ commit }, friends) {
    commit('SET_FRIENDS', friends)
  },

  async setupFriends({ commit }) {
    const r = await this.$axios.$get(`/friend`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting friend')
    }
    commit('SET_FRIENDS', r.data)
  },

  async setupFriendRequests({ commit }) {
    const { data } = await this.$axios.$get(`/friend-requests`)
    if (!Array.isArray(data)) {
      throw new TypeError('data is not array')
    }
    commit('SET_FRIEND_REQUESTS', data)
  },

  async setupSentFriendRequests({ commit }) {
    const { data } = await this.$axios.$get(`/sent-friend-requests`)
    if (!Array.isArray(data)) {
      throw new TypeError('data is not array')
    }
    commit('SET_SENT_FRIEND_REQUESTS', data)
  },
}
