import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  BaseConfirmDialog: () => import('../../components/Base/BaseConfirmDialog.vue' /* webpackChunkName: "components/base-confirm-dialog" */).then(c => wrapFunctional(c.default || c)),
  BaseDatePicker: () => import('../../components/Base/BaseDatePicker.vue' /* webpackChunkName: "components/base-date-picker" */).then(c => wrapFunctional(c.default || c)),
  BaseIcon: () => import('../../components/Base/BaseIcon.vue' /* webpackChunkName: "components/base-icon" */).then(c => wrapFunctional(c.default || c)),
  BaseMonthPicker: () => import('../../components/Base/BaseMonthPicker.vue' /* webpackChunkName: "components/base-month-picker" */).then(c => wrapFunctional(c.default || c)),
  BaseYearPicker: () => import('../../components/Base/BaseYearPicker.vue' /* webpackChunkName: "components/base-year-picker" */).then(c => wrapFunctional(c.default || c)),
  BaseFormButtons: () => import('../../components/BaseForm/BaseFormButtons.vue' /* webpackChunkName: "components/base-form-buttons" */).then(c => wrapFunctional(c.default || c)),
  BaseTimeUnitForm: () => import('../../components/BaseTimeUnit/BaseTimeUnitForm.vue' /* webpackChunkName: "components/base-time-unit-form" */).then(c => wrapFunctional(c.default || c)),
  BaseTimeUnitTable: () => import('../../components/BaseTimeUnit/BaseTimeUnitTable.vue' /* webpackChunkName: "components/base-time-unit-table" */).then(c => wrapFunctional(c.default || c)),
  BookingCalendar: () => import('../../components/Booking/BookingCalendar.vue' /* webpackChunkName: "components/booking-calendar" */).then(c => wrapFunctional(c.default || c)),
  BookingForm: () => import('../../components/Booking/BookingForm.vue' /* webpackChunkName: "components/booking-form" */).then(c => wrapFunctional(c.default || c)),
  BookingList: () => import('../../components/Booking/BookingList.vue' /* webpackChunkName: "components/booking-list" */).then(c => wrapFunctional(c.default || c)),
  BookingPeriodSelector: () => import('../../components/Booking/BookingPeriodSelector.vue' /* webpackChunkName: "components/booking-period-selector" */).then(c => wrapFunctional(c.default || c)),
  BookingSettingsForm: () => import('../../components/Booking/BookingSettingsForm.vue' /* webpackChunkName: "components/booking-settings-form" */).then(c => wrapFunctional(c.default || c)),
  BookingTimeline: () => import('../../components/Booking/BookingTimeline.vue' /* webpackChunkName: "components/booking-timeline" */).then(c => wrapFunctional(c.default || c)),
  CompanyBookings: () => import('../../components/Company/CompanyBookings.vue' /* webpackChunkName: "components/company-bookings" */).then(c => wrapFunctional(c.default || c)),
  CompanyFilter: () => import('../../components/Company/CompanyFilter.vue' /* webpackChunkName: "components/company-filter" */).then(c => wrapFunctional(c.default || c)),
  CompanyForm: () => import('../../components/Company/CompanyForm.vue' /* webpackChunkName: "components/company-form" */).then(c => wrapFunctional(c.default || c)),
  CompanyHeader: () => import('../../components/Company/CompanyHeader.vue' /* webpackChunkName: "components/company-header" */).then(c => wrapFunctional(c.default || c)),
  CompanyInvoices: () => import('../../components/Company/CompanyInvoices.vue' /* webpackChunkName: "components/company-invoices" */).then(c => wrapFunctional(c.default || c)),
  CompanyListAndMembersTable: () => import('../../components/Company/CompanyListAndMembersTable.vue' /* webpackChunkName: "components/company-list-and-members-table" */).then(c => wrapFunctional(c.default || c)),
  CompanyMembers: () => import('../../components/Company/CompanyMembers.vue' /* webpackChunkName: "components/company-members" */).then(c => wrapFunctional(c.default || c)),
  CompanyOverview: () => import('../../components/Company/CompanyOverview.vue' /* webpackChunkName: "components/company-overview" */).then(c => wrapFunctional(c.default || c)),
  CompanyTable: () => import('../../components/Company/CompanyTable.vue' /* webpackChunkName: "components/company-table" */).then(c => wrapFunctional(c.default || c)),
  CompanyUsage: () => import('../../components/Company/CompanyUsage.vue' /* webpackChunkName: "components/company-usage" */).then(c => wrapFunctional(c.default || c)),
  CompanySpecificsForm: () => import('../../components/CompanySpecifics/CompanySpecificsForm.vue' /* webpackChunkName: "components/company-specifics-form" */).then(c => wrapFunctional(c.default || c)),
  CompanySpecificsTable: () => import('../../components/CompanySpecifics/CompanySpecificsTable.vue' /* webpackChunkName: "components/company-specifics-table" */).then(c => wrapFunctional(c.default || c)),
  CompanyTypeForm: () => import('../../components/CompanyType/CompanyTypeForm.vue' /* webpackChunkName: "components/company-type-form" */).then(c => wrapFunctional(c.default || c)),
  CompanyTypeTable: () => import('../../components/CompanyType/CompanyTypeTable.vue' /* webpackChunkName: "components/company-type-table" */).then(c => wrapFunctional(c.default || c)),
  ContractCard: () => import('../../components/Contract/ContractCard.vue' /* webpackChunkName: "components/contract-card" */).then(c => wrapFunctional(c.default || c)),
  ContractDepositHistory: () => import('../../components/Contract/ContractDepositHistory.vue' /* webpackChunkName: "components/contract-deposit-history" */).then(c => wrapFunctional(c.default || c)),
  ContractDesksForm: () => import('../../components/Contract/ContractDesksForm.vue' /* webpackChunkName: "components/contract-desks-form" */).then(c => wrapFunctional(c.default || c)),
  ContractDesksFormDisplay: () => import('../../components/Contract/ContractDesksFormDisplay.vue' /* webpackChunkName: "components/contract-desks-form-display" */).then(c => wrapFunctional(c.default || c)),
  ContractExtrasForm: () => import('../../components/Contract/ContractExtrasForm.vue' /* webpackChunkName: "components/contract-extras-form" */).then(c => wrapFunctional(c.default || c)),
  ContractExtrasFormDisplay: () => import('../../components/Contract/ContractExtrasFormDisplay.vue' /* webpackChunkName: "components/contract-extras-form-display" */).then(c => wrapFunctional(c.default || c)),
  ContractFinanceFilter: () => import('../../components/Contract/ContractFinanceFilter.vue' /* webpackChunkName: "components/contract-finance-filter" */).then(c => wrapFunctional(c.default || c)),
  ContractFinancePartsTable: () => import('../../components/Contract/ContractFinancePartsTable.vue' /* webpackChunkName: "components/contract-finance-parts-table" */).then(c => wrapFunctional(c.default || c)),
  ContractFinanceTable: () => import('../../components/Contract/ContractFinanceTable.vue' /* webpackChunkName: "components/contract-finance-table" */).then(c => wrapFunctional(c.default || c)),
  ContractForm: () => import('../../components/Contract/ContractForm.vue' /* webpackChunkName: "components/contract-form" */).then(c => wrapFunctional(c.default || c)),
  ContractMeetingHoursForm: () => import('../../components/Contract/ContractMeetingHoursForm.vue' /* webpackChunkName: "components/contract-meeting-hours-form" */).then(c => wrapFunctional(c.default || c)),
  ContractMeetingHoursFormDisplay: () => import('../../components/Contract/ContractMeetingHoursFormDisplay.vue' /* webpackChunkName: "components/contract-meeting-hours-form-display" */).then(c => wrapFunctional(c.default || c)),
  ContractParkingsForm: () => import('../../components/Contract/ContractParkingsForm.vue' /* webpackChunkName: "components/contract-parkings-form" */).then(c => wrapFunctional(c.default || c)),
  ContractParkingsFormDisplay: () => import('../../components/Contract/ContractParkingsFormDisplay.vue' /* webpackChunkName: "components/contract-parkings-form-display" */).then(c => wrapFunctional(c.default || c)),
  ContractPassesForm: () => import('../../components/Contract/ContractPassesForm.vue' /* webpackChunkName: "components/contract-passes-form" */).then(c => wrapFunctional(c.default || c)),
  ContractPassesFormDisplay: () => import('../../components/Contract/ContractPassesFormDisplay.vue' /* webpackChunkName: "components/contract-passes-form-display" */).then(c => wrapFunctional(c.default || c)),
  ContractTable: () => import('../../components/Contract/ContractTable.vue' /* webpackChunkName: "components/contract-table" */).then(c => wrapFunctional(c.default || c)),
  ContractTemplateEditor: () => import('../../components/Contract/ContractTemplateEditor.vue' /* webpackChunkName: "components/contract-template-editor" */).then(c => wrapFunctional(c.default || c)),
  ContractsPage: () => import('../../components/Contract/ContractsPage.vue' /* webpackChunkName: "components/contracts-page" */).then(c => wrapFunctional(c.default || c)),
  ContractRemoveBuffer: () => import('../../components/Contract/contractRemoveBuffer.ts' /* webpackChunkName: "components/contract-remove-buffer" */).then(c => wrapFunctional(c.default || c)),
  ContractDepositStatusForm: () => import('../../components/ContractDepositStatus/ContractDepositStatusForm.vue' /* webpackChunkName: "components/contract-deposit-status-form" */).then(c => wrapFunctional(c.default || c)),
  ContractDepositStatusTable: () => import('../../components/ContractDepositStatus/ContractDepositStatusTable.vue' /* webpackChunkName: "components/contract-deposit-status-table" */).then(c => wrapFunctional(c.default || c)),
  ContractExtrasTable: () => import('../../components/ContractExtras/ContractExtrasTable.vue' /* webpackChunkName: "components/contract-extras-table" */).then(c => wrapFunctional(c.default || c)),
  ContractSpacePeriodPackagePriceOfficeForm: () => import('../../components/ContractSpacePeriodPackagePriceOffice/ContractSpacePeriodPackagePriceOfficeForm.vue' /* webpackChunkName: "components/contract-space-period-package-price-office-form" */).then(c => wrapFunctional(c.default || c)),
  ContractSpacePeriodPackagePriceOfficeTable: () => import('../../components/ContractSpacePeriodPackagePriceOffice/ContractSpacePeriodPackagePriceOfficeTable.vue' /* webpackChunkName: "components/contract-space-period-package-price-office-table" */).then(c => wrapFunctional(c.default || c)),
  ContractSpacePeriodPackagePriceRoomForm: () => import('../../components/ContractSpacePeriodPackagePriceRoom/ContractSpacePeriodPackagePriceRoomForm.vue' /* webpackChunkName: "components/contract-space-period-package-price-room-form" */).then(c => wrapFunctional(c.default || c)),
  ContractSpacePeriodPackagePriceRoomTable: () => import('../../components/ContractSpacePeriodPackagePriceRoom/ContractSpacePeriodPackagePriceRoomTable.vue' /* webpackChunkName: "components/contract-space-period-package-price-room-table" */).then(c => wrapFunctional(c.default || c)),
  CostFinanceFilter: () => import('../../components/Cost/CostFinanceFilter.vue' /* webpackChunkName: "components/cost-finance-filter" */).then(c => wrapFunctional(c.default || c)),
  CostFinanceTable: () => import('../../components/Cost/CostFinanceTable.vue' /* webpackChunkName: "components/cost-finance-table" */).then(c => wrapFunctional(c.default || c)),
  CostForm: () => import('../../components/Cost/CostForm.vue' /* webpackChunkName: "components/cost-form" */).then(c => wrapFunctional(c.default || c)),
  CostCategoryForm: () => import('../../components/CostCategory/CostCategoryForm.vue' /* webpackChunkName: "components/cost-category-form" */).then(c => wrapFunctional(c.default || c)),
  CostCategoryTable: () => import('../../components/CostCategory/CostCategoryTable.vue' /* webpackChunkName: "components/cost-category-table" */).then(c => wrapFunctional(c.default || c)),
  AvailabilityRadialGraph: () => import('../../components/Dashboard/AvailabilityRadialGraph.vue' /* webpackChunkName: "components/availability-radial-graph" */).then(c => wrapFunctional(c.default || c)),
  DashboardDailyOverview: () => import('../../components/Dashboard/DashboardDailyOverview.vue' /* webpackChunkName: "components/dashboard-daily-overview" */).then(c => wrapFunctional(c.default || c)),
  DashboardDailyOverviewCard: () => import('../../components/Dashboard/DashboardDailyOverviewCard.vue' /* webpackChunkName: "components/dashboard-daily-overview-card" */).then(c => wrapFunctional(c.default || c)),
  DashboardHeader: () => import('../../components/Dashboard/DashboardHeader.vue' /* webpackChunkName: "components/dashboard-header" */).then(c => wrapFunctional(c.default || c)),
  DashboardMemberPage: () => import('../../components/Dashboard/DashboardMemberPage.vue' /* webpackChunkName: "components/dashboard-member-page" */).then(c => wrapFunctional(c.default || c)),
  DashboardOverview: () => import('../../components/Dashboard/DashboardOverview.vue' /* webpackChunkName: "components/dashboard-overview" */).then(c => wrapFunctional(c.default || c)),
  DashboardSpaceInfo: () => import('../../components/Dashboard/DashboardSpaceInfo.vue' /* webpackChunkName: "components/dashboard-space-info" */).then(c => wrapFunctional(c.default || c)),
  DepositStatusForm: () => import('../../components/DepositStatus/DepositStatusForm.vue' /* webpackChunkName: "components/deposit-status-form" */).then(c => wrapFunctional(c.default || c)),
  DepositStatusTable: () => import('../../components/DepositStatus/DepositStatusTable.vue' /* webpackChunkName: "components/deposit-status-table" */).then(c => wrapFunctional(c.default || c)),
  ExtrasForm: () => import('../../components/Extras/ExtrasForm.vue' /* webpackChunkName: "components/extras-form" */).then(c => wrapFunctional(c.default || c)),
  ExtrasPricingByLocation: () => import('../../components/Extras/ExtrasPricingByLocation.vue' /* webpackChunkName: "components/extras-pricing-by-location" */).then(c => wrapFunctional(c.default || c)),
  ExtrasTable: () => import('../../components/Extras/ExtrasTable.vue' /* webpackChunkName: "components/extras-table" */).then(c => wrapFunctional(c.default || c)),
  ExtrasCategoryForm: () => import('../../components/ExtrasCategory/ExtrasCategoryForm.vue' /* webpackChunkName: "components/extras-category-form" */).then(c => wrapFunctional(c.default || c)),
  ExtrasCategoryTable: () => import('../../components/ExtrasCategory/ExtrasCategoryTable.vue' /* webpackChunkName: "components/extras-category-table" */).then(c => wrapFunctional(c.default || c)),
  FeesPreInvoices: () => import('../../components/Fees/FeesPreInvoices.vue' /* webpackChunkName: "components/fees-pre-invoices" */).then(c => wrapFunctional(c.default || c)),
  FeesTable: () => import('../../components/Fees/FeesTable.vue' /* webpackChunkName: "components/fees-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceFeeForm: () => import('../../components/Fees/InvoiceFeeForm.vue' /* webpackChunkName: "components/invoice-fee-form" */).then(c => wrapFunctional(c.default || c)),
  FloorChooser: () => import('../../components/Floor/FloorChooser.vue' /* webpackChunkName: "components/floor-chooser" */).then(c => wrapFunctional(c.default || c)),
  FloorForm: () => import('../../components/Floor/FloorForm.vue' /* webpackChunkName: "components/floor-form" */).then(c => wrapFunctional(c.default || c)),
  FloorLocationPlan: () => import('../../components/Floor/FloorLocationPlan.vue' /* webpackChunkName: "components/floor-location-plan" */).then(c => wrapFunctional(c.default || c)),
  FloorTable: () => import('../../components/Floor/FloorTable.vue' /* webpackChunkName: "components/floor-table" */).then(c => wrapFunctional(c.default || c)),
  FloorPlanViewer: () => import('../../components/FloorPlan/FloorPlanViewer.vue' /* webpackChunkName: "components/floor-plan-viewer" */).then(c => wrapFunctional(c.default || c)),
  FriendForm: () => import('../../components/Friend/FriendForm.vue' /* webpackChunkName: "components/friend-form" */).then(c => wrapFunctional(c.default || c)),
  FriendTable: () => import('../../components/Friend/FriendTable.vue' /* webpackChunkName: "components/friend-table" */).then(c => wrapFunctional(c.default || c)),
  FundamentalSpaceTypeForm: () => import('../../components/FundamentalSpaceType/FundamentalSpaceTypeForm.vue' /* webpackChunkName: "components/fundamental-space-type-form" */).then(c => wrapFunctional(c.default || c)),
  FundamentalSpaceTypeTable: () => import('../../components/FundamentalSpaceType/FundamentalSpaceTypeTable.vue' /* webpackChunkName: "components/fundamental-space-type-table" */).then(c => wrapFunctional(c.default || c)),
  NoContracts: () => import('../../components/Illustrations/NoContracts.vue' /* webpackChunkName: "components/no-contracts" */).then(c => wrapFunctional(c.default || c)),
  InboxDelimiter: () => import('../../components/Inbox/InboxDelimiter.vue' /* webpackChunkName: "components/inbox-delimiter" */).then(c => wrapFunctional(c.default || c)),
  InboxList: () => import('../../components/Inbox/InboxList.vue' /* webpackChunkName: "components/inbox-list" */).then(c => wrapFunctional(c.default || c)),
  InboxListItem: () => import('../../components/Inbox/InboxListItem.vue' /* webpackChunkName: "components/inbox-list-item" */).then(c => wrapFunctional(c.default || c)),
  InboxMessageInput: () => import('../../components/Inbox/InboxMessageInput.vue' /* webpackChunkName: "components/inbox-message-input" */).then(c => wrapFunctional(c.default || c)),
  InboxPill: () => import('../../components/Inbox/InboxPill.vue' /* webpackChunkName: "components/inbox-pill" */).then(c => wrapFunctional(c.default || c)),
  InboxUserCard: () => import('../../components/Inbox/InboxUserCard.vue' /* webpackChunkName: "components/inbox-user-card" */).then(c => wrapFunctional(c.default || c)),
  InboxUserDrawer: () => import('../../components/Inbox/InboxUserDrawer.vue' /* webpackChunkName: "components/inbox-user-drawer" */).then(c => wrapFunctional(c.default || c)),
  InboxUserMessages: () => import('../../components/Inbox/InboxUserMessages.vue' /* webpackChunkName: "components/inbox-user-messages" */).then(c => wrapFunctional(c.default || c)),
  InvoiceAdminTable: () => import('../../components/Invoice/InvoiceAdminTable.vue' /* webpackChunkName: "components/invoice-admin-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceCurrencyCalculation: () => import('../../components/Invoice/InvoiceCurrencyCalculation.vue' /* webpackChunkName: "components/invoice-currency-calculation" */).then(c => wrapFunctional(c.default || c)),
  InvoiceDisplayForm: () => import('../../components/Invoice/InvoiceDisplayForm.vue' /* webpackChunkName: "components/invoice-display-form" */).then(c => wrapFunctional(c.default || c)),
  InvoiceDisplayItemsTable: () => import('../../components/Invoice/InvoiceDisplayItemsTable.vue' /* webpackChunkName: "components/invoice-display-items-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceFinanceFilter: () => import('../../components/Invoice/InvoiceFinanceFilter.vue' /* webpackChunkName: "components/invoice-finance-filter" */).then(c => wrapFunctional(c.default || c)),
  InvoiceFinanceTable: () => import('../../components/Invoice/InvoiceFinanceTable.vue' /* webpackChunkName: "components/invoice-finance-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceForm: () => import('../../components/Invoice/InvoiceForm.vue' /* webpackChunkName: "components/invoice-form" */).then(c => wrapFunctional(c.default || c)),
  InvoiceItemsTable: () => import('../../components/Invoice/InvoiceItemsTable.vue' /* webpackChunkName: "components/invoice-items-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceTable: () => import('../../components/Invoice/InvoiceTable.vue' /* webpackChunkName: "components/invoice-table" */).then(c => wrapFunctional(c.default || c)),
  InvoicesPage: () => import('../../components/Invoice/InvoicesPage.vue' /* webpackChunkName: "components/invoices-page" */).then(c => wrapFunctional(c.default || c)),
  Calculator: () => import('../../components/Invoice/calculator.ts' /* webpackChunkName: "components/calculator" */).then(c => wrapFunctional(c.default || c)),
  InvoiceContractForm: () => import('../../components/InvoiceContract/InvoiceContractForm.vue' /* webpackChunkName: "components/invoice-contract-form" */).then(c => wrapFunctional(c.default || c)),
  InvoiceContractTable: () => import('../../components/InvoiceContract/InvoiceContractTable.vue' /* webpackChunkName: "components/invoice-contract-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceOneOfFeeForm: () => import('../../components/InvoiceOneOfFee/InvoiceOneOfFeeForm.vue' /* webpackChunkName: "components/invoice-one-of-fee-form" */).then(c => wrapFunctional(c.default || c)),
  InvoiceOneOfFeeTable: () => import('../../components/InvoiceOneOfFee/InvoiceOneOfFeeTable.vue' /* webpackChunkName: "components/invoice-one-of-fee-table" */).then(c => wrapFunctional(c.default || c)),
  InvoiceStatusForm: () => import('../../components/InvoiceStatus/InvoiceStatusForm.vue' /* webpackChunkName: "components/invoice-status-form" */).then(c => wrapFunctional(c.default || c)),
  InvoiceStatusTable: () => import('../../components/InvoiceStatus/InvoiceStatusTable.vue' /* webpackChunkName: "components/invoice-status-table" */).then(c => wrapFunctional(c.default || c)),
  LocationAdminForm: () => import('../../components/Location/LocationAdminForm.vue' /* webpackChunkName: "components/location-admin-form" */).then(c => wrapFunctional(c.default || c)),
  LocationFloorsForm: () => import('../../components/Location/LocationFloorsForm.vue' /* webpackChunkName: "components/location-floors-form" */).then(c => wrapFunctional(c.default || c)),
  LocationFloorsPricingForm: () => import('../../components/Location/LocationFloorsPricingForm.vue' /* webpackChunkName: "components/location-floors-pricing-form" */).then(c => wrapFunctional(c.default || c)),
  LocationForm: () => import('../../components/Location/LocationForm.vue' /* webpackChunkName: "components/location-form" */).then(c => wrapFunctional(c.default || c)),
  LocationFormExtended: () => import('../../components/Location/LocationFormExtended.vue' /* webpackChunkName: "components/location-form-extended" */).then(c => wrapFunctional(c.default || c)),
  LocationListSettings: () => import('../../components/Location/LocationListSettings.vue' /* webpackChunkName: "components/location-list-settings" */).then(c => wrapFunctional(c.default || c)),
  LocationListSettingsItem: () => import('../../components/Location/LocationListSettingsItem.vue' /* webpackChunkName: "components/location-list-settings-item" */).then(c => wrapFunctional(c.default || c)),
  LocationOfficesDetails: () => import('../../components/Location/LocationOfficesDetails.vue' /* webpackChunkName: "components/location-offices-details" */).then(c => wrapFunctional(c.default || c)),
  LocationOfficesForm: () => import('../../components/Location/LocationOfficesForm.vue' /* webpackChunkName: "components/location-offices-form" */).then(c => wrapFunctional(c.default || c)),
  LocationRoomDetails: () => import('../../components/Location/LocationRoomDetails.vue' /* webpackChunkName: "components/location-room-details" */).then(c => wrapFunctional(c.default || c)),
  LocationSpaceDetails: () => import('../../components/Location/LocationSpaceDetails.vue' /* webpackChunkName: "components/location-space-details" */).then(c => wrapFunctional(c.default || c)),
  LocationTabChooser: () => import('../../components/Location/LocationTabChooser.vue' /* webpackChunkName: "components/location-tab-chooser" */).then(c => wrapFunctional(c.default || c)),
  LocationTable: () => import('../../components/Location/LocationTable.vue' /* webpackChunkName: "components/location-table" */).then(c => wrapFunctional(c.default || c)),
  LocationTermsAndConditions: () => import('../../components/Location/LocationTermsAndConditions.vue' /* webpackChunkName: "components/location-terms-and-conditions" */).then(c => wrapFunctional(c.default || c)),
  LocationPassForm: () => import('../../components/LocationPass/LocationPassForm.vue' /* webpackChunkName: "components/location-pass-form" */).then(c => wrapFunctional(c.default || c)),
  LocationPassTable: () => import('../../components/LocationPass/LocationPassTable.vue' /* webpackChunkName: "components/location-pass-table" */).then(c => wrapFunctional(c.default || c)),
  LocationPassesByLocation: () => import('../../components/LocationPass/LocationPassesByLocation.vue' /* webpackChunkName: "components/location-passes-by-location" */).then(c => wrapFunctional(c.default || c)),
  MembersTable: () => import('../../components/Members/MembersTable.vue' /* webpackChunkName: "components/members-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeCard: () => import('../../components/OneOfFee/OneOfFeeCard.vue' /* webpackChunkName: "components/one-of-fee-card" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeFinanceFilter: () => import('../../components/OneOfFee/OneOfFeeFinanceFilter.vue' /* webpackChunkName: "components/one-of-fee-finance-filter" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeFinancePartsTable: () => import('../../components/OneOfFee/OneOfFeeFinancePartsTable.vue' /* webpackChunkName: "components/one-of-fee-finance-parts-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeFinanceTable: () => import('../../components/OneOfFee/OneOfFeeFinanceTable.vue' /* webpackChunkName: "components/one-of-fee-finance-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeForm: () => import('../../components/OneOfFee/OneOfFeeForm.vue' /* webpackChunkName: "components/one-of-fee-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeHoursTable: () => import('../../components/OneOfFee/OneOfFeeHoursTable.vue' /* webpackChunkName: "components/one-of-fee-hours-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeePostpaidForm: () => import('../../components/OneOfFee/OneOfFeePostpaidForm.vue' /* webpackChunkName: "components/one-of-fee-postpaid-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeePrepaidForm: () => import('../../components/OneOfFee/OneOfFeePrepaidForm.vue' /* webpackChunkName: "components/one-of-fee-prepaid-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeePrintTable: () => import('../../components/OneOfFee/OneOfFeePrintTable.vue' /* webpackChunkName: "components/one-of-fee-print-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeTable: () => import('../../components/OneOfFee/OneOfFeeTable.vue' /* webpackChunkName: "components/one-of-fee-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeUsageCard: () => import('../../components/OneOfFee/OneOfFeeUsageCard.vue' /* webpackChunkName: "components/one-of-fee-usage-card" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeUsageTable: () => import('../../components/OneOfFee/OneOfFeeUsageTable.vue' /* webpackChunkName: "components/one-of-fee-usage-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeesPage: () => import('../../components/OneOfFee/OneOfFeesPage.vue' /* webpackChunkName: "components/one-of-fees-page" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeExtrasForm: () => import('../../components/OneOfFeeExtras/OneOfFeeExtrasForm.vue' /* webpackChunkName: "components/one-of-fee-extras-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeExtrasTable: () => import('../../components/OneOfFeeExtras/OneOfFeeExtrasTable.vue' /* webpackChunkName: "components/one-of-fee-extras-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeSpacePeriodPackagePriceOfficeForm: () => import('../../components/OneOfFeeSpacePeriodPackagePriceOffice/OneOfFeeSpacePeriodPackagePriceOfficeForm.vue' /* webpackChunkName: "components/one-of-fee-space-period-package-price-office-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeSpacePeriodPackagePriceOfficeTable: () => import('../../components/OneOfFeeSpacePeriodPackagePriceOffice/OneOfFeeSpacePeriodPackagePriceOfficeTable.vue' /* webpackChunkName: "components/one-of-fee-space-period-package-price-office-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeSpacePeriodPackagePriceRoomForm: () => import('../../components/OneOfFeeSpacePeriodPackagePriceRoom/OneOfFeeSpacePeriodPackagePriceRoomForm.vue' /* webpackChunkName: "components/one-of-fee-space-period-package-price-room-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeSpacePeriodPackagePriceRoomTable: () => import('../../components/OneOfFeeSpacePeriodPackagePriceRoom/OneOfFeeSpacePeriodPackagePriceRoomTable.vue' /* webpackChunkName: "components/one-of-fee-space-period-package-price-room-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeUserLocationPassForm: () => import('../../components/OneOfFeeUserLocationPass/OneOfFeeUserLocationPassForm.vue' /* webpackChunkName: "components/one-of-fee-user-location-pass-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeUserLocationPassTable: () => import('../../components/OneOfFeeUserLocationPass/OneOfFeeUserLocationPassTable.vue' /* webpackChunkName: "components/one-of-fee-user-location-pass-table" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeUserLocationPassEntryForm: () => import('../../components/OneOfFeeUserLocationPassEntry/OneOfFeeUserLocationPassEntryForm.vue' /* webpackChunkName: "components/one-of-fee-user-location-pass-entry-form" */).then(c => wrapFunctional(c.default || c)),
  OneOfFeeUserLocationPassEntryTable: () => import('../../components/OneOfFeeUserLocationPassEntry/OneOfFeeUserLocationPassEntryTable.vue' /* webpackChunkName: "components/one-of-fee-user-location-pass-entry-table" */).then(c => wrapFunctional(c.default || c)),
  ParkingPricingAdminForm: () => import('../../components/ParkingPricing/ParkingPricingAdminForm.vue' /* webpackChunkName: "components/parking-pricing-admin-form" */).then(c => wrapFunctional(c.default || c)),
  ParkingPricingByLocationForm: () => import('../../components/ParkingPricing/ParkingPricingByLocationForm.vue' /* webpackChunkName: "components/parking-pricing-by-location-form" */).then(c => wrapFunctional(c.default || c)),
  ParkingPricingTable: () => import('../../components/ParkingPricing/ParkingPricingTable.vue' /* webpackChunkName: "components/parking-pricing-table" */).then(c => wrapFunctional(c.default || c)),
  ParkingSpotForm: () => import('../../components/ParkingSpot/ParkingSpotForm.vue' /* webpackChunkName: "components/parking-spot-form" */).then(c => wrapFunctional(c.default || c)),
  ParkingSpotTable: () => import('../../components/ParkingSpot/ParkingSpotTable.vue' /* webpackChunkName: "components/parking-spot-table" */).then(c => wrapFunctional(c.default || c)),
  ParkingOccupancyDetails: () => import('../../components/ParkingType/ParkingOccupancyDetails.vue' /* webpackChunkName: "components/parking-occupancy-details" */).then(c => wrapFunctional(c.default || c)),
  ParkingTypeForm: () => import('../../components/ParkingType/ParkingTypeForm.vue' /* webpackChunkName: "components/parking-type-form" */).then(c => wrapFunctional(c.default || c)),
  ParkingTypeListSettings: () => import('../../components/ParkingType/ParkingTypeListSettings.vue' /* webpackChunkName: "components/parking-type-list-settings" */).then(c => wrapFunctional(c.default || c)),
  ParkingTypeListSettingsItem: () => import('../../components/ParkingType/ParkingTypeListSettingsItem.vue' /* webpackChunkName: "components/parking-type-list-settings-item" */).then(c => wrapFunctional(c.default || c)),
  ParkingTypeTable: () => import('../../components/ParkingType/ParkingTypeTable.vue' /* webpackChunkName: "components/parking-type-table" */).then(c => wrapFunctional(c.default || c)),
  PassTypeForm: () => import('../../components/PassType/PassTypeForm.vue' /* webpackChunkName: "components/pass-type-form" */).then(c => wrapFunctional(c.default || c)),
  PassTypePricingView: () => import('../../components/PassType/PassTypePricingView.vue' /* webpackChunkName: "components/pass-type-pricing-view" */).then(c => wrapFunctional(c.default || c)),
  PassTypeTable: () => import('../../components/PassType/PassTypeTable.vue' /* webpackChunkName: "components/pass-type-table" */).then(c => wrapFunctional(c.default || c)),
  CompanyPayments: () => import('../../components/Payment/CompanyPayments.vue' /* webpackChunkName: "components/company-payments" */).then(c => wrapFunctional(c.default || c)),
  PaymentAllocationTable: () => import('../../components/Payment/PaymentAllocationTable.vue' /* webpackChunkName: "components/payment-allocation-table" */).then(c => wrapFunctional(c.default || c)),
  PaymentAllocator: () => import('../../components/Payment/PaymentAllocator.vue' /* webpackChunkName: "components/payment-allocator" */).then(c => wrapFunctional(c.default || c)),
  PaymentFinanceFilter: () => import('../../components/Payment/PaymentFinanceFilter.vue' /* webpackChunkName: "components/payment-finance-filter" */).then(c => wrapFunctional(c.default || c)),
  PaymentFinanceTable: () => import('../../components/Payment/PaymentFinanceTable.vue' /* webpackChunkName: "components/payment-finance-table" */).then(c => wrapFunctional(c.default || c)),
  PaymentForm: () => import('../../components/Payment/PaymentForm.vue' /* webpackChunkName: "components/payment-form" */).then(c => wrapFunctional(c.default || c)),
  CompanyPreinvoiceEditor: () => import('../../components/PreInvoice/CompanyPreinvoiceEditor.vue' /* webpackChunkName: "components/company-preinvoice-editor" */).then(c => wrapFunctional(c.default || c)),
  CompanyPreinvoicePreviewer: () => import('../../components/PreInvoice/CompanyPreinvoicePreviewer.vue' /* webpackChunkName: "components/company-preinvoice-previewer" */).then(c => wrapFunctional(c.default || c)),
  PreInvoiceForm: () => import('../../components/PreInvoice/PreInvoiceForm.vue' /* webpackChunkName: "components/pre-invoice-form" */).then(c => wrapFunctional(c.default || c)),
  PreInvoiceTable: () => import('../../components/PreInvoice/PreInvoiceTable.vue' /* webpackChunkName: "components/pre-invoice-table" */).then(c => wrapFunctional(c.default || c)),
  PreInvoiceContractForm: () => import('../../components/PreInvoiceContract/PreInvoiceContractForm.vue' /* webpackChunkName: "components/pre-invoice-contract-form" */).then(c => wrapFunctional(c.default || c)),
  PreInvoiceContractTable: () => import('../../components/PreInvoiceContract/PreInvoiceContractTable.vue' /* webpackChunkName: "components/pre-invoice-contract-table" */).then(c => wrapFunctional(c.default || c)),
  PreInvoiceOneOfFeeForm: () => import('../../components/PreInvoiceOneOfFee/PreInvoiceOneOfFeeForm.vue' /* webpackChunkName: "components/pre-invoice-one-of-fee-form" */).then(c => wrapFunctional(c.default || c)),
  PreInvoiceOneOfFeeTable: () => import('../../components/PreInvoiceOneOfFee/PreInvoiceOneOfFeeTable.vue' /* webpackChunkName: "components/pre-invoice-one-of-fee-table" */).then(c => wrapFunctional(c.default || c)),
  LocationSpacePricingForm: () => import('../../components/PriceList/LocationSpacePricingForm.vue' /* webpackChunkName: "components/location-space-pricing-form" */).then(c => wrapFunctional(c.default || c)),
  PriceListAll: () => import('../../components/PriceList/PriceListAll.vue' /* webpackChunkName: "components/price-list-all" */).then(c => wrapFunctional(c.default || c)),
  PriceListForm: () => import('../../components/PriceList/PriceListForm.vue' /* webpackChunkName: "components/price-list-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileSummaryCard: () => import('../../components/Profile/ProfileSummaryCard.vue' /* webpackChunkName: "components/profile-summary-card" */).then(c => wrapFunctional(c.default || c)),
  ProfileView: () => import('../../components/Profile/ProfileView.vue' /* webpackChunkName: "components/profile-view" */).then(c => wrapFunctional(c.default || c)),
  ReportOfficeFilter: () => import('../../components/ReportOffice/ReportOfficeFilter.vue' /* webpackChunkName: "components/report-office-filter" */).then(c => wrapFunctional(c.default || c)),
  ReportParkingFilter: () => import('../../components/ReportParking/ReportParkingFilter.vue' /* webpackChunkName: "components/report-parking-filter" */).then(c => wrapFunctional(c.default || c)),
  ReportParkingTable: () => import('../../components/ReportParking/ReportParkingTable.vue' /* webpackChunkName: "components/report-parking-table" */).then(c => wrapFunctional(c.default || c)),
  ReportRoomFilter: () => import('../../components/ReportRoom/ReportRoomFilter.vue' /* webpackChunkName: "components/report-room-filter" */).then(c => wrapFunctional(c.default || c)),
  ReportPage: () => import('../../components/Reports/ReportPage.vue' /* webpackChunkName: "components/report-page" */).then(c => wrapFunctional(c.default || c)),
  ReportSpaceFilter: () => import('../../components/Reports/ReportSpaceFilter.vue' /* webpackChunkName: "components/report-space-filter" */).then(c => wrapFunctional(c.default || c)),
  ReportTable: () => import('../../components/Reports/ReportTable.vue' /* webpackChunkName: "components/report-table" */).then(c => wrapFunctional(c.default || c)),
  RoleForm: () => import('../../components/Role/RoleForm.vue' /* webpackChunkName: "components/role-form" */).then(c => wrapFunctional(c.default || c)),
  RoleTable: () => import('../../components/Role/RoleTable.vue' /* webpackChunkName: "components/role-table" */).then(c => wrapFunctional(c.default || c)),
  RoomPeriodPriceForm: () => import('../../components/RoomPeriodPrice/RoomPeriodPriceForm.vue' /* webpackChunkName: "components/room-period-price-form" */).then(c => wrapFunctional(c.default || c)),
  RoomPeriodPriceTable: () => import('../../components/RoomPeriodPrice/RoomPeriodPriceTable.vue' /* webpackChunkName: "components/room-period-price-table" */).then(c => wrapFunctional(c.default || c)),
  RoomTypePeriodPackageForm: () => import('../../components/RoomTypePeriodPackage/RoomTypePeriodPackageForm.vue' /* webpackChunkName: "components/room-type-period-package-form" */).then(c => wrapFunctional(c.default || c)),
  RoomTypePeriodPackageTable: () => import('../../components/RoomTypePeriodPackage/RoomTypePeriodPackageTable.vue' /* webpackChunkName: "components/room-type-period-package-table" */).then(c => wrapFunctional(c.default || c)),
  SearchFloorPlan: () => import('../../components/Search/SearchFloorPlan.vue' /* webpackChunkName: "components/search-floor-plan" */).then(c => wrapFunctional(c.default || c)),
  SearchOffices: () => import('../../components/Search/SearchOffices.vue' /* webpackChunkName: "components/search-offices" */).then(c => wrapFunctional(c.default || c)),
  SearchParkings: () => import('../../components/Search/SearchParkings.vue' /* webpackChunkName: "components/search-parkings" */).then(c => wrapFunctional(c.default || c)),
  SearchSpaceTable: () => import('../../components/Search/SearchSpaceTable.vue' /* webpackChunkName: "components/search-space-table" */).then(c => wrapFunctional(c.default || c)),
  FriendRequestList: () => import('../../components/Social/FriendRequestList.vue' /* webpackChunkName: "components/friend-request-list" */).then(c => wrapFunctional(c.default || c)),
  SpaceCard: () => import('../../components/Space/SpaceCard.vue' /* webpackChunkName: "components/space-card" */).then(c => wrapFunctional(c.default || c)),
  SpaceForm: () => import('../../components/Space/SpaceForm.vue' /* webpackChunkName: "components/space-form" */).then(c => wrapFunctional(c.default || c)),
  SpacePricingTable: () => import('../../components/Space/SpacePricingTable.vue' /* webpackChunkName: "components/space-pricing-table" */).then(c => wrapFunctional(c.default || c)),
  SpaceSettingsFilter: () => import('../../components/Space/SpaceSettingsFilter.vue' /* webpackChunkName: "components/space-settings-filter" */).then(c => wrapFunctional(c.default || c)),
  SpaceTable: () => import('../../components/Space/SpaceTable.vue' /* webpackChunkName: "components/space-table" */).then(c => wrapFunctional(c.default || c)),
  SpacePeriodForm: () => import('../../components/SpacePeriod/SpacePeriodForm.vue' /* webpackChunkName: "components/space-period-form" */).then(c => wrapFunctional(c.default || c)),
  SpacePeriodTable: () => import('../../components/SpacePeriod/SpacePeriodTable.vue' /* webpackChunkName: "components/space-period-table" */).then(c => wrapFunctional(c.default || c)),
  SpacePeriodPricingForm: () => import('../../components/SpacePeriodPricing/SpacePeriodPricingForm.vue' /* webpackChunkName: "components/space-period-pricing-form" */).then(c => wrapFunctional(c.default || c)),
  SpacePeriodPricingTable: () => import('../../components/SpacePeriodPricing/SpacePeriodPricingTable.vue' /* webpackChunkName: "components/space-period-pricing-table" */).then(c => wrapFunctional(c.default || c)),
  SpaceTypePeriodPricingView: () => import('../../components/SpacePeriodPricing/SpaceTypePeriodPricingView.vue' /* webpackChunkName: "components/space-type-period-pricing-view" */).then(c => wrapFunctional(c.default || c)),
  SpaceTypeForm: () => import('../../components/SpaceType/SpaceTypeForm.vue' /* webpackChunkName: "components/space-type-form" */).then(c => wrapFunctional(c.default || c)),
  SpaceTypeListSettings: () => import('../../components/SpaceType/SpaceTypeListSettings.vue' /* webpackChunkName: "components/space-type-list-settings" */).then(c => wrapFunctional(c.default || c)),
  SpaceTypeListSettingsItem: () => import('../../components/SpaceType/SpaceTypeListSettingsItem.vue' /* webpackChunkName: "components/space-type-list-settings-item" */).then(c => wrapFunctional(c.default || c)),
  SpaceTypeTable: () => import('../../components/SpaceType/SpaceTypeTable.vue' /* webpackChunkName: "components/space-type-table" */).then(c => wrapFunctional(c.default || c)),
  StatusCompanyForm: () => import('../../components/StatusCompany/StatusCompanyForm.vue' /* webpackChunkName: "components/status-company-form" */).then(c => wrapFunctional(c.default || c)),
  StatusCompanyTable: () => import('../../components/StatusCompany/StatusCompanyTable.vue' /* webpackChunkName: "components/status-company-table" */).then(c => wrapFunctional(c.default || c)),
  StatusMemberForm: () => import('../../components/StatusMember/StatusMemberForm.vue' /* webpackChunkName: "components/status-member-form" */).then(c => wrapFunctional(c.default || c)),
  StatusMemberTable: () => import('../../components/StatusMember/StatusMemberTable.vue' /* webpackChunkName: "components/status-member-table" */).then(c => wrapFunctional(c.default || c)),
  SupplierForm: () => import('../../components/Supplier/SupplierForm.vue' /* webpackChunkName: "components/supplier-form" */).then(c => wrapFunctional(c.default || c)),
  SupplierTable: () => import('../../components/Supplier/SupplierTable.vue' /* webpackChunkName: "components/supplier-table" */).then(c => wrapFunctional(c.default || c)),
  TaxRateForm: () => import('../../components/TaxRate/TaxRateForm.vue' /* webpackChunkName: "components/tax-rate-form" */).then(c => wrapFunctional(c.default || c)),
  TaxRateTable: () => import('../../components/TaxRate/TaxRateTable.vue' /* webpackChunkName: "components/tax-rate-table" */).then(c => wrapFunctional(c.default || c)),
  TermsEditor: () => import('../../components/Terms/TermsEditor.vue' /* webpackChunkName: "components/terms-editor" */).then(c => wrapFunctional(c.default || c)),
  TicketCard: () => import('../../components/Ticket/TicketCard.vue' /* webpackChunkName: "components/ticket-card" */).then(c => wrapFunctional(c.default || c)),
  TicketDisplay: () => import('../../components/Ticket/TicketDisplay.vue' /* webpackChunkName: "components/ticket-display" */).then(c => wrapFunctional(c.default || c)),
  TicketForm: () => import('../../components/Ticket/TicketForm.vue' /* webpackChunkName: "components/ticket-form" */).then(c => wrapFunctional(c.default || c)),
  TicketTable: () => import('../../components/Ticket/TicketTable.vue' /* webpackChunkName: "components/ticket-table" */).then(c => wrapFunctional(c.default || c)),
  UserAdminTable: () => import('../../components/User/UserAdminTable.vue' /* webpackChunkName: "components/user-admin-table" */).then(c => wrapFunctional(c.default || c)),
  UserCollaborationTable: () => import('../../components/User/UserCollaborationTable.vue' /* webpackChunkName: "components/user-collaboration-table" */).then(c => wrapFunctional(c.default || c)),
  UserCompanyMembersTable: () => import('../../components/User/UserCompanyMembersTable.vue' /* webpackChunkName: "components/user-company-members-table" */).then(c => wrapFunctional(c.default || c)),
  UserForm: () => import('../../components/User/UserForm.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c)),
  UserLocationPassForm: () => import('../../components/UserLocationPass/UserLocationPassForm.vue' /* webpackChunkName: "components/user-location-pass-form" */).then(c => wrapFunctional(c.default || c)),
  UserLocationPassTable: () => import('../../components/UserLocationPass/UserLocationPassTable.vue' /* webpackChunkName: "components/user-location-pass-table" */).then(c => wrapFunctional(c.default || c)),
  UserLocationPassEntryForm: () => import('../../components/UserLocationPassEntry/UserLocationPassEntryForm.vue' /* webpackChunkName: "components/user-location-pass-entry-form" */).then(c => wrapFunctional(c.default || c)),
  UserLocationPassEntryTable: () => import('../../components/UserLocationPassEntry/UserLocationPassEntryTable.vue' /* webpackChunkName: "components/user-location-pass-entry-table" */).then(c => wrapFunctional(c.default || c)),
  UserPostCard: () => import('../../components/UserPost/UserPostCard.vue' /* webpackChunkName: "components/user-post-card" */).then(c => wrapFunctional(c.default || c)),
  UserPostComments: () => import('../../components/UserPost/UserPostComments.vue' /* webpackChunkName: "components/user-post-comments" */).then(c => wrapFunctional(c.default || c)),
  UserPostDisplay: () => import('../../components/UserPost/UserPostDisplay.vue' /* webpackChunkName: "components/user-post-display" */).then(c => wrapFunctional(c.default || c)),
  UserPostForm: () => import('../../components/UserPost/UserPostForm.vue' /* webpackChunkName: "components/user-post-form" */).then(c => wrapFunctional(c.default || c)),
  UserPostTable: () => import('../../components/UserPost/UserPostTable.vue' /* webpackChunkName: "components/user-post-table" */).then(c => wrapFunctional(c.default || c)),
  UserPostCommentForm: () => import('../../components/UserPostComment/UserPostCommentForm.vue' /* webpackChunkName: "components/user-post-comment-form" */).then(c => wrapFunctional(c.default || c)),
  UserPostCommentTable: () => import('../../components/UserPostComment/UserPostCommentTable.vue' /* webpackChunkName: "components/user-post-comment-table" */).then(c => wrapFunctional(c.default || c)),
  NoCostsAnimation: () => import('../../components/common/NoCostsAnimation.vue' /* webpackChunkName: "components/no-costs-animation" */).then(c => wrapFunctional(c.default || c)),
  NoDataAnimation: () => import('../../components/common/NoDataAnimation.vue' /* webpackChunkName: "components/no-data-animation" */).then(c => wrapFunctional(c.default || c)),
  NoScheduleAnimation: () => import('../../components/common/NoScheduleAnimation.vue' /* webpackChunkName: "components/no-schedule-animation" */).then(c => wrapFunctional(c.default || c)),
  ExtrasCategoryView: () => import('../../components/extras/ExtrasCategoryView.vue' /* webpackChunkName: "components/extras-category-view" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
