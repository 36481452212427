import Vue from 'vue'
import { User } from '~/entities/user'

Vue.prototype.$deepCopy = function (obj) {
  if (!obj) {
    return
  }
  try {
    return JSON.parse(JSON.stringify(obj))
  } catch (error) {
    console.log('ERROR: ', error)
  }
}

Vue.prototype.$truncateText = function (text: string, chars = 50) {
  if (text.length <= chars) {
    return text
  }
  return text.slice(0, chars) + '...'
}

Vue.prototype.$formatPercent = function (n) {
  if (isNaN(Number(n))) {
    return `/`
  }
  return `${Number(n).toFixed(2)}%`
}

Vue.prototype.$getStatusColor = function (status) {
  const colors = {
    ACTIVE: '#df48ef',
    'DROP IN': 'blue',
    FORMER: 'grey',
    INACTIVE: 'grey',
  }

  return (status && status.name && colors[status.name]) || 'blue'
}

interface statusIcon {
  ACTIVE: string
  FORMER: string
  'DROP IN': string
  INACTIVE: string
}

Vue.prototype.$getStatusIcon = function (status) {
  const icons: statusIcon = {
    ACTIVE: 'mdi-checkbox-marked-circle',
    FORMER: 'mdi-close-circle-outline',
    'DROP IN': 'mdi-water',
    INACTIVE: 'mdi-close-circle-outline',
  }

  return (status && status.name && icons[status.name]) || 'mdi-help-circle'
}

Vue.prototype.$download = function (dataBase64, fileName) {
  console.log('DOWNLOADING PDF')
  console.log({ dataBase64 })
  const element = document.createElement('a')
  element.setAttribute('href', 'data:application/pdf;base64,' + dataBase64)
  element.setAttribute('download', fileName)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

Vue.prototype.$downloadFromByteArray = function (bytes, fileName) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([bytes], { type: 'application/pdf' })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = 'file.pdf'
  link.click()
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}

Vue.prototype.$UTC = function (date: Date): number {
  console.log('getting utc for', date)
  if (!date) {
    return +new Date()
  }
  return Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  )
}

Vue.prototype.$getTime = (s: string | Date): string => {
  let dateString: string = ''
  if (s instanceof Date) {
    dateString = s.toISOString()
  } else {
    dateString = s
  }
  try {
    return dateString.split('T')[1].substr(0, 5)
  } catch (error) {
    return ''
  }
}

Vue.prototype.$getYearsInRange = function (start: Date, end: Date) {
  try {
    return this.$dateFns
      .eachYearOfInterval({
        start: new Date(start),
        end: new Date(end),
      })
      .map((date: any) => this.$dateFns.getYear(date))
  } catch (err) {
    console.error(err)
  }
}

Vue.prototype.$getProfilePictureUrl = function (userId: number) {
  // console.log('fetching avatar for ', userId)
  const token = process.env.TOKEN_NAME
    ? localStorage.getItem(process.env.TOKEN_NAME)
    : ''
  return process.env.BASE_URL + `/user/${userId}/avatar?token=${token}`
}

Vue.prototype.$getUserInitials = function (user: User) {
  try {
    return user.name
      .split(' ')
      .map((part) => part[0])
      .join('')
  } catch (error) {
    if (user.name && user.name.length > 2) {
      return user.name.substr(0, 2)
    }
    return ''
  }
}

Vue.prototype.$showDialog = function (dialog: object) {
  this.$bus.$emit('show-dialog', dialog)
}

declare module 'vue/types/vue' {
  interface Vue {
    $download: (dataBase64: string, filename: string) => void
    $getStatusIcon: (stateus: string) => string
    $deepCopy: (obj: object) => any
    $getStatusColor: (status: string) => string
    $discount: (value: number, discount: number) => number
    $UTC: (date: Date) => number
    $dateFns: any
    $getYearsInRange: (start: Date, end: Date) => Array<number>
    $getProfilePictureUrl: (userId: number) => string
    $getUserInitials: (user: User) => string
    $showDialog: (dialog: object) => void
    $downloadFromByteArray: (bytes: any, fileName: string) => void
  }
}

Vue.prototype.$downloadXLS = function (dataBase64, fileName) {
  console.log('downloading xls')
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
      dataBase64
  )
  element.setAttribute('download', fileName)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}

Vue.prototype.$dateISOSort = (e1, e2) => {
  if (!e1 || !e2) {
    return 0
  }
  // new date might fail
  try {
    const d1 = new Date(e1)
    const d2 = new Date(e2)
    return d1 < d2 ? 1 : -1
  } catch (error) {
    return 0
  }
}
