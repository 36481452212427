




import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseIcon extends Vue {
  @Prop({ required: true, default: 'user' }) name!: string
  // @Prop({ required: true, default: '28px' }) width: string
  // @Prop({ required: true, default: '28px' }) height: string

  get svg() {
    return require(`~/assets/icons/svg/${this.name}.svg?raw`)
  }

  mounted() {
    // alert('MOUNED!!!')
  }
}
