import { UserLocationPassEntry } from '@/entities/userLocationPassEntry.ts'

export const state = () => ({
  userLocationPassEntry: new UserLocationPassEntry(),
})

export const getters = {
  getUserLocationPassEntry: (state) => {
    return state.userLocationPassEntry
  },
}

export const mutations = {
  SET_USER_LOCATION_PASS_ENTRY: (state, payload) => {
    state.userLocationPassEntry = payload
  },
}

export const actions = {
  setUserLocationPassEntry({ commit }, payload) {
    commit('SET_USER_LOCATION_PASS_ENTRY', payload)
  },

  async getUserLocationPassEntryById({ commit }, id) {
    const r = await this.$axios.$get(`/user-location-pass-entry/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: userLocationPassEntry')
    }
    commit('SET_USER_LOCATION_PASS_ENTRY', r.data)
  },

  async createUserLocationPassEntry({ commit, getters }, payload) {
    if (payload) {
      commit('SET_USER_LOCATION_PASS_ENTRY', payload)
    }
    const r = await this.$axios.$post(
      `/user-location-pass-entry`,
      getters.getUserLocationPassEntry
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: userLocationPassEntry'
      )
    }
    commit('SET_USER_LOCATION_PASS_ENTRY', r.data)
  },

  async saveUserLocationPassEntry({ commit, getters }, payload) {
    if (payload) {
      commit('SET_USER_LOCATION_PASS_ENTRY', payload)
    }
    const r = await this.$axios.$put(
      `/user-location-pass-entry/${getters.getUserLocationPassEntry.id}`,
      getters.getUserLocationPassEntry
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: userLocationPassEntry'
      )
    }
    commit('SET_USER_LOCATION_PASS_ENTRY', r.data)
  },

  async deleteUserLocationPassEntry({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_USER_LOCATION_PASS_ENTRY', payload)
    await this.$axios.$delete(
      `/user-location-pass-entry/${getters.getUserLocationPassEntry.id}`
    )
    commit('SET_USER_LOCATION_PASS_ENTRY', null)
  },
}
