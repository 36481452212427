import Vue from 'vue'

export const state = () => ({
  extrasCategories: [],

  locationExtrasCategories: {},
})

export const getters = {
  getExtrasCategories: (state) => {
    return state.extrasCategories
  },
  getExtrasCategoriesByLocation: (state) =>
    state.locationExtrasCategories || {},

  getExtrasCategoriesByLocationID: (state) => (locationID) => {
    if (!state.locationExtrasCategories) {
      state.locationExtrasCategories = {}
    }
    return state.locationExtrasCategories[locationID]
  },
}

export const mutations = {
  SET_EXTRAS_CATEGORIES: (state, extrasCategories) => {
    state.extrasCategories = extrasCategories
  },
  SET_EXTRAS_CATEGORY_CATEGORIES_BY_LOCATION_ID: (
    state,
    { locationID, extrasCategories }
  ) => {
    if (!state.locationExtrasCategories) {
      state.locationExtrasCategories = {}
    }
    Vue.set(state.locationExtrasCategories, locationID, extrasCategories)
  },
}

export const actions = {
  setExtrasCategories({ commit }, extrasCategories) {
    commit('SET_EXTRAS_CATEGORIES', extrasCategories)
  },

  async setupExtrasCategories({ commit }) {
    const r = await this.$axios.$get(`/extras`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting extras')
    }
    commit('SET_EXTRAS_CATEGORIES', r.data)
  },

  async setupExtrasCategoriesByLocationID({ commit }, locationID) {
    const { data } = await this.$axios.$get(
      `/location/${locationID}/extras-category`
    )
    commit('SET_EXTRAS_CATEGORY_CATEGORIES_BY_LOCATION_ID', {
      locationID,
      extrasCategories: data,
    })
  },
}
