import { Extras } from '@/entities/extras.ts'

export const state = () => ({
  extras: new Extras(),
})

export const getters = {
  getExtras: (state) => {
    return state.extras
  },
}

export const mutations = {
  SET_EXTRAS: (state, payload) => {
    state.extras = payload
  },
}

export const actions = {
  setExtras({ commit }, payload) {
    commit('SET_EXTRAS', payload)
  },

  async getExtrasById({ commit }, id) {
    const r = await this.$axios.$get(`/extras/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: extras')
    }
    commit('SET_EXTRAS', r.data)
  },

  async createExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_EXTRAS', payload)
    }
    const r = await this.$axios.$post(`/extras`, getters.getExtras)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: extras')
    }
    commit('SET_EXTRAS', r.data)
  },

  async saveExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_EXTRAS', payload)
    }
    const r = await this.$axios.$put(
      `/extras/${getters.getExtras.id}`,
      getters.getExtras
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: extras')
    }
    commit('SET_EXTRAS', r.data)
  },

  async deleteExtras({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    if (!confirm('Are you sure that you want to delete?')) {
      return
    }

    commit('SET_EXTRAS', payload)
    await this.$axios.$delete(`/extras/${getters.getExtras.id}`)
    commit('SET_EXTRAS', null)
  },
}
