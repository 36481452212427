import { Ticket } from '@/entities/ticket.ts'

export const state = () => ({
  ticket: new Ticket(),
})

export const getters = {
  getTicket: (state) => {
    return state.ticket
  },
}

export const mutations = {
  SET_TICKET: (state, payload) => {
    state.ticket = payload
  },
}

export const actions = {
  setTicket({ commit }, payload) {
    commit('SET_TICKET', payload)
  },

  async getTicketById({ commit }, id) {
    const r = await this.$axios.$get(`/ticket/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: ticket')
    }
    commit('SET_TICKET', r.data)
  },

  async createTicket({ commit }, payload) {
    if (!payload) {
      throw new Error('payload must be provided')
    }
    const { data } = await this.$axios.$post(`/ticket`, payload)
    commit('SET_TICKET', data)
  },

  async saveTicket({ commit }, payload) {
    if (!payload) {
      throw new Error('you msut provide payload')
    }
    if (payload.id <= 0) {
      throw new Error('store(ticket) saveTicket: payload must have id >= 0')
    }
    const { data } = await this.$axios.$put(`/ticket/${payload.id}`, payload)
    commit('SET_TICKET', data)
  },

  async deleteTicket({ commit }, payload) {
    if (!payload) {
      throw new Error('you msut provide payload')
    }
    if (payload.id <= 0) {
      throw new Error('store(ticket) saveTicket: payload must have id >= 0')
    }
    await this.$axios.$delete(`/ticket/${payload.id}`)
    commit('SET_TICKET', null)
  },
}
