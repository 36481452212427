export class OneOfFee {
  id: number | undefined
  dateCreated: Date | string
  startDate: Date | string
  dueDate?: Date | string
  endDate: Date | string
  number: string
  user: object | null
  company: object | null
  oneOfFeeuserLocationPassSet: object | null
  oneOfFeespacePeriodPackagePriceOfficeSet: object | null
  oneOfFeespacePeriodPackagePriceRoomSet: object | null
  extrasOneOfFeeSet: object | null
  location: Location | null

  constructor() {
    this.dateCreated = ''
    this.startDate = ''
    this.endDate = ''
    this.number = ''
    this.user = null
    this.company = null
    this.oneOfFeeuserLocationPassSet = []
    this.oneOfFeespacePeriodPackagePriceOfficeSet = []
    this.oneOfFeespacePeriodPackagePriceRoomSet = []
    this.extrasOneOfFeeSet = []
    this.location = null
  }
}
