export const state = () => ({
  preInvoiceContracts: [],
})

export const getters = {
  getPreInvoiceContracts: (state) => {
    return state.preInvoiceContracts
  },
}

export const mutations = {
  SET_PRE_INVOICE_CONTRACTS: (state, preInvoiceContracts) => {
    state.preInvoiceContracts = preInvoiceContracts
  },
}

export const actions = {
  setPreInvoiceContracts({ commit }, preInvoiceContracts) {
    commit('SET_PRE_INVOICE_CONTRACTS', preInvoiceContracts)
  },

  async setupPreInvoiceContracts({ commit }) {
    const r = await this.$axios.$get(`/pre-invoice-contract`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting preInvoiceContract')
    }
    commit('SET_PRE_INVOICE_CONTRACTS', r.data)
  },
}
