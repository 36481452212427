export const state = () => ({
  taxRates: [],
})

export const getters = {
  getTaxRates: (state) => {
    return state.taxRates
  },
}

export const mutations = {
  SET_TAX_RATES: (state, taxRates) => {
    state.taxRates = taxRates
  },
}

export const actions = {
  setTaxRates({ commit }, taxRates) {
    commit('SET_TAX_RATES', taxRates)
  },

  async setupTaxRates({ commit }) {
    const r = await this.$axios.$get(`/tax-rate`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting taxRate')
    }
    commit('SET_TAX_RATES', r.data)
  },
}
