export class RoomPeriodPrice {
  id: number | undefined
  price: object | null
  roomTypePeriodPackage: object | null

  constructor() {
    this.price = null
    this.roomTypePeriodPackage = null
  }
}
