import { Location } from './location'
export class Contract {
  id: number | undefined
  dateCreated: Date | string
  startDate: Date | string
  endDate: Date | string
  dueDate?: Date | string
  indefinite: boolean
  number: string
  user: object | null
  company: object | null
  userLocationPassSet: object | null
  contractSpacePeriodPackagePriceOfficeSet: object | null
  contractSpacePeriodPackagePriceRoomSet: object | null
  deposit: object | null
  depositStatus: object | null
  contractDepositeStatusSet: object | null
  contractParkingSet: object | null
  contractExtrasSet: object | null
  annex: object | null
  oldContract: object | null
  dateOfContract: Date | string | null
  contractTemplateText: string
  location: Location | null

  constructor() {
    this.dateCreated = new Date().toISOString()
    this.startDate = ''
    this.endDate = ''
    this.indefinite = false
    this.number = ''
    this.user = null
    this.company = null
    this.userLocationPassSet = []
    this.contractSpacePeriodPackagePriceOfficeSet = []
    this.contractSpacePeriodPackagePriceRoomSet = []
    this.deposit = null
    this.depositStatus = null
    this.contractDepositeStatusSet = []
    this.contractParkingSet = []
    this.contractExtrasSet = []
    this.annex = null
    this.oldContract = null
    this.dateOfContract = ''
    this.contractTemplateText = ''
    this.location = null
  }
}
