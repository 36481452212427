import Vue from 'vue'

export const state = () => ({
  parkingPricings: [],

  locationParkingPricings: {},
})

export const getters = {
  getParkingPricings: (state) => {
    return state.parkingPricings
  },

  getParkingPricingsByLocationID: (state) => (locationID) =>
    state.locationParkingPricings[locationID],
}

export const mutations = {
  SET_PARKING_PRICINGs: (state, parkingPricings) => {
    state.parkingPricings = parkingPricings
  },
  SET_PARKING_PRICINGS_BY_LOCATION_ID(state, { locationID, parkingPricings }) {
    if (!state.locationParkingPricings) {
      state.locationParkingPricings = {}
    }
    Vue.set(state.locationParkingPricings, locationID, parkingPricings)
  },
}

export const actions = {
  setParkingPricings({ commit }, parkingPricings) {
    commit('SET_PARKINGPRICINGs', parkingPricings)
  },

  async setupParkingPricings({ commit }) {
    const r = await this.$axios.$get(`/parking-pricing`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting parkingPricing')
    }
    commit('SET_PARKING_PRICINGs', r.data)
  },

  async setupParkingPricingsByLocationID({ commit, getters }, locationID) {
    if (getters.getParkingPricingsByLocationID(locationID)) {
      // use cache
      return
    }
    const { data } = await this.$axios.$get(
      `/location/${locationID}/parking-pricing`
    )
    commit('SET_PARKING_PRICINGS_BY_LOCATION_ID', {
      locationID,
      parkingPricings: data,
    })
  },
}
