import { BaseTimeUnit } from '@/entities/baseTimeUnit.ts'

export const state = () => ({
  baseTimeUnit: new BaseTimeUnit(),
})

export const getters = {
  getBaseTimeUnit: (state) => {
    return state.baseTimeUnit
  },
}

export const mutations = {
  SET_BASE_TIME_UNIT: (state, payload) => {
    state.baseTimeUnit = payload
  },
}

export const actions = {
  setBaseTimeUnit({ commit }, payload) {
    commit('SET_BASE_TIME_UNIT', payload)
  },

  async getBaseTimeUnitById({ commit }, id) {
    const r = await this.$axios.$get(`/base-time-unit/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: baseTimeUnit')
    }
    commit('SET_BASE_TIME_UNIT', r.data)
  },

  async createBaseTimeUnit({ commit, getters }, payload) {
    if (payload) {
      commit('SET_BASE_TIME_UNIT', payload)
    }
    const r = await this.$axios.$post(
      `/base-time-unit`,
      getters.getBaseTimeUnit
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: baseTimeUnit')
    }
    commit('SET_BASE_TIME_UNIT', r.data)
  },

  async saveBaseTimeUnit({ commit, getters }, payload) {
    if (payload) {
      commit('SET_BASE_TIME_UNIT', payload)
    }
    const r = await this.$axios.$put(
      `/base-time-unit/${getters.getBaseTimeUnit.id}`,
      getters.getBaseTimeUnit
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: baseTimeUnit')
    }
    commit('SET_BASE_TIME_UNIT', r.data)
  },

  async deleteBaseTimeUnit({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_BASE_TIME_UNIT', payload)
    await this.$axios.$delete(`/base-time-unit/${getters.getBaseTimeUnit.id}`)
    commit('SET_BASE_TIME_UNIT', null)
  },
}
