export const state = () => ({
  spacePeriods: [],
})

export const getters = {
  getSpacePeriods: (state) => {
    return state.spacePeriods
  },
}

export const mutations = {
  SET_SPACE_PERIODs: (state, spacePeriods) => {
    state.spacePeriods = spacePeriods
  },
}

export const actions = {
  setSpacePeriods({ commit }, spacePeriods) {
    commit('SET_SPACEPERIODs', spacePeriods)
  },

  async setupSpacePeriods({ commit }) {
    const r = await this.$axios.$get(`/space-period`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting spacePeriod')
    }
    commit('SET_SPACE_PERIODs', r.data)
  },
}
