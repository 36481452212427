import { CompanyTypeID } from '~/entities/companyType'
import { User } from '~/entities/user'

export const state = () => ({
  users: [],
})

export const getters = {
  getUsers: (state) => {
    return state.users
  },

  getMyCompanyUsers: (state, _getters, _rootState, rootGetters) => {
    if (!rootGetters['user/getUser'].company) {
      return state.users
    }
    return state.users.filter(
      (e) => e?.company?.id === rootGetters['user/getUser']?.company?.id
    )
  },

  getUsersByCompanyID: (state) => (companyID) => {
    return state.users.filter(
      (e) => e?.company?.id === Number(companyID) && companyID
    )
  },
}

export const mutations = {
  SET_USERS: (state, users) => {
    const us = []
    for (const u of users) {
      const user = new User().from(u)
      user.memberType =
        user.company &&
        user.company.companyType &&
        user.company.companyType.id === CompanyTypeID.FREELANCE
          ? 'FREELANCE MEMBER'
          : 'COMPANY MEMBER'
      us.push(user)
    }
    state.users = us
  },
}

export const actions = {
  setUsers({ commit }, users) {
    commit('SET_USERS', users)
  },

  async setupUsers({ commit }) {
    const { data } = await this.$axios.$get(`/user`)
    if (!Array.isArray(data)) {
      throw new TypeError('response is not array in setupUsers')
    }
    commit('SET_USERS', data)
  },
}
