import { Invoice } from '@/entities/invoice.ts'

export const state = () => ({
  invoice: new Invoice(),

  invoiceContracts: [],
  invoiceOneOfFees: [],
  invoicePostPaids: [],
  invoicePreInvoices: [],
})

export const getters = {
  getInvoice: (state) => {
    return state.invoice
  },
  getInvoiceContracts: (state) => state.invoiceContracts,
  getInvoiceOneOfFees: (state) => state.invoiceOneOfFees,
  getInvoicePostPaids: (state) => state.invoicePostPaids,
  getInvoicePreInvoices: (state) => state.invoicePreInvoices,
}

export const mutations = {
  SET_INVOICE: (state, payload) => {
    state.invoice = payload
  },

  SET_INVOICE_CONTRACTS: (state, payload) => {
    state.invoiceContracts = payload
  },

  SET_INVOICE_ONE_OF_FEES: (state, payload) => {
    state.invoiceOneOfFees = payload
  },

  SET_INVOICE_POSTPAIDS: (state, payload) => {
    state.invoicePostPaids = payload
  },

  SET_INVOICE_PRE_INVOICES: (state, payload) => {
    let pl = payload
    pl.forEach((val, index) => {
      pl[index].preInvoice.currencyPreInvoiceId = val.preInvoice.id
    })
    state.invoicePreInvoices = pl
  },
}

export const actions = {
  setInvoice({ commit }, payload) {
    commit('SET_INVOICE', payload)
  },

  async getInvoiceById({ commit }, id) {
    const r = await this.$axios.$get(`/invoice/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: invoice')
    }
    commit('SET_INVOICE', r.data)
  },

  async setupInvoiceContracts({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/invoice/contract-period`
    )
    commit('SET_INVOICE_CONTRACTS', data)
  },

  async setupInvoiceOneOfFees({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/invoice/one-of-fee-period`
    )
    commit('SET_INVOICE_ONE_OF_FEES', data)
  },

  async setupInvoicePostPaids({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/invoice/post-paid`
    )
    commit('SET_INVOICE_POSTPAIDS', data)
  },

  async setupInvoicePreInvoices({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/invoice/pre-invoice`
    )
    commit('SET_INVOICE_PRE_INVOICES', data)
  },

  async createInvoiceByCompanyID({ commit }, { companyID, invoice }) {
    const r = await this.$axios.$post(`/company/${companyID}/invoice`, invoice)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: invoice')
    }
    commit('SET_INVOICE', r.data)
  },

  // ENDPOINT DOES NOT EXIST YET
  async createInvoice({ commit }, payload) {
    const r = await this.$axios.$post(`/invoice`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: invoice')
    }
    commit('SET_INVOICE', r.data)
  },

  async saveInvoice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_INVOICE', payload)
    }
    const r = await this.$axios.$put(
      `/invoice/${getters.getInvoice.id}`,
      getters.getInvoice
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: invoice')
    }
    commit('SET_INVOICE', r.data)
  },

  async deleteInvoice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_INVOICE', payload)
    }
    await this.$axios.$delete(`/invoice/${getters.getInvoice.id}`)
    commit('SET_INVOICE', null)
  },

  async deleteInvoiceByID(_, invoiceID) {
    await this.$axios.$delete(`/invoice/${invoiceID}`)
  },

  async patchInvoiceStatus(_, { id, statusID }) {
    await this.$axios.$patch(`/invoice/${id}/invoice-status/${statusID}`)
  },
}
