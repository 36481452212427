export const state = () => ({
  contractParkings: [],
})

export const getters = {
  getContractParkings: (state) => {
    return state.contractParkings
  },
}

export const mutations = {
  SET_CONTRACT_PARKINGS: (state, contractParkings) => {
    state.contractParkingS = contractParkings
  },
}

export const actions = {
  setContractParkings({ commit }, contractParkings) {
    commit('SET_CONTRACTPARKINGS', contractParkings)
  },

  async setupContractParkings({ commit, getters }) {
    if (
      !getters.getContractParkings ||
      getters.getContractParkings.length === 0
    ) {
      const r = await this.$axios.$get(`/contract-parking`)
      if (!r || !r.data) {
        throw new Error('response format error: error getting contractParking')
      }
      commit('SET_CONTRACT_PARKINGS', r.data)
    }
  },
}
