import { Role } from '@/entities/role.ts'

export const state = () => ({
  role: new Role(),
})

export const getters = {
  getRole: (state) => {
    return state.role
  },
}

export const mutations = {
  SET_ROLE: (state, payload) => {
    state.role = payload
  },
}

export const actions = {
  setRole({ commit }, payload) {
    commit('SET_ROLE', payload)
  },

  async getRoleById({ commit }, id) {
    const r = await this.$axios.$get(`/role/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: role')
    }
    commit('SET_ROLE', r.data)
  },

  async createRole({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROLE', payload)
    }
    const r = await this.$axios.$post(`/role`, getters.getRole)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: role')
    }
    commit('SET_ROLE', r.data)
  },

  async saveRole({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROLE', payload)
    }
    const r = await this.$axios.$put(
      `/role/${getters.getrole.roleId}`,
      getters.getRole
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: role')
    }
    commit('SET_ROLE', r.data)
  },

  async deleteRole({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_ROLE', payload)
    await this.$axios.$delete(`/role/${getters.getrole.roleId}`)
    commit('SET_ROLE', null)
  },
}
