import { OneOfFeeExtras } from '@/entities/oneOfFeeExtras.ts'

export const state = () => ({
  oneOfFeeExtras: new OneOfFeeExtras(),
})

export const getters = {
  getOneOfFeeExtras: (state) => {
    return state.oneOfFeeExtras
  },
}

export const mutations = {
  SET_ONE_OF_FEE_EXTRAS: (state, payload) => {
    state.oneOfFeeExtras = payload
  },
}

export const actions = {
  setOneOfFeeExtras({ commit }, payload) {
    commit('SET_ONE_OF_FEE_EXTRAS', payload)
  },

  async getOneOfFeeExtrasById({ commit }, id) {
    const r = await this.$axios.$get(`/one-of-fee-extras/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: oneOfFeeExtras')
    }
    commit('SET_ONE_OF_FEE_EXTRAS', r.data)
  },

  async createOneOfFeeExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_EXTRAS', payload)
    }
    const r = await this.$axios.$post(
      `/one-of-fee-extras`,
      getters.getOneOfFeeExtras
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: oneOfFeeExtras')
    }
    commit('SET_ONE_OF_FEE_EXTRAS', r.data)
  },

  async saveOneOfFeeExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_EXTRAS', payload)
    }
    const r = await this.$axios.$put(
      `/one-of-fee-extras/${getters.getOneOfFeeExtras.id}`,
      getters.getOneOfFeeExtras
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: oneOfFeeExtras')
    }
    commit('SET_ONE_OF_FEE_EXTRAS', r.data)
  },

  async deleteOneOfFeeExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_EXTRAS', payload)
    }
    await this.$axios.$delete(
      `/one-of-fee-extras/${getters.getOneOfFeeExtras.id}`
    )
    commit('SET_ONE_OF_FEE_EXTRAS', null)
  },
}
