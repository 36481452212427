export class ContractExtras {
  id: number | undefined
  contract: object | null
  extras: object | null
  amount: number

  constructor() {
    this.contract = null
    this.extras = null
    this.amount = 0
  }
}
