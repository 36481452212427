import Vue from 'vue'

const required = [
  (v) => {
    return !!v || 'value is bad'
  },
]
const requireString = [
  ...required,
  (v) => {
    if (!v || typeof v !== 'string') {
      return 'value is false'
    }
    return true
  },
]
const requireStringN = function (n) {
  return [
    ...requireString,
    (v) => {
      if (requireString) {
        return v.length > n
      }
      return 'validation fault'
    },
  ]
}

const requiredSoft = [
  (v) => {
    return v ?? 'Validation fault'
  },
]

const le = (n) => (val) => {
  const v = parseFloat(val)
  return n >= v || `${v} must be less than or equal to ${n}`
}

const requiredLe = (n) => [le(n)]

const ge = (n) => (val) => {
  const v = parseFloat(val)
  return n <= v || `${v} must be greater than or equal to ${n}`
}

const requiredGe = (n) => [ge(n)]

const requiredBetween = (min, max) => [
  ...requiredSoft,
  (val) => {
    return (
      (Number(val) >= Number(min) && Number(val) <= Number(max)) ||
      `Must be between ${min} and ${max}`
    )
  },
]

const requiredNumber = [
  (val) => {
    if (typeof val === 'number') {
      return true
    }
    if (typeof val !== 'string') {
      return 'Not a number'
    }
    return !isNaN(parseInt(val, 10)) || 'Not a number'
  },
]

const emailFormat = [
  ...requireString,
  (val) => {
    const mailRx = /^\S+@\S+\.\S+$/
    return mailRx.test(val) || 'Invalid mail address'
  },
]

Vue.prototype.$_required = required
Vue.prototype.$_requiredString = requireString
Vue.prototype.$_requiredStringN = requireStringN
Vue.prototype.$_requiredSoft = requiredSoft
Vue.prototype.$_requiredLe = requiredLe
Vue.prototype.$_requiredGe = requiredGe
Vue.prototype.$_requiredNumber = requiredNumber
Vue.prototype.$_requiredBetween = requiredBetween
Vue.prototype.$_emailFormat = emailFormat
