import { OneOfFee } from '@/entities/oneOfFee.ts'

export const state = () => ({
  oneOfFee: new OneOfFee(),

  getOneOfFeeExtras: [],
  oneOfFeeDesks: [],
  oneOfFeePasses: [],
  oneOfFeeMeetingHours: [],
})

export const getters = {
  getOneOfFee: (state) => {
    return state.oneOfFee
  },

  getOneOfFeeExtras: (state) => state.getOneOfFeeExtras,
  getOneOfFeeDesks: (state) => state.oneOfFeeDesks,
  getOneOfFeePasses: (state) => state.oneOfFeePasses,
  getOneOfFeeMeetingHours: (state) => state.oneOfFeeMeetingHours,
}

export const mutations = {
  SET_ONE_OF_FEE: (state, payload) => {
    if (!payload) {
      state.oneOfFee = new OneOfFee()
    } else {
      if (typeof payload.startDate === 'string') {
        payload.startDate = payload.startDate.split('T')[0]
      }
      if (typeof payload.endDate === 'string') {
        payload.endDate = payload.endDate.split('T')[0]
      }
      state.oneOfFee = payload
    }
  },

  SET_ONE_OF_FEE_EXTRAS: (state, extrases) => {
    state.getOneOfFeeExtras = extrases
  },
  SET_ONE_OF_FEE_PASSES: (state, passes) => {
    state.oneOfFeePasses = passes
  },
  SET_ONE_OF_FEE_DESKS: (state, desks) => {
    state.oneOfFeeDesks = desks
  },

  SET_ONE_OF_FEE_MEETING_HOURS: (state, meetingHours) => {
    state.oneOfFeeMeetingHours = meetingHours
  },
}

export const actions = {
  setOneOfFee({ commit }, payload) {
    commit('SET_ONE_OF_FEE', payload)
  },

  async setupOneOfFeeById({ commit }, id) {
    const r = await this.$axios.$get(`/one-of-fee/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: oneOfFee')
    }
    commit('SET_ONE_OF_FEE', r.data)
    return r.data
  },

  async setupOneOfFeeExtras({ commit, getters }) {
    const oneOfFee = getters.getOneOfFee
    if (!oneOfFee || !oneOfFee.id || oneOfFee.id <= 0) {
      throw new Error('oneOfFee not set')
    }
    const { data } = await this.$axios.$get(
      `/one-of-fee/${oneOfFee.id}/one-of-fee-extras`
    )
    commit('SET_ONE_OF_FEE_EXTRAS', data)
    return data
  },

  async setupOneOfFeePasses({ commit, getters }) {
    const oneOfFee = getters.getOneOfFee
    if (!oneOfFee || !oneOfFee.id || oneOfFee.id <= 0) {
      throw new Error('one of fee not set')
    }
    const { data } = await this.$axios.$get(
      `/one-of-fee/${oneOfFee.id}/one-of-fee-user-location-pass`
    )
    commit('SET_ONE_OF_FEE_PASSES', data)
    return data
  },

  async setupOneOfFeeDesks({ commit, getters }) {
    const oneOfFee = getters.getOneOfFee
    if (!oneOfFee || !oneOfFee.id || oneOfFee.id <= 0) {
      throw new Error('one of fee not set')
    }
    const { data } = await this.$axios.$get(
      `/one-of-fee/${oneOfFee.id}/one-of-fee-space-period-package-price-office`
    )
    commit('SET_ONE_OF_FEE_DESKS', data)
    return data
  },

  async setupOneOfFeeMeetingHours({ commit, getters }) {
    const oneOfFee = getters.getOneOfFee
    if (!oneOfFee || !oneOfFee.id || oneOfFee.id <= 0) {
      throw new Error('one of fee not set')
    }
    const { data } = await this.$axios.$get(
      `/one-of-fee/${oneOfFee.id}/one-of-fee-space-period-package-price-room`
    )
    commit('SET_ONE_OF_FEE_MEETING_HOURS', data)
    return data
  },

  async createOneOfFee({ commit }, payload) {
    if (!payload) {
      throw new Error('payload must be provided')
    }
    const { data } = await this.$axios.$post(`/one-of-fee`, payload)
    commit('SET_ONE_OF_FEE', data)
    return data
  },

  async createMeetingHoursOnOneOfFee(_, { m, oneOfFeeID }) {
    try {
      await this.$axios.$post(
        `/one-of-fee/${oneOfFeeID}/room-type-period-package-price/${m.roomTypePeriodPackagePrice.id}/one-of-fee-space-period-package-price-room`,
        m
      )
    } catch (error) {
      console.error(error)
    }
  },

  async createDesksOnOneOfFee(_, { d, oneOfFeeID }) {
    if (
      oneOfFeeID <= 0 ||
      !d ||
      !d.spacePeriodPackagePrice ||
      d.spacePeriodPackagePrice.id <= 0
    ) {
      return
    }
    try {
      await this.$axios.$post(
        `/one-of-fee/${oneOfFeeID}/space-period-package-price/${d.spacePeriodPackagePrice.id}/one-of-fee-space-period-package-price-office`,
        d
      )
    } catch (error) {
      console.log(error)
    }
  },

  async createPassesOnOneOfFee(_, { p, oneOfFeeID }) {
    if (oneOfFeeID <= 0 || !p.locationPass || p.locationPass.id <= 0) {
      return
    }
    await this.$axios.$post(
      `/user/${p.user.id}/location-pass/${p.locationPass.id}/one-of-fee/${oneOfFeeID}/one-of-fee-user-location-pass`,
      p
    )
  },

  async createExtrasOnOneOfFee(_, { e, oneOfFeeID }) {
    if (oneOfFeeID <= 0 || !e.extras || e.extras.id <= 0) {
      return
    }
    try {
      await this.$axios.$post(
        `/one-of-fee/${oneOfFeeID}/extras/${e.extras.id}/one-of-fee-extras`,
        e
      )
    } catch (error) {
      console.error(error)
    }
  },

  async changeDepositStatus(_, { depositStatus, oneOfFeeID }) {
    if (!depositStatus || !depositStatus.id || depositStatus.id <= 0) {
      return
    }
    try {
      await this.$axios.$post(
        `/one-of-fee/${oneOfFeeID}/deposit-status/${depositStatus.id}`
      )
    } catch (error) {
      console.error(error)
    }
  },

  // SECTION: update

  async saveOneOfFee({ commit }, payload) {
    if (!payload) {
      throw new Error('error: payload not passed to saveOneOfFee')
    }
    if (payload.oneOfFee.id <= 0) {
      throw new Error('error: payload passed to saveOneOfFee has id <= 0')
    }
    try {
      const res = await this.$axios.$put(
        `/one-of-fee/${payload.oneOfFee.id}`,
        payload
      )
      commit('SET_ONE_OF_FEE', res.data)
      return res
    } catch (error) {
      console.error(error)
      return null
    }
  },

  async updateExtrasOnOneOfFee(_, e) {
    await this.$axios.$put(`/one-of-fee-extras/${e.id}`, e)
  },

  async updatePassesOnOneOfFee(_, e) {
    await this.$axios.$put(`/one-of-fee-user-location-pass/${e.id}`, e)
  },

  async updateMeetingHoursOnOneOfFee(_, m) {
    await this.$axios.$put(
      `/one-of-fee-space-period-package-price-room/${m.id}`,
      m
    )
  },

  async updateDeskOnOneOfFee(_, d) {
    await this.$axios.$put(
      `/one-of-fee-space-period-package-price-office/${d.id}`,
      d
    )
  },

  // SECTION END: update

  // SECTION: delete

  async deleteOneOfFee({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE', payload)
    }
    try {
      await this.$axios.$delete(`/one-of-fee/${getters.getOneOfFee.id}`)
      commit('SET_ONE_OF_FEE', null)
    } catch (error) {
      console.error(error)
    }
  },

  async deleteExtrasOnOneOfFee(_, id) {
    await this.$axios.$delete(`/one-of-fee-extras/${id}`)
  },

  async deletePassesOnOneOfFee(_, id) {
    await this.$axios.$delete(`/user-location-pass/${id}`)
  },

  async deleteMeetingHoursOnOneOfFee(_, id) {
    try {
      await this.$axios.$delete(
        `/one-of-fee-space-period-package-price-room/${id}`
      )
    } catch (error) {
      console.log('error deleting meeting hours:', error)
    }
  },

  async deleteDesksOnOneOfFee(_, id) {
    await this.$axios.$delete(
      `/one-of-fee-space-period-package-price-office/${id}`
    )
  },

  // SECTION END: delete
}
