import { RoomTypePeriodPackage } from '@/entities/roomTypePeriodPackage.ts'

export const state = () => ({
  roomTypePeriodPackage: new RoomTypePeriodPackage(),
})

export const getters = {
  getRoomTypePeriodPackage: (state) => {
    return state.roomTypePeriodPackage
  },
}

export const mutations = {
  SET_ROOM_TYPE_PERIOD_PACKAGE: (state, payload) => {
    state.roomTypePeriodPackage = payload
  },
}

export const actions = {
  setRoomTypePeriodPackage({ commit }, payload) {
    commit('SET_ROOM_TYPE_PERIOD_PACKAGE', payload)
  },

  async getRoomTypePeriodPackageById({ commit }, id) {
    const r = await this.$axios.$get(`/room-type-period-package/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: roomTypePeriodPackage')
    }
    commit('SET_ROOM_TYPE_PERIOD_PACKAGE', r.data)
  },

  async createRoomTypePeriodPackage({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROOM_TYPE_PERIOD_PACKAGE', payload)
    }
    const r = await this.$axios.$post(
      `/room-type-period-package`,
      getters.getRoomTypePeriodPackage
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: roomTypePeriodPackage'
      )
    }
    commit('SET_ROOM_TYPE_PERIOD_PACKAGE', r.data)
  },

  async saveRoomTypePeriodPackage({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROOM_TYPE_PERIOD_PACKAGE', payload)
    }
    const r = await this.$axios.$put(
      `/room-type-period-package/${getters.getRoomTypePeriodPackage.id}`,
      getters.getRoomTypePeriodPackage
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: roomTypePeriodPackage'
      )
    }
    commit('SET_ROOM_TYPE_PERIOD_PACKAGE', r.data)
  },

  async deleteRoomTypePeriodPackage({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROOM_TYPE_PERIOD_PACKAGE', payload)
    }
    await this.$axios.$delete(
      `/room-type-period-package/${getters.getRoomTypePeriodPackage.id}`
    )
    commit('SET_ROOM_TYPE_PERIOD_PACKAGE', null)
  },
}
