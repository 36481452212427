export const state = () => ({
  paymentStatuses: [
    {
      id: 0,
      name: 'NOT PAID',
    },
    {
      id: 1,
      name: 'PARTIALLY PAID',
    },
    {
      id: 2,
      name: 'PAID',
    },
  ],
})

export const getters = {
  getPaymentStatuses: (state) => {
    return state.paymentStatuses
  },
}

export const mutations = {
  SET_PAYMENT_STATUSES: (state, paymentStatuses) => {
    state.paymentStatusS = paymentStatuses
  },
}

export const actions = {
  setPaymentStatuses({ commit }, paymentStatuses) {
    commit('SET_PAYMENT_STATUSES', paymentStatuses)
  },
}
