export const state = () => ({
  currency: {
    name: '',
  },
})

export const getters = {
  getCurrency: (state) => {
    return state.currency
  },
}

export const mutations = {
  SET_CURRENCY: (state, payload) => {
    state.currency = payload
  },
}

export const actions = {
  setCurrency({ commit }, payload) {
    commit('SET_CURRENCY', payload)
  },

  async getCurrencyById({ commit }, id) {
    const r = await this.$axios.$get(`/currency/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: currency')
    }
    commit('SET_CURRENCY', r.data)
  },

  async createCurrency({ commit }, payload) {
    if (!payload) {
      throw new Error('payload not sent')
    }
    const r = await this.$axios.$post(`/currency`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: currency')
    }
    commit('SET_CURRENCY', r.data)
    return r.data
  },

  async saveCurrency({ commit }, payload) {
    if (!payload) {
      throw new Error('can not save currency without payload')
    }
    const r = await this.$axios.$put(`/currency/${payload.id}`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on saving: currency')
    }
    commit('SET_CURRENCY', r.data)
    return r.data
  },

  async deleteCurrency({ commit }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    await this.$axios.$delete(`/currency/${payload.id}`)
    commit('SET_CURRENCY', null)
  },
}
