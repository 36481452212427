import { RoomAddition } from './roomAddition'
export class Space {
  id: number | undefined
  name: string
  spaceType: object | null
  roomCode: string
  floor: object | null
  size: object | null
  capacity: number
  rentableBySingleDesk: boolean
  spacePeriodPricingList: object | null
  roomAdditionSet?: RoomAddition[] | null

  constructor() {
    this.name = ''
    this.spaceType = null
    this.roomCode = ''
    this.floor = null
    this.size = null
    this.capacity = 0
    this.rentableBySingleDesk = false
    this.spacePeriodPricingList = []
  }
}

// Space type
export const SpaceOffice = 1
