import Vue from 'vue'

export const state = () => ({
  spaceTypePeriodPackages: [],
  spaceTypePeriodPackagesBySpaceType: {},
})

export const getters = {
  getSpaceTypePeriodPackages: (state) => {
    return state.spaceTypePeriodPackages
  },
  getSpaceTypePeriodPackagesBySpaceType: (state) => (spaceTypeID) => {
    if (state.spaceTypePeriodPackagesBySpaceType) {
      return state.spaceTypePeriodPackagesBySpaceType[spaceTypeID] || []
    }
  },
}

export const mutations = {
  SET_SPACE_TYPE_PERIOD_PACKAGES_BY_SPACE_TYPE: (
    state,
    { spaceTypePeriodPackages, spaceTypeID }
  ) => {
    if (!state.spaceTypePeriodPackagesBySpaceType) {
      state.spaceTypePeriodPackages = {}
    }
    Vue.set(
      state.spaceTypePeriodPackagesBySpaceType,
      spaceTypeID,
      spaceTypePeriodPackages
    )
  },
}

export const actions = {
  setSpaceTypePeriodPackages({ commit }, spaceTypePeriodPackages) {
    commit('SET_SPACE_TYPE_PERIOD_PACKAGES', spaceTypePeriodPackages)
  },

  async setupSpaceTypePeriodPackagesBySpaceTypeID({ commit }, spaceTypeID) {
    const { data } = await this.$axios.$get(
      `/space-type/${spaceTypeID}/space-type-period-package`
    )
    commit('SET_SPACE_TYPE_PERIOD_PACKAGES_BY_SPACE_TYPE', {
      spaceTypePeriodPackages: data,
      spaceTypeID,
    })
  },
}
