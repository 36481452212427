import { Currency } from '@/entities/currency'

export const state = () => ({
  currencies: [],
  baseCurrency: new Currency(),
})

export const getters = {
  getCurrencies: (state) => {
    return state.currencies
  },

  getBaseCurrency: (state) => state.baseCurrency,
}

export const mutations = {
  SET_CURRENCIES: (state, currencies) => {
    state.currencies = currencies
  },

  SET_BASE_CURRENCY: (state, currency) => {
    state.baseCurrency = currency
  },
}

export const actions = {
  async setupCurrencies({ commit }) {
    const { data } = await this.$axios.$get(`/currency`)
    commit(
      'SET_BASE_CURRENCY',
      data.find((e) => isBaseCurrency(e))
    )
    commit('SET_CURRENCIES', (this.currencies = data))
  },
  async setupAllCurrencies({ commit }) {
    const { data } = await this.$axios.$get(`/currency`)
    commit(
      'SET_BASE_CURRENCY',
      data.find((e) => isBaseCurrency(e))
    )
    commit('SET_CURRENCIES', (this.currencies = data))
  },
}

function isBaseCurrency(f) {
  return f.id === 1
}
