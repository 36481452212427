export class SpacePeriod {
  id: number | undefined
  fundamentalSpaceType: object | null
  // removed 25.7 after model changed
  //   baseTimeUnit: object | null
  amount: number
  name: string
  spacePeriodPricingList: object | null

  constructor() {
    this.fundamentalSpaceType = null
    // this.baseTimeUnit = null
    this.amount = 0
    this.name = ''
    this.spacePeriodPricingList = []
  }
}
