import { RoomPeriodPrice } from '@/entities/roomPeriodPrice.ts'

export const state = () => ({
  roomPeriodPrice: new RoomPeriodPrice(),
})

export const getters = {
  getRoomPeriodPrice: (state) => {
    return state.roomPeriodPrice
  },
}

export const mutations = {
  SET_ROOM_PERIOD_PRICE: (state, payload) => {
    state.roomPeriodPrice = payload
  },
}

export const actions = {
  setRoomPeriodPrice({ commit }, payload) {
    commit('SET_ROOM_PERIOD_PRICE', payload)
  },

  async getRoomPeriodPriceById({ commit }, id) {
    const r = await this.$axios.$get(`/room-period-price/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: roomPeriodPrice')
    }
    commit('SET_ROOM_PERIOD_PRICE', r.data)
  },

  async createRoomPeriodPrice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROOM_PERIOD_PRICE', payload)
    }
    const r = await this.$axios.$post(
      `/room-period-price`,
      getters.getRoomPeriodPrice
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: roomPeriodPrice')
    }
    commit('SET_ROOM_PERIOD_PRICE', r.data)
  },

  async saveRoomPeriodPrice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROOM_PERIOD_PRICE', payload)
    }
    const r = await this.$axios.$put(
      `/room-period-price/${getters.getRoomPeriodPrice.id}`,
      getters.getRoomPeriodPrice
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: roomPeriodPrice')
    }
    commit('SET_ROOM_PERIOD_PRICE', r.data)
  },

  async deleteRoomPeriodPrice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ROOM_PERIOD_PRICE', payload)
    }
    await this.$axios.$delete(
      `/room-period-price/${getters.getRoomPeriodPrice.id}`
    )
    commit('SET_ROOM_PERIOD_PRICE', null)
  },
}
