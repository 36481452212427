import { InvoiceStatus } from '@/entities/invoiceStatus.ts'

export const state = () => ({
  invoiceStatus: new InvoiceStatus(),
})

export const getters = {
  getInvoiceStatus: (state) => {
    return state.invoiceStatus
  },
}

export const mutations = {
  SET_INVOICE_STATUS: (state, payload) => {
    state.invoiceStatus = payload
  },
}

export const actions = {
  setInvoiceStatus({ commit }, payload) {
    commit('SET_INVOICE_STATUS', payload)
  },

  async getInvoiceStatusById({ commit }, id) {
    const r = await this.$axios.$get(`/invoice-status/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: invoiceStatus')
    }
    commit('SET_INVOICE_STATUS', r.data)
  },

  async createInvoiceStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_INVOICE_STATUS', payload)
    }
    const r = await this.$axios.$post(
      `/invoice-status`,
      getters.getInvoiceStatus
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: invoiceStatus')
    }
    commit('SET_INVOICE_STATUS', r.data)
  },

  async saveInvoiceStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_INVOICE_STATUS', payload)
    }
    const r = await this.$axios.$put(
      `/invoice-status/${getters.getInvoiceStatus.id}`,
      getters.getInvoiceStatus
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: invoiceStatus')
    }
    commit('SET_INVOICE_STATUS', r.data)
  },

  async deleteInvoiceStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_INVOICE_STATUS', payload)
    }
    await this.$axios.$delete(`/invoice-status/${getters.getInvoiceStatus.id}`)
    commit('SET_INVOICE_STATUS', null)
  },
}
