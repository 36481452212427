export class ParkingType {
    id: number | undefined
	name: string 
	parkingSpotList: object | null
	parkingPricing: object | null

	constructor() {
		this.name = ""
		this.parkingSpotList = []
		this.parkingPricing = null
	}
}
