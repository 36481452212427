import { SpaceType } from '@/entities/spaceType.ts'

export const state = () => ({
  spaceType: new SpaceType(),
})

export const getters = {
  getSpaceType: (state) => {
    return state.spaceType
  },
}

export const mutations = {
  SET_SPACE_TYPE: (state, payload) => {
    state.spaceType = payload
  },
}

export const actions = {
  setSpaceType({ commit }, payload) {
    commit('SET_SPACE_TYPE', payload)
  },

  async getSpaceTypeById({ commit }, id) {
    const r = await this.$axios.$get(`/space-type/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: spaceType')
    }
    commit('SET_SPACE_TYPE', r.data)
  },

  async createSpaceType({ commit, getters }, payload) {
    if (
      !payload ||
      !payload.fundamentalSpaceType ||
      !payload.fundamentalSpaceType.id
    ) {
      throw new Error('Fundamental space type is required')
    }
    commit('SET_SPACE_TYPE', payload)
    const r = await this.$axios.$post(
      `/fundamental-space-type/${payload.fundamentalSpaceType.id}/space-type`,
      getters.getSpaceType
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: spaceType')
    }
    commit('SET_SPACE_TYPE', r.data)
  },

  async saveSpaceType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_SPACE_TYPE', payload)
    }
    const r = await this.$axios.$put(
      `/space-type/${getters.getSpaceType.id}`,
      getters.getSpaceType
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: spaceType')
    }
    commit('SET_SPACE_TYPE', r.data)
  },

  async deleteSpaceType({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_SPACE_TYPE', payload)
    await this.$axios.$delete(`/space-type/${getters.getSpaceType.id}`)
    commit('SET_SPACE_TYPE', null)
  },
}
