export class TaxRate {
    id: number | undefined
	name: string 
	amount: object | null

	constructor() {
		this.name = ""
		this.amount = null
	}
}
