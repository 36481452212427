import Vue from 'vue'

export const state = () => ({
  userPostComments: [],
  postComments: {},
})

export const getters = {
  getUserPostComments: (state) => {
    return state.userPostComments
  },

  getCommentsByPostID: (state) => (postID) => state.postComments[postID],
}

export const mutations = {
  SET_USER_POST_COMMENTS: (state, userPostComments) => {
    state.userPostCommentS = userPostComments
  },
  SET_COMMENTS_BY_POST_ID(state, { comments, postID }) {
    if (!state.postComments) {
      state.postComments = Vue.set(state, 'postComments', {})
    }
    Vue.set(state.postComments, postID, comments)
  },
}

export const actions = {
  setUserPostComments({ commit }, userPostComments) {
    commit('SET_USER_POST_COMMENTS', userPostComments)
  },

  async setupUserPostComments({ commit, getters }) {
    if (
      !getters.getUserPostComments ||
      getters.getUserPostComments.length === 0
    ) {
      const r = await this.$axios.$get(`/user-post-comment`)
      if (!r || !r.data) {
        throw new Error('response format error: error getting userPostComment')
      }
      commit('SET_USER_POST_COMMENTS', r.data)
    }
  },

  async setupUserPostCommentsByPostID({ commit }, postID) {
    if (!postID || postID < 1) {
      throw new Error('postID must be >= 1')
    }
    const { data } = await this.$axios.$get(
      `/user-post/${postID}/user-post-comment`
    )
    if (!Array.isArray(data)) {
      throw new TypeError('error data returned as comments is not array')
    }
    commit('SET_COMMENTS_BY_POST_ID', {
      comments: data,
      postID,
    })
    return data
  },
}
