export class UserLocationPassEntry {
  id: number | undefined
  timestamp: Date | string
  userLocationPass: object | null

  constructor() {
    this.timestamp = ''
    this.userLocationPass = null
  }
}
