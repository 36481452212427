import BaseIcon from '@/components/Base/BaseIcon.vue'

const iconList: string = `facebook-toolbar.svg
check_circle_outline.svg
photo.svg
trapezoid.svg
dancesteps.svg
two-fingers-toolbar.svg
fish.svg
movie-2.svg
phone-toolbar.svg
cow-toolbar.svg
beverage.svg
rotate-toolbar.svg
ekg.svg
shrimp.svg
bolt-toolbar.svg
line-chart-toolbar.svg
diaper-pin.svg
road-sign-toolbar.svg
pants-toolbar.svg
foursquare-toolbar.svg
mailbox.svg
necklace-2-toolbar.svg
alarm-clock.svg
snowflake.svg
github-toolbar.svg
file-type-film-toolbar.svg
camera.svg
mushroom.svg
dumbells.svg
shape-blend.svg
music-playlist-toolbar.svg
disco-ball.svg
television.svg
phone.svg
welding-mask.svg
sound-1-toolbar.svg
group-error-toolbar.svg
hex-nut.svg
sun-2-toolbar.svg
jacket-toolbar.svg
bulleted-list-toolbar.svg
bankers-box.svg
rocket.svg
weather-tornado.svg
shovel.svg
bus.svg
quill.svg
city.svg
bounce.svg
amazon.svg
dice-4.svg
tshirt.svg
folder.svg
satellite-toolbar.svg
stop-hand-1-toolbar.svg
eye.svg
brightness.svg
card-clubs.svg
dollar-currency.svg
messenger-bag.svg
paper-airplane.svg
birdhouse.svg
spotify-toolbar.svg
package-toolbar.svg
skull-toolbar.svg
scissors-toolbar.svg
mouse-cursor-toolbar.svg
fuzzy-dice.svg
stickynote.svg
barbell.svg
dice-6.svg
paperclip.svg
telescope.svg
magic-wand-toolbar.svg
tooth.svg
washing-machine-1.svg
sound-1.svg
coverflow.svg
question-toolbar.svg
sinawebo-toolbar.svg
house.svg
emoticon-angry.svg
audioscrobbler-toolbar.svg
sock-toolbar.svg
document-2-toolbar.svg
route.svg
measuring-cup.svg
hand-saw.svg
baseball.svg
shoebox.svg
fast-forward-toolbar.svg
stopwatch.svg
3up.svg
drawer.svg
emoticon-sad.svg
stack-2-toolbar.svg
bath.svg
map-marker.svg
photo-2-toolbar.svg
resize.svg
coffee-mug.svg
bing.svg
upload-photo-2.svg
zoom-in.svg
steak.svg
boolean-exclude.svg
golf-toolbar.svg
slideshow.svg
movie-1.svg
umbrella.svg
sign.svg
gift.svg
football-goalposts-toolbar.svg
printer.svg
horizontal-swipe-toolbar.svg
screwdriver.svg
male-toolbar.svg
building.svg
shape-duplicate.svg
basketball.svg
cupcake.svg
combination-lock-toolbar.svg
weather-cloudy-lightning.svg
file-type-text-file-toolbar.svg
flick-left-toolbar.svg
rotate.svg
pin.svg
dollars2.svg
document.svg
cloud-2-toolbar.svg
dropbox-toolbar.svg
dartboard.svg
sawhorse.svg
ipod.svg
sliders.svg
steam-toolbar.svg
photos-toolbar.svg
whale.svg
outlet.svg
warning-toolbar.svg
plus-toolbar.svg
photo-1-toolbar.svg
trumpet.svg
balloon.svg
playing-card-hearts.svg
car-toolbar.svg
weather-snowflake.svg
attachment.svg
key.svg
flags.svg
heart-toolbar.svg
screw.svg
citrus.svg
rolling-pin.svg
bowling-ball.svg
document-plus-toolbar.svg
file-type-bar-chart-toolbar.svg
popcorn.svg
paper-clip-toolbar.svg
reply.svg
arrow-up-toolbar.svg
cards.svg
group.svg
airplane.svg
trumpet-toolbar.svg
lure.svg
two-finger-tap-toolbar.svg
spread-toolbar.svg
clock-2-toolbar.svg
ketchup.svg
file-type-image-toolbar.svg
strawberry.svg
espresso-machine.svg
sun.svg
arrow-right-toolbar.svg
fountain-pen.svg
pie.svg
soup.svg
emoticon-suprise.svg
ladder.svg
tennis-net-toolbar.svg
box.svg
facebook.svg
document-1-toolbar.svg
list.svg
firework.svg
inbox.svg
controller.svg
align-center-toolbar.svg
guide.svg
scale-toolbar.svg
bandaid.svg
brick-wall.svg
folder-checked-toolbar.svg
magnet.svg
albums-toolbar.svg
key-toolbar.svg
retweet.svg
weather.svg
dog-paw.svg
football-jersey-toolbar.svg
code-fork.svg
reply-toolbar.svg
mansion.svg
fuel.svg
compose.svg
skirt-2-toolbar.svg
wordpress-toolbar.svg
shield-toolbar.svg
bubble-chart.svg
flipcam.svg
router.svg
dashboard.svg
satellite.svg
squiggle.svg
whisk.svg
line-chart.svg
money-2-toolbar.svg
orange.svg
gear2.svg
macbook.svg
display.svg
skull.svg
purse.svg
airplay-toolbar.svg
pill.svg
notebook.svg
windmill.svg
gauge-toolbar.svg
hockey-stick-and-puck-toolbar.svg
database.svg
fence-toolbar.svg
action2.svg
flowerpot.svg
hotdog.svg
pot.svg
one-finger-tap-toolbar.svg
location-pin-toolbar.svg
mobile-phone-toolbar.svg
emoticon-shades.svg
tape-measure.svg
api1.svg
passport.svg
twitter.svg
chat-delete-toolbar.svg
chat-toolbar.svg
decagon.svg
gear-2.svg
shuffle-toolbar.svg
persondot.svg
infinity.svg
gba.svg
deviantart-toolbar.svg
sock.svg
dice-3.svg
sawblade.svg
shinto-torii-arch-toolbar.svg
broccoli.svg
recordplayer.svg
rewind-toolbar.svg
document-checked-toolbar.svg
stack.svg
pipes.svg
person.svg
wrench.svg
album-open.svg
toolbox.svg
googleplus-toolbar.svg
zap.svg
radar2.svg
cabinet.svg
filing-cabinet-toolbar.svg
golf.svg
microphone-2.svg
bomb2.svg
bolt.svg
party-horn.svg
oval.svg
glasses-toolbar.svg
stamp-toolbar.svg
boolean-intersect.svg
speaker.svg
helicopter.svg
group-minus-toolbar.svg
diamond.svg
location.svg
chat-plus-toolbar.svg
pencil-toolbar.svg
director-chair.svg
notepad-toolbar.svg
mixing-bowl.svg
star-toolbar.svg
choose-font-toolbar.svg
candy-cane.svg
islamic-moon-and-star-toolbar.svg
dice.svg
cat-toolbar.svg
chat.svg
plus-circle-toolbar.svg
bowling-pins-toolbar.svg
map-toolbar.svg
evernote.svg
hiking.svg
bell-toolbar.svg
paper-airplane-toolbar.svg
radar.svg
browser.svg
yahoo-toolbar.svg
zoomin.svg
christian-cross-toolbar.svg
piechart.svg
bread.svg
apple.svg
microwave.svg
fancy-toolbar.svg
film-strip.svg
rss-toolbar.svg
honey.svg
hindu-om-symbol-toolbar.svg
spiderweb.svg
dress-shirt-toolbar.svg
emoticon-eyeroll.svg
milkshake.svg
large-house-toolbar.svg
building-toolbar.svg
three-finger-tap-toolbar.svg
birthday-cake.svg
ships-wheel.svg
api2.svg
suitcase.svg
service-bell.svg
emoticon-grin.svg
location-arrow-toolbar.svg
handtruck-toolbar.svg
file-type-table-toolbar.svg
tshirt-toolbar.svg
card-spades.svg
alien-ship.svg
bowtie-toolbar.svg
sun-cloud-toolbar.svg
imac.svg
file-type-text-document-toolbar.svg
camper.svg
teapot.svg
nail.svg
shopping.svg
snow-globe.svg
signpost-toolbar.svg
archive-box.svg
documents-toolbar.svg
bell.svg
hand-drill.svg
network.svg
avocado.svg
note-2.svg
bandaid-toolbar.svg
boxing-glove.svg
video-film-strip-toolbar.svg
bank-toolbar.svg
download-toolbar.svg
radiation.svg
knife-and-fork.svg
cake.svg
wastebasket.svg
hard-hat-and-goggles.svg
t-square.svg
salad.svg
lightning-bolt-toolbar.svg
photos.svg
sound-3.svg
envelope.svg
adjustments.svg
rubber-stamp.svg
flower-pot-toolbar.svg
compose-toolbar.svg
gmail-toolbar.svg
landscape.svg
bar-chart-horizontal.svg
fingers-spread-left-toolbar.svg
newspaper.svg
ice-cream-cone-toolbar.svg
brick-trowel.svg
sound-off.svg
location-arrow.svg
badminton-birdie-toolbar.svg
butcher-knife.svg
dress-toolbar.svg
pinterest-toolbar.svg
bank.svg
headphone-toolbar.svg
dart.svg
salt.svg
recycle-toolbar.svg
bing-toolbar.svg
fork-and-spoon.svg
android-toolbar.svg
dribbble-toolbar.svg
iphone-5.svg
podium-toolbar.svg
replyall.svg
level.svg
playing-card-clubs.svg
ruler.svg
puzzle-piece.svg
sushami.svg
basketball-court-toolbar.svg
gameplan.svg
envelope-toolbar.svg
safety-pin-toolbar.svg
signpost.svg
binoculars-toolbar.svg
sledgehammer.svg
subscription.svg
google-toolbar.svg
sort-ascending-toolbar.svg
burrito.svg
compass-toolbar.svg
snowman.svg
sikh-symbol-toolbar.svg
area-chart.svg
beer-mug.svg
code-merge2.svg
weather-cloudy-snow.svg
tractor.svg
car.svg
renren-toolbar.svg
paint-bucket.svg
trailer-toolbar.svg
slayer-hand-toolbar.svg
shipping-box.svg
tape-dispenser.svg
document-error-toolbar.svg
plant.svg
graduation-cap-toolbar.svg
checkmark3.svg
chocolate-bar.svg
ipod-classic.svg
gloves-toolbar.svg
stumbleupon-toolbar.svg
pine-tree.svg
bahá_í-star-toolbar.svg
basket-plus-toolbar.svg
paste-clipboard.svg
newspaper-toolbar.svg
folder-delete-toolbar.svg
scooter.svg
inbox-toolbar.svg
moon.svg
user-minus-toolbar.svg
star.svg
clock-toolbar.svg
liquor-bottle.svg
orgchart.svg
index-cards.svg
tag.svg
2up.svg
moon-toolbar.svg
grapes.svg
pinch-toolbar.svg
baseball-diamond.svg
runner.svg
corn.svg
backintime.svg
binoculars.svg
picture-frame.svg
link.svg
headphones.svg
sunrise.svg
boy.svg
crossiant.svg
cyclist.svg
beaker-2.svg
emoticon-tongue.svg
guitar.svg
skype-toolbar.svg
linkedin-toolbar.svg
gear.svg
chat2.svg
hexagon.svg
iphone-4.svg
rhombus.svg
crib.svg
barrier.svg
heat-map.svg
fingers-spread-right-toolbar.svg
baseball-and-bat-toolbar.svg
box2.svg
file-type-vector-toolbar.svg
barcode.svg
clock.svg
briefcase.svg
bicycle.svg
happyface.svg
sound-2.svg
book.svg
voicemail.svg
delete.svg
sailboat.svg
megaphone-toolbar.svg
alarm-clock-toolbar.svg
file-type-secure-toolbar.svg
heart.svg
wrench-toolbar.svg
numbered-list-toolbar.svg
flag-1.svg
shuffle.svg
christmas-stocking.svg
pentagon.svg
snow-cloud-toolbar.svg
sound-none.svg
gift-toolbar.svg
coupon.svg
bus-toolbar.svg
snake-toolbar.svg
money-1-toolbar.svg
hammer.svg
doghouse.svg
iphone.svg
basecamp-toolbar.svg
scatter-plot.svg
gear-toolbar.svg
desk-lamp.svg
zoom-in-toolbar.svg
soup-ladle.svg
eye-toolbar.svg
badge.svg
fan.svg
500px-toolbar.svg
equalizer.svg
forrst-toolbar.svg
dollars1.svg
magnify.svg
mustard.svg
sandwich.svg
tv-toolbar.svg
cleaver.svg
ballpoint-pen.svg
clipboard-toolbar.svg
backintime2.svg
hiking-toolbar.svg
user-delete-toolbar.svg
flag-toolbar.svg
birthday-cake-toolbar.svg
gannt-chart.svg
flow-chart.svg
bar-chart-descending.svg
pizza.svg
refresh-3-toolbar.svg
chilli-pepper.svg
box-toolbar.svg
atom-toolbar.svg
cursor.svg
worm-toolbar.svg
rubberstamp.svg
line-chart-descending.svg
cloud.svg
scales.svg
enter.svg
triangle.svg
thumbtack.svg
tulip.svg
tea-bag.svg
spatuala.svg
collection.svg
shopping-bag.svg
shopping-bag-toolbar.svg
train.svg
peanut.svg
printer-toolbar.svg
locked-toolbar.svg
bold.svg
hard-hat.svg
glasses-1.svg
circle.svg
id-card.svg
home-toolbar.svg
sword.svg
bathtub-toolbar.svg
mountains-toolbar.svg
piano-1.svg
filter.svg
video-camera-toolbar.svg
file-type-audio-toolbar.svg
sunset.svg
train-toolbar.svg
trinity-knot-toolbar.svg
emoticon-wink.svg
basket-minus-toolbar.svg
basketball-net-toolbar.svg
shape-scale-up.svg
toast.svg
file-type-video-toolbar.svg
album-closed.svg
blogger-toolbar.svg
chef-hat.svg
factory.svg
chat-minus-toolbar.svg
crane.svg
soda.svg
globe.svg
chart.svg
code-sync.svg
paperairplane.svg
ramen.svg
chair.svg
tic-tac-toe.svg
bone.svg
refresh.svg
wrench2.svg
card-hearts.svg
sound-2-toolbar.svg
tower.svg
calculator.svg
file-type-code-toolbar.svg
ipad.svg
note.svg
camera-toolbar.svg
ticket.svg
vimeo-toolbar.svg
yin-yang.svg
filter-toolbar.svg
saucepan.svg
projector.svg
castle.svg
poison.svg
contrast.svg
target.svg
link-toolbar.svg
tophat-toolbar.svg
baseball-glove-toolbar.svg
french-fries.svg
card-diamonds.svg
youtube-toolbar.svg
medical-bag.svg
football-play.svg
quora-toolbar.svg
stopwatch-1-toolbar.svg
notepad.svg
glasses-2.svg
necktie.svg
fork-and-knife.svg
skip-ahead-toolbar.svg
line-chart-ascending.svg
filing-cabinet.svg
taco.svg
pin-toolbar.svg
audioscrobbler.svg
parcel-1.svg
picket-fence.svg
anchor.svg
crown.svg
underline-toolbar.svg
code-fork2.svg
instagram-toolbar.svg
folder-error-toolbar.svg
sun-1-toolbar.svg
unhappyface.svg
command-symbol.svg
flipboard-toolbar.svg
eyedropper.svg
eject-toolbar.svg
playing-card-diamonds.svg
loopback.svg
file-type-pencil-toolbar.svg
sweater-toolbar.svg
dice-2.svg
candlestick-chart.svg
arrow-down-toolbar.svg
apron.svg
more-toolbar.svg
sound-muted-toolbar.svg
compass.svg
record-toolbar.svg
flag.svg
file-type-pie-chart-toolbar.svg
pause-toolbar.svg
champagne-ice-bucket.svg
dropbox.svg
group-checked-toolbar.svg
rain-cloud-toolbar.svg
checkmark.svg
at.svg
suit-toolbar.svg
presentation.svg
refresh-2-toolbar.svg
vertical-swipe-toolbar.svg
apple-toolbar.svg
tea-mug.svg
alien.svg
users-toolbar.svg
sound-muted.svg
tumblr-toolbar.svg
bold-toolbar.svg
pig-toolbar.svg
idcard.svg
user-checked-toolbar.svg
blender.svg
shoes-toolbar.svg
map.svg
lighting.svg
clapboard.svg
film-roll.svg
santa-sleigh.svg
walk.svg
bowtie.svg
location-target-toolbar.svg
amazon-toolbar.svg
pointing-finger-toolbar.svg
banana.svg
underwear-toolbar.svg
shorts-toolbar.svg
rolodex.svg
slowmo-toolbar.svg
voicemail-2.svg
document-delete-toolbar.svg
macroflower.svg
swords-toolbar.svg
church.svg
music-playlist.svg
todo.svg
reddit-toolbar.svg
user-group-toolbar.svg
file-type-tabular-data-toolbar.svg
stop-toolbar.svg
beaker.svg
evernote-toolbar.svg
folder-plus-toolbar.svg
cocktail.svg
taxi.svg
file-type-zip-toolbar.svg
paint-brush.svg
weather-cloudy-rain.svg
storm-cloud-toolbar.svg
fist-toolbar.svg
psp.svg
horse-toolbar.svg
tv.svg
group-delete-toolbar.svg
beaker-toolbar.svg
align-justify-toolbar.svg
shape-change.svg
radar-toolbar.svg
washing-machine-toolbar.svg
checkmark1.svg
dramamasks.svg
itunes-toolbar.svg
share-toolbar.svg
stamp.svg
cheese-grater.svg
navigation.svg
pancakes.svg
wine-bottle.svg
widescreen.svg
dog-toolbar.svg
coversation.svg
spraycan.svg
shape-sort.svg
airplane-toolbar.svg
confetti.svg
floppy-disk.svg
windows.svg
girl.svg
screen.svg
calendar-toolbar.svg
ghost.svg
bagel.svg
chinese-takeaway.svg
screw-toolbar.svg
tree-ornament.svg
exposure.svg
crowbar.svg
airplay.svg
stand-mixer.svg
wiimote.svg
weather-windy.svg
credit-card.svg
redo.svg
wristwatch.svg
align-right-toolbar.svg
sushi.svg
speechbubble.svg
trash-toolbar.svg
tools-1.svg
scarf-toolbar.svg
skip-back-toolbar.svg
bookmark.svg
browser-windows-toolbar.svg
door-open.svg
chat-checked-toolbar.svg
refresh-1-toolbar.svg
easter-bunny.svg
emoticon-happy.svg
lifebuoy.svg
browser-toolbar.svg
door-toolbar.svg
viking.svg
vampire.svg
salt-and-pepper.svg
mailbox-toolbar.svg
dice-1.svg
christmas-tree.svg
t-shirt-toolbar.svg
user.svg
milk-carton.svg
test-tube.svg
italic-toolbar.svg
ships-wheel-toolbar.svg
cloud-upload-toolbar.svg
wrench-2.svg
scoreboard-toolbar.svg
dog-house-toolbar.svg
basket-checked-toolbar.svg
bar-chart-toolbar.svg
triangle-ruler.svg
hand.svg
whistle-toolbar.svg
hockeymask.svg
easter-egg.svg
cloud-toolbar.svg
paper-clip.svg
dumbphone.svg
pepper.svg
shield.svg
rocket-toolbar.svg
bookmarks-toolbar.svg
stapler.svg
download-box-toolbar.svg
paint-roller.svg
warning.svg
bomb.svg
bird-house-toolbar.svg
bubble-level.svg
tennis-racquet-toolbar.svg
cloud-download-toolbar.svg
sharpness.svg
bow.svg
oven.svg
walkie-talkie.svg
checkmark2.svg
nescontroller.svg
sort-descending-toolbar.svg
stopwatch-2-toolbar.svg
ruler-and-pencil.svg
bbq-grill.svg
gear4.svg
grab-hand-1-toolbar.svg
document-2.svg
drawing-compass.svg
bullhorn.svg
beverage-toolbar.svg
coat-hanger.svg
arrow-left-toolbar.svg
mug-toolbar.svg
fried-egg.svg
credit-card-toolbar.svg
group-plus-toolbar.svg
cherries.svg
end-of-calendar.svg
location-toolbar.svg
basket-delete-toolbar.svg
appstore-toolbar.svg
circlex.svg
rdio-toolbar.svg
headset.svg
tortilla-chips.svg
tennis-observation-seat-toolbar.svg
windows-toolbar.svg
bow-and-arrow.svg
copy-documents.svg
user-error-toolbar.svg
pricetag.svg
file-type-sound-recording-toolbar.svg
zoom-out-toolbar.svg
receipt.svg
pencil.svg
wallet.svg
venn-diagram.svg
flickr-toolbar.svg
map-location-toolbar.svg
videocamera.svg
weather-partly-cloudy.svg
serving-tray.svg
saftey-cone.svg
file-type-music-toolbar.svg
martini.svg
costume-toolbar.svg
bowling-pin-and-ball-toolbar.svg
chart-toolbar.svg
calculator-toolbar.svg
playing-card-spades.svg
baby-bottle.svg
code-merge.svg
octagon.svg
mac-mini.svg
planet.svg
bacon.svg
flower.svg
trash.svg
buddhist-wheel-toolbar.svg
boolean-union.svg
judaic-star-toolbar.svg
gradhat.svg
rectangle.svg
diamond-toolbar.svg
shoppingbag.svg
music-note-toolbar.svg
broadcast.svg
egg.svg
4up.svg
atom.svg
chat-bubbles-toolbar.svg
sodacan.svg
stack-1-toolbar.svg
frying-pan.svg
cassette-tape.svg
football-helmet-toolbar.svg
mac-pro.svg
bird.svg
checkmark-toolbar.svg
cheese.svg
file-type-bookmark-toolbar.svg
coffee.svg
muffin.svg
tags.svg
dice-toolbar.svg
lamp.svg
midnight-clock.svg
globe-toolbar.svg
exit.svg
upload.svg
medical.svg
necklace-1-toolbar.svg
parcel-toolbar.svg
oven-range.svg
telescope-toolbar.svg
peace-sign-toolbar.svg
flick-right-toolbar.svg
chicken-leg.svg
saftey-vest.svg
1up.svg
folder-minus-toolbar.svg
weather-raindrops.svg
combination-lock.svg
diamond-ring.svg
dictionary.svg
count.svg
play-toolbar.svg
thumbs-up-toolbar.svg
lock.svg
dice-5.svg
download.svg
bed.svg
finger-drag-toolbar.svg
smile-toolbar.svg
toasting.svg
football-field-toolbar.svg
org-chart.svg
pie-chart.svg
folder-toolbar.svg
trash2.svg
search-toolbar.svg
shopping-cart.svg
microphone.svg
gear3.svg
crop.svg
floppy-disk-toolbar.svg
bar-chart.svg
skull-n-bones.svg
hamburger.svg
document-minus-toolbar.svg
butterfly.svg
battery.svg
icloud-toolbar.svg
facetime.svg
taoism-yin-yang-toolbar.svg
box3.svg
ufo.svg
info-toolbar.svg
carrot.svg
macbook-air.svg
slingshot-toolbar.svg
thermometer.svg
code-tree.svg
photobook.svg
upload-photo.svg
shape-separate.svg
archive-toolbar.svg
door-closed.svg
file-type-html-toolbar.svg
sound-3-toolbar.svg
trophy.svg
female-toolbar.svg
mind-map.svg
roast-turkey.svg
timer-1-toolbar.svg
service-bell-toolbar.svg
purse-toolbar.svg
ruler-toolbar.svg
meetup-toolbar.svg
dislike.svg
ice-cream.svg
palette.svg
behance-toolbar.svg
anchor-toolbar.svg
sketchbook.svg
fish-toolbar.svg
align-left-toolbar.svg
santa-hat.svg
warning-sign.svg
bookmarks.svg
dropzone.svg
road.svg
umbrella-toolbar.svg
user-add-toolbar.svg
wine-glass.svg
twitterbird.svg
weather-cloudy.svg
macbook-pro.svg
bug.svg
scissors.svg
top-list-toolbar.svg
boolean-subtract.svg
disguise.svg
bar-chart-ascending.svg
money.svg
zoom-out.svg
emoticon-meh.svg
target-toolbar.svg
zoomout.svg
skirt-1-toolbar.svg
file-type-settings-toolbar.svg
keyhole-toolbar.svg
microphone-toolbar.svg
square.svg
chicken-toolbar.svg
store-toolbar.svg
shape-scale-down.svg
lightbulb.svg
unlocked-toolbar.svg
genie-lamp.svg
elephant-toolbar.svg
thumbs-down-toolbar.svg
food.svg
list-toolbar.svg
moterbike.svg
chicken.svg
baby.svg
ipad-mini.svg
twitter-toolbar.svg
tennis-court-toolbar.svg
piano.svg
party-mask.svg
wheelbarrow.svg
paypal-toolbar.svg
weather-sunny.svg
piano-toolbar.svg
glasses-3.svg
hightlighter.svg
8ball.svg
chefs-hat.svg
beer-glass.svg
piggy-bank.svg
piano-2.svg
sound-on.svg
stats.svg
popsicle.svg
calendar.svg
baseball-jersey-toolbar.svg
tag-toolbar.svg
palette-toolbar.svg
baseball-helmet-toolbar.svg
balloons.svg
mayo.svg
gavel.svg
action.svg
`

const icons = iconList.split('\n').reduce((acc, iconFile) => {
  const name = iconFile.replace('.svg', '')
  acc[name] = {
    component: BaseIcon,
    props: {
      name,
    },
  }
  return acc
}, {})

export default icons
