export class RoomTypePeriodPackage {
  id: number | undefined
  spaceType: object | null
  amount: number
  name: string

  constructor() {
    this.spaceType = null
    this.amount = 0
    this.name = ''
  }
}
