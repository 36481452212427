import Vue from 'vue'

export const state = () => ({
  parkingSpots: [],
  // Key: location id
  // Value: array
  locationParkingSpots: {},
})

export const getters = {
  getParkingSpots: (state) => {
    return state.parkingSpots || []
  },

  getParkingSpotsByLocationID: (state) => (locationID) => {
    return state.locationParkingSpots[locationID] || []
  },
}

export const mutations = {
  SET_PARKING_SPOTS: (state, parkingSpots) => {
    state.parkingSpots = parkingSpots
  },

  SET_PARKING_SPOTS_FOR_LOCATION_ID: (state, { id, parkingSpots }) => {
    if (!state.locationParkingSpots) {
      state.locationParkingSpots = {}
    }
    Vue.set(state.locationParkingSpots, id, parkingSpots)
  },
}

export const actions = {
  setParkingSpots({ commit }, parkingSpots) {
    commit('SET_PARKINGSPOTS', parkingSpots)
  },

  async setupParkingSpots({ commit }) {
    const r = await this.$axios.$get(`/parking-spot`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting parkingSpot')
    }
    commit('SET_PARKING_SPOTS', r.data)
  },

  async setupParkingSpotsByLocationID({ commit }, locationID) {
    if (locationID <= 0) {
      throw new Error('error: locationID <= 0')
    }
    const r = await this.$axios.$get(`/location/${locationID}/parking-spot`)
    if (!r || !r.data) {
      throw new Error(
        'response format error: error fetching parking spots by location id'
      )
    }
    commit('SET_PARKING_SPOTS_FOR_LOCATION_ID', {
      id: locationID,
      parkingSpots: r.data,
    })
  },
}
