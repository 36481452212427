export class Floor {
  id: number | undefined
  location: object | null
  name: string
  area: object | null
  spaceList: object | null

  constructor() {
    this.location = null
    this.name = ''
    this.area = null
    this.spaceList = []
  }
}
