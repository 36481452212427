export const state = () => ({
  statusMembers: [],
})

export const getters = {
  getStatusMembers: (state) => {
    return state.statusMembers
  },
}

export const mutations = {
  SET_STATUS_MEMBERs: (state, statusMembers) => {
    state.statusMembers = statusMembers
  },
}

export const actions = {
  setStatusMembers({ commit }, statusMembers) {
    commit('SET_STATUSMEMBERs', statusMembers)
  },

  async setupStatusMembers({ commit }) {
    const r = await this.$axios.$get(`/status-member`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting statusMember')
    }
    commit('SET_STATUS_MEMBERs', r.data)
  },
}
