export const state = () => ({
  userLocationPassEntrys: [],
})

export const getters = {
  getUserLocationPassEntrys: (state) => {
    return state.userLocationPassEntrys
  },
}

export const mutations = {
  SET_USER_LOCATION_PASS_ENTRYs: (state, userLocationPassEntrys) => {
    state.userLocationPassEntrys = userLocationPassEntrys
  },
}

export const actions = {
  setUserLocationPassEntrys({ commit }, userLocationPassEntrys) {
    commit('SET_USERLOCATIONPASSENTRYs', userLocationPassEntrys)
  },

  async setupUserLocationPassEntrys({ commit }) {
    const r = await this.$axios.$get(`/user-location-pass-entry`)
    if (!r || !r.data) {
      throw new Error(
        'response format error: error getting userLocationPassEntry'
      )
    }
    commit('SET_USER_LOCATION_PASS_ENTRYs', r.data)
  },
}
