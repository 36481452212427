export const state = () => ({
  contractSpacePeriodPackagePriceOffices: [],
})

export const getters = {
  getContractSpacePeriodPackagePriceOffices: (state) => {
    return state.contractSpacePeriodPackagePriceOffices
  },
}

export const mutations = {
  SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICES: (
    state,
    contractSpacePeriodPackagePriceOffices
  ) => {
    state.contractSpacePeriodPackagePriceOfficeS = contractSpacePeriodPackagePriceOffices
  },
}

export const actions = {
  setContractSpacePeriodPackagePriceOffices(
    { commit },
    contractSpacePeriodPackagePriceOffices
  ) {
    commit(
      'SET_CONTRACTSPACEPERIODPACKAGEPRICEOFFICES',
      contractSpacePeriodPackagePriceOffices
    )
  },

  async setupContractSpacePeriodPackagePriceOffices({ commit, getters }) {
    if (
      !getters.getContractSpacePeriodPackagePriceOffices ||
      getters.getContractSpacePeriodPackagePriceOffices.length === 0
    ) {
      const r = await this.$axios.$get(
        `/contract-space-period-package-price-office`
      )
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting contractSpacePeriodPackagePriceOffice'
        )
      }
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICES', r.data)
    }
  },
}
