export const state = () => ({
  contractSpacePeriodPackagePriceRooms: [],
})

export const getters = {
  getContractSpacePeriodPackagePriceRooms: (state) => {
    return state.contractSpacePeriodPackagePriceRooms
  },
}

export const mutations = {
  SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOMS: (
    state,
    contractSpacePeriodPackagePriceRooms
  ) => {
    state.contractSpacePeriodPackagePriceRoomS = contractSpacePeriodPackagePriceRooms
  },
}

export const actions = {
  setContractSpacePeriodPackagePriceRooms(
    { commit },
    contractSpacePeriodPackagePriceRooms
  ) {
    commit(
      'SET_CONTRACTSPACEPERIODPACKAGEPRICEROOMS',
      contractSpacePeriodPackagePriceRooms
    )
  },

  async setupContractSpacePeriodPackagePriceRooms({ commit, getters }) {
    if (
      !getters.getContractSpacePeriodPackagePriceRooms ||
      getters.getContractSpacePeriodPackagePriceRooms.length === 0
    ) {
      const r = await this.$axios.$get(
        `/contract-space-period-package-price-room`
      )
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting contractSpacePeriodPackagePriceRoom'
        )
      }
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOMS', r.data)
    }
  },
}
