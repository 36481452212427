export class Location {
  id?: number
  name: string
  code: string
  address: object | null
  cityCountry: string
  email: string
  floors: number
  numbers: string
  parkingSpotList: object | null
  floorList: object | null
  extrasTypeList: object | null
  parkingPricingList: object | null
  locationPassList: object | null

  constructor() {
    this.name = ''
    this.code = ''
    this.address = null
    this.cityCountry = ''
    this.email = ''
    this.floors = 0
    this.numbers = ''
    this.parkingSpotList = []
    this.floorList = []
    this.extrasTypeList = []
    this.parkingPricingList = []
    this.locationPassList = []
  }
}
