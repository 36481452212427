export const state = () => ({
  tickets: [],
})

export const getters = {
  getTickets: (state) => {
    return state.tickets
  },
}

export const mutations = {
  SET_TICKETS: (state, tickets) => {
    state.tickets = tickets
  },
}

export const actions = {
  setTickets({ commit }, tickets) {
    commit('SET_TICKETS', tickets)
  },

  async setupTickets({ commit }) {
    const { data } = await this.$axios.$get(`/ticket`)
    if (!Array.isArray(data)) {
      throw new TypeError('response in setupTickets msut be array')
    }
    commit('SET_TICKETS', data)
  },
}
