export const state = () => ({
  locationPasses: [],
})

export const getters = {
  getLocationPasses: (state) => {
    return state.locationPasses
  },
}

export const mutations = {
  SET_LOCATION_PASSs: (state, locationPasses) => {
    state.locationPasses = locationPasses
  },
}

export const actions = {
  setLocationPasses({ commit }, locationPasses) {
    commit('SET_LOCATIONPASSs', locationPasses)
  },

  async setupLocationPasses({ commit }) {
    const r = await this.$axios.$get(`/location-pass`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting locationPass')
    }
    commit('SET_LOCATION_PASSs', r.data)
  },
}
