import { ContractSpacePeriodPackagePriceRoom } from '@/entities/contractSpacePeriodPackagePriceRoom.ts'

export const state = () => ({
  contractSpacePeriodPackagePriceRoom: new ContractSpacePeriodPackagePriceRoom(),
})

export const getters = {
  getContractSpacePeriodPackagePriceRoom: (state) => {
    return state.contractSpacePeriodPackagePriceRoom
  },
}

export const mutations = {
  SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM: (state, payload) => {
    state.contractSpacePeriodPackagePriceRoom = payload
  },
}

export const actions = {
  setContractSpacePeriodPackagePriceRoom({ commit }, payload) {
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
  },

  async getContractSpacePeriodPackagePriceRoomById({ commit }, id) {
    const r = await this.$axios.$get(
      `/contract-space-period-package-price-room/${id}`
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format: contractSpacePeriodPackagePriceRoom'
      )
    }
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', r.data)
  },

  async createContractSpacePeriodPackagePriceRoom(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
    }
    const r = await this.$axios.$post(
      `/contract-space-period-package-price-room`,
      getters.getContractSpacePeriodPackagePriceRoom
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: contractSpacePeriodPackagePriceRoom'
      )
    }
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', r.data)
  },

  async saveContractSpacePeriodPackagePriceRoom({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
    }
    const r = await this.$axios.$put(
      `/contract-space-period-package-price-room/${getters.getContractSpacePeriodPackagePriceRoom.id}`,
      getters.getContractSpacePeriodPackagePriceRoom
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: contractSpacePeriodPackagePriceRoom'
      )
    }
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', r.data)
  },

  async deleteContractSpacePeriodPackagePriceRoom(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
    }
    await this.$axios.$delete(
      `/contract-space-period-package-price-room/${getters.getContractSpacePeriodPackagePriceRoom.id}`
    )
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_ROOM', null)
  },
}
