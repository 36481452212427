import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/annex.js'), 'annex.js')
  resolveStoreModules(require('../store/base-time-unit.js'), 'base-time-unit.js')
  resolveStoreModules(require('../store/base-time-units.js'), 'base-time-units.js')
  resolveStoreModules(require('../store/companies.js'), 'companies.js')
  resolveStoreModules(require('../store/company-specifics.js'), 'company-specifics.js')
  resolveStoreModules(require('../store/company-specificses.js'), 'company-specificses.js')
  resolveStoreModules(require('../store/company-type.js'), 'company-type.js')
  resolveStoreModules(require('../store/company-types.js'), 'company-types.js')
  resolveStoreModules(require('../store/company.js'), 'company.js')
  resolveStoreModules(require('../store/contract-deposit-status.js'), 'contract-deposit-status.js')
  resolveStoreModules(require('../store/contract-deposit-statuses.js'), 'contract-deposit-statuses.js')
  resolveStoreModules(require('../store/contract-extras.js'), 'contract-extras.js')
  resolveStoreModules(require('../store/contract-extrass.js'), 'contract-extrass.js')
  resolveStoreModules(require('../store/contract-parking.js'), 'contract-parking.js')
  resolveStoreModules(require('../store/contract-parkings.js'), 'contract-parkings.js')
  resolveStoreModules(require('../store/contract-space-period-package-price-office.js'), 'contract-space-period-package-price-office.js')
  resolveStoreModules(require('../store/contract-space-period-package-price-offices.js'), 'contract-space-period-package-price-offices.js')
  resolveStoreModules(require('../store/contract-space-period-package-price-room.js'), 'contract-space-period-package-price-room.js')
  resolveStoreModules(require('../store/contract-space-period-package-price-rooms.js'), 'contract-space-period-package-price-rooms.js')
  resolveStoreModules(require('../store/contract-statuses.js'), 'contract-statuses.js')
  resolveStoreModules(require('../store/contract.js'), 'contract.js')
  resolveStoreModules(require('../store/contracts.js'), 'contracts.js')
  resolveStoreModules(require('../store/cost-categories.js'), 'cost-categories.js')
  resolveStoreModules(require('../store/cost-category.js'), 'cost-category.js')
  resolveStoreModules(require('../store/cost.js'), 'cost.js')
  resolveStoreModules(require('../store/costs.js'), 'costs.js')
  resolveStoreModules(require('../store/currencies.js'), 'currencies.js')
  resolveStoreModules(require('../store/currency.js'), 'currency.js')
  resolveStoreModules(require('../store/deposit-status.js'), 'deposit-status.js')
  resolveStoreModules(require('../store/deposit-statuses.js'), 'deposit-statuses.js')
  resolveStoreModules(require('../store/extras-categories.js'), 'extras-categories.js')
  resolveStoreModules(require('../store/extras-category.js'), 'extras-category.js')
  resolveStoreModules(require('../store/extras.js'), 'extras.js')
  resolveStoreModules(require('../store/extrases.js'), 'extrases.js')
  resolveStoreModules(require('../store/fees.js'), 'fees.js')
  resolveStoreModules(require('../store/floor.js'), 'floor.js')
  resolveStoreModules(require('../store/floors.js'), 'floors.js')
  resolveStoreModules(require('../store/friend.js'), 'friend.js')
  resolveStoreModules(require('../store/friends.js'), 'friends.js')
  resolveStoreModules(require('../store/fundamental-space-type.js'), 'fundamental-space-type.js')
  resolveStoreModules(require('../store/fundamental-space-types.js'), 'fundamental-space-types.js')
  resolveStoreModules(require('../store/inbox.js'), 'inbox.js')
  resolveStoreModules(require('../store/industries.js'), 'industries.js')
  resolveStoreModules(require('../store/invoice-status.js'), 'invoice-status.js')
  resolveStoreModules(require('../store/invoice-statuses.js'), 'invoice-statuses.js')
  resolveStoreModules(require('../store/invoice.js'), 'invoice.js')
  resolveStoreModules(require('../store/invoices.js'), 'invoices.js')
  resolveStoreModules(require('../store/location-pass.js'), 'location-pass.js')
  resolveStoreModules(require('../store/location-passes.js'), 'location-passes.js')
  resolveStoreModules(require('../store/location.js'), 'location.js')
  resolveStoreModules(require('../store/locations.js'), 'locations.js')
  resolveStoreModules(require('../store/notifications.js'), 'notifications.js')
  resolveStoreModules(require('../store/one-of-fee-extras.js'), 'one-of-fee-extras.js')
  resolveStoreModules(require('../store/one-of-fee-extrass.js'), 'one-of-fee-extrass.js')
  resolveStoreModules(require('../store/one-of-fee-space-period-package-price-office.js'), 'one-of-fee-space-period-package-price-office.js')
  resolveStoreModules(require('../store/one-of-fee-space-period-package-price-offices.js'), 'one-of-fee-space-period-package-price-offices.js')
  resolveStoreModules(require('../store/one-of-fee-space-period-package-price-room.js'), 'one-of-fee-space-period-package-price-room.js')
  resolveStoreModules(require('../store/one-of-fee-space-period-package-price-rooms.js'), 'one-of-fee-space-period-package-price-rooms.js')
  resolveStoreModules(require('../store/one-of-fee-user-location-pass-entry.js'), 'one-of-fee-user-location-pass-entry.js')
  resolveStoreModules(require('../store/one-of-fee-user-location-pass-entrys.js'), 'one-of-fee-user-location-pass-entrys.js')
  resolveStoreModules(require('../store/one-of-fee-user-location-pass.js'), 'one-of-fee-user-location-pass.js')
  resolveStoreModules(require('../store/one-of-fee-user-location-passs.js'), 'one-of-fee-user-location-passs.js')
  resolveStoreModules(require('../store/one-of-fee.js'), 'one-of-fee.js')
  resolveStoreModules(require('../store/one-of-fees.js'), 'one-of-fees.js')
  resolveStoreModules(require('../store/parking-pricing.js'), 'parking-pricing.js')
  resolveStoreModules(require('../store/parking-pricings.js'), 'parking-pricings.js')
  resolveStoreModules(require('../store/parking-spot.js'), 'parking-spot.js')
  resolveStoreModules(require('../store/parking-spots.js'), 'parking-spots.js')
  resolveStoreModules(require('../store/parking-type.js'), 'parking-type.js')
  resolveStoreModules(require('../store/parking-types.js'), 'parking-types.js')
  resolveStoreModules(require('../store/pass-type.js'), 'pass-type.js')
  resolveStoreModules(require('../store/pass-types.js'), 'pass-types.js')
  resolveStoreModules(require('../store/payment-statuses.js'), 'payment-statuses.js')
  resolveStoreModules(require('../store/payment.js'), 'payment.js')
  resolveStoreModules(require('../store/payments.js'), 'payments.js')
  resolveStoreModules(require('../store/pre-invoice-contract.js'), 'pre-invoice-contract.js')
  resolveStoreModules(require('../store/pre-invoice-contracts.js'), 'pre-invoice-contracts.js')
  resolveStoreModules(require('../store/pre-invoice-one-of-fee.js'), 'pre-invoice-one-of-fee.js')
  resolveStoreModules(require('../store/pre-invoice-one-of-fees.js'), 'pre-invoice-one-of-fees.js')
  resolveStoreModules(require('../store/pre-invoice.js'), 'pre-invoice.js')
  resolveStoreModules(require('../store/pre-invoices.js'), 'pre-invoices.js')
  resolveStoreModules(require('../store/role.js'), 'role.js')
  resolveStoreModules(require('../store/roles.js'), 'roles.js')
  resolveStoreModules(require('../store/room-period-price.js'), 'room-period-price.js')
  resolveStoreModules(require('../store/room-period-prices.js'), 'room-period-prices.js')
  resolveStoreModules(require('../store/room-type-period-package.js'), 'room-type-period-package.js')
  resolveStoreModules(require('../store/room-type-period-packages.js'), 'room-type-period-packages.js')
  resolveStoreModules(require('../store/space-period-package-price.js'), 'space-period-package-price.js')
  resolveStoreModules(require('../store/space-period-package-prices.js'), 'space-period-package-prices.js')
  resolveStoreModules(require('../store/space-period-pricing.js'), 'space-period-pricing.js')
  resolveStoreModules(require('../store/space-period-pricings.js'), 'space-period-pricings.js')
  resolveStoreModules(require('../store/space-period.js'), 'space-period.js')
  resolveStoreModules(require('../store/space-periods.js'), 'space-periods.js')
  resolveStoreModules(require('../store/space-type-period-packages.js'), 'space-type-period-packages.js')
  resolveStoreModules(require('../store/space-type.js'), 'space-type.js')
  resolveStoreModules(require('../store/space-types.js'), 'space-types.js')
  resolveStoreModules(require('../store/space.js'), 'space.js')
  resolveStoreModules(require('../store/spaces.js'), 'spaces.js')
  resolveStoreModules(require('../store/status-companies.js'), 'status-companies.js')
  resolveStoreModules(require('../store/status-company.js'), 'status-company.js')
  resolveStoreModules(require('../store/status-companyies.js'), 'status-companyies.js')
  resolveStoreModules(require('../store/status-member.js'), 'status-member.js')
  resolveStoreModules(require('../store/status-members.js'), 'status-members.js')
  resolveStoreModules(require('../store/supplier.js'), 'supplier.js')
  resolveStoreModules(require('../store/suppliers.js'), 'suppliers.js')
  resolveStoreModules(require('../store/tax-rate.js'), 'tax-rate.js')
  resolveStoreModules(require('../store/tax-rates.js'), 'tax-rates.js')
  resolveStoreModules(require('../store/ticket.js'), 'ticket.js')
  resolveStoreModules(require('../store/tickets.js'), 'tickets.js')
  resolveStoreModules(require('../store/user-location-pass-entries.js'), 'user-location-pass-entries.js')
  resolveStoreModules(require('../store/user-location-pass-entry.js'), 'user-location-pass-entry.js')
  resolveStoreModules(require('../store/user-location-pass.js'), 'user-location-pass.js')
  resolveStoreModules(require('../store/user-location-passes.js'), 'user-location-passes.js')
  resolveStoreModules(require('../store/user-post-comment.js'), 'user-post-comment.js')
  resolveStoreModules(require('../store/user-post-comments.js'), 'user-post-comments.js')
  resolveStoreModules(require('../store/user-post.js'), 'user-post.js')
  resolveStoreModules(require('../store/user-posts.js'), 'user-posts.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/users.js'), 'users.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
