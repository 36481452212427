import Vue from 'vue'

export const state = () => ({
  extrases: [],

  extrasCategoryExtrases: {},
})

export const getters = {
  getExtrases: (state) => {
    return state.extrases
  },
  getExtrasesByExtrasCategory: (state) => state.extrasCategoryExtrases,
  getExtrasesByExtrasCategoryID: (state) => (extrasCategoryID) =>
    state.extrasCategoryExtrases[extrasCategoryID],
}

export const mutations = {
  SET_EXTRASS: (state, extrases) => {
    state.extrases = extrases
  },
  SET_EXTRASES_BY_EXTRAS_CATEGORY_ID: (
    state,
    { extrasCategoryID, extrasCategories }
  ) => {
    if (!state.extrasCategoryExtrases) {
      state.extrasCategoryExtrases = {}
    }
    Vue.set(state.extrasCategoryExtrases, extrasCategoryID, extrasCategories)
  },
}

export const actions = {
  setExtrases({ commit }, extrases) {
    commit('SET_EXTRASs', extrases)
  },

  async setupExtrases({ commit }) {
    const { data } = await this.$axios.$get(`/extras`)
    commit('SET_EXTRASS', data)
    return data
  },

  async setupExtrasesByExtrasCategoryID({ commit }, extrasCategoryID) {
    const { data } = await this.$axios.$get(
      `/extras-category/${extrasCategoryID}/extras`
    )
    commit('SET_EXTRASES_BY_EXTRAS_CATEGORY_ID', {
      extrasCategoryID,
      extrasCategories: data,
    })
    return data
  },
}
