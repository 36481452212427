import { ContractParking } from '@/entities/contractParking.ts'

export const state = () => ({
  contractParking: new ContractParking(),
})

export const getters = {
  getContractParking: (state) => {
    return state.contractParking
  },
}

export const mutations = {
  SET_CONTRACT_PARKING: (state, payload) => {
    state.contractParking = payload
  },
}

export const actions = {
  setContractParking({ commit }, payload) {
    commit('SET_CONTRACT_PARKING', payload)
  },

  async getContractParkingById({ commit }, id) {
    const r = await this.$axios.$get(`/contract-parking/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: contractParking')
    }
    commit('SET_CONTRACT_PARKING', r.data)
  },

  async createContractParking({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_PARKING', payload)
    }
    const r = await this.$axios.$post(
      `/contract-parking`,
      getters.getContractParking
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: contractParking')
    }
    commit('SET_CONTRACT_PARKING', r.data)
  },

  async saveContractParking({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_PARKING', payload)
    }
    const r = await this.$axios.$put(
      `/contract-parking/${getters.getContractParking.id}`,
      getters.getContractParking
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: contractParking')
    }
    commit('SET_CONTRACT_PARKING', r.data)
  },

  async deleteContractParking({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_PARKING', payload)
    }
    await this.$axios.$delete(
      `/contract-parking/${getters.getContractParking.id}`
    )
    commit('SET_CONTRACT_PARKING', null)
  },
}
