import { LocationPass } from '@/entities/locationPass.ts'

export const state = () => ({
  locationPass: new LocationPass(),
})

export const getters = {
  getLocationPass: (state) => {
    return state.locationPass
  },
}

export const mutations = {
  SET_LOCATION_PASS: (state, payload) => {
    state.locationPass = payload
  },
}

export const actions = {
  setLocationPass({ commit }, payload) {
    commit('SET_LOCATION_PASS', payload)
  },

  async getLocationPassById({ commit }, id) {
    const r = await this.$axios.$get(`/location-pass/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: locationPass')
    }
    commit('SET_LOCATION_PASS', r.data)
  },

  async createLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_LOCATION_PASS', payload)
    }
    const r = await this.$axios.$post(`/location-pass`, getters.getLocationPass)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: locationPass')
    }
    commit('SET_LOCATION_PASS', r.data)
  },

  async saveLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_LOCATION_PASS', payload)
    }
    const r = await this.$axios.$put(
      `/location-pass/${getters.getLocationPass.id}`,
      getters.getLocationPass
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: locationPass')
    }
    commit('SET_LOCATION_PASS', r.data)
  },

  async deleteLocationPass({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_LOCATION_PASS', payload)
    await this.$axios.$delete(`/location-pass/${getters.getLocationPass.id}`)
    commit('SET_LOCATION_PASS', null)
  },
}
