import { ParkingType } from '@/entities/parkingType.ts'

export const state = () => ({
  parkingType: new ParkingType(),
})

export const getters = {
  getParkingType: (state) => {
    return state.parkingType
  },
}

export const mutations = {
  SET_PARKING_TYPE: (state, payload) => {
    state.parkingType = payload
  },
}

export const actions = {
  setParkingType({ commit }, payload) {
    commit('SET_PARKING_TYPE', payload)
  },

  async getParkingTypeById({ commit }, id) {
    const r = await this.$axios.$get(`/parking-type/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: parkingType')
    }
    commit('SET_PARKING_TYPE', r.data)
  },

  async createParkingType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PARKING_TYPE', payload)
    }
    const r = await this.$axios.$post(`/parking-type`, getters.getParkingType)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: parkingType')
    }
    commit('SET_PARKING_TYPE', r.data)
  },

  async saveParkingType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PARKING_TYPE', payload)
    }
    const r = await this.$axios.$put(
      `/parking-type/${getters.getParkingType.id}`,
      getters.getParkingType
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: parkingType')
    }
    commit('SET_PARKING_TYPE', r.data)
  },

  async deleteParkingType({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_PARKING_TYPE', payload)
    await this.$axios.$delete(`/parking-type/${getters.getParkingType.id}`)
    commit('SET_PARKING_TYPE', null)
  },
}
