export class StatusMember {
    id: number | undefined
	name: string 
	userList: object | null

	constructor() {
		this.name = ""
		this.userList = []
	}
}
