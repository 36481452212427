export class CompanySpecifics {
    id: number | undefined
	url: string 
	logo: string 

	constructor() {
		this.url = ""
		this.logo = ""
	}
}
