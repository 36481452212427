export const state = () => ({
  payments: [],
})

export const getters = {
  getPayments: (state) => {
    return state.payments
  },
}

export const mutations = {
  SET_PAYMENTS: (state, payments) => {
    state.payments = payments
  },
}

export const actions = {
  setPayments({ commit }, payments) {
    commit('SET_PAYMENTS', payments)
  },

  async setupPayments({ commit, getters }) {
    if (!getters.getPayments || getters.getPayments.length === 0) {
      const r = await this.$axios.$get(`/payment`)
      if (!r || !r.data) {
        throw new Error('response format error: error getting payment')
      }
      commit('SET_PAYMENTS', r.data)
    }
  },
}
