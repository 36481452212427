import { ContractSpacePeriodPackagePriceOffice } from '@/entities/contractSpacePeriodPackagePriceOffice.ts'

export const state = () => ({
  contractSpacePeriodPackagePriceOffice: new ContractSpacePeriodPackagePriceOffice(),
})

export const getters = {
  getContractSpacePeriodPackagePriceOffice: (state) => {
    return state.contractSpacePeriodPackagePriceOffice
  },
}

export const mutations = {
  SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE: (state, payload) => {
    state.contractSpacePeriodPackagePriceOffice = payload
  },
}

export const actions = {
  setContractSpacePeriodPackagePriceOffice({ commit }, payload) {
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', payload)
  },

  async getContractSpacePeriodPackagePriceOfficeById({ commit }, id) {
    const r = await this.$axios.$get(
      `/contract-space-period-package-price-office/${id}`
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format: contractSpacePeriodPackagePriceOffice'
      )
    }
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', r.data)
  },

  async createContractSpacePeriodPackagePriceOffice(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', payload)
    }
    const r = await this.$axios.$post(
      `/contract-space-period-package-price-office`,
      getters.getContractSpacePeriodPackagePriceOffice
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: contractSpacePeriodPackagePriceOffice'
      )
    }
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', r.data)
  },

  async saveContractSpacePeriodPackagePriceOffice(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', payload)
    }
    const r = await this.$axios.$put(
      `/contract-space-period-package-price-office/${getters.getContractSpacePeriodPackagePriceOffice.id}`,
      getters.getContractSpacePeriodPackagePriceOffice
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: contractSpacePeriodPackagePriceOffice'
      )
    }
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', r.data)
  },

  async deleteContractSpacePeriodPackagePriceOffice(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', payload)
    }
    await this.$axios.$delete(
      `/contract-space-period-package-price-office/${getters.getContractSpacePeriodPackagePriceOffice.id}`
    )
    commit('SET_CONTRACT_SPACE_PERIOD_PACKAGE_PRICE_OFFICE', null)
  },
}
