export class Friend {
    id: number | undefined
	userSender: object | null
	userReciever: object | null
	accepted: boolean 

	constructor() {
		this.userSender = null
		this.userReciever = null
		this.accepted = false
	}
}
