export const state = () => ({
  companies: [],

  companiesShort: [],
})

export const getters = {
  getCompanies: (state) => {
    return state.companies
  },

  getCompaniesShort: (state) => state.companiesShort,
}

export const mutations = {
  SET_COMPANIES: (state, companies) => {
    state.companies = companies
  },

  SET_COMPANIES_SHORT: (state, companies) => {
    state.companiesShort = companies
  },
}

export const actions = {
  setCompanies({ commit }, companies) {
    commit('SET_COMPANIES', companies)
  },

  async setupCompanies({ commit }) {
    const r = await this.$axios.$get(`/company`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting company')
    }
    commit('SET_COMPANIES', r.data)
  },

  async setupCompaniesShort({ commit }) {
    const { data } = await this.$axios.$get(`/company-short`)
    commit('SET_COMPANIES_SHORT', data)
  },
}
