export const state = () => ({
  costs: [],
})

export const getters = {
  getCosts: (state) => {
    return state.costs
  },
}

export const mutations = {
  SET_COSTS: (state, costs) => {
    for (const c of costs) {
      const a = Number(c.amount) || 0
      const t = Number(c.tax) || 0
      c.total = a + a * (t / 100)
      if (c.timestamp) {
        c.timestamp = new Date(c.timestamp).toISOString().split('T')[0]
      }
    }
    state.costs = costs
  },
}

export const actions = {
  setCosts({ commit }, costs) {
    commit('SET_COSTS', costs)
  },

  async setupCosts({ commit }) {
    const { data } = await this.$axios.$get(`/cost`)
    if (!Array.isArray(data)) {
      throw new TypeError('response is not array in setupCosts')
    }
    commit('SET_COSTS', data)
  },
}
