export const state = () => ({
  preInvoices: [],
})

export const getters = {
  getPreInvoices: (state) => {
    return state.preInvoices
  },
}

export const mutations = {
  SET_PRE_INVOICES: (state, preInvoices) => {
    state.preInvoiceS = preInvoices
  },
}

export const actions = {
  setPreInvoices({ commit }, preInvoices) {
    commit('SET_PREINVOICES', preInvoices)
  },

  async setupPreInvoices({ commit, getters }) {
    if (!getters.getPreInvoices || getters.getPreInvoices.length === 0) {
      const r = await this.$axios.$get(`/pre-invoice`)
      if (!r || !r.data) {
        throw new Error('response format error: error getting preInvoice')
      }
      commit('SET_PRE_INVOICES', r.data)
    }
  },
}
