import { Company } from './company'

export class Payment {
  id?: number
  company: Company | null
  amount: number
  reference: string
  description: string
  overPayment: number
  dateOfPayment: string
  timestamp?: Date | string | null

  constructor() {
    this.company = null
    this.amount = 0
    this.overPayment = 0
    this.reference = ''
    this.description = ''
    this.dateOfPayment = ''
  }

  from(p: Payment) {
    this.company = p.company
    this.amount = p.amount
    this.reference = p.reference
    this.description = p.description
    this.overPayment = p.overPayment
    this.dateOfPayment = p.dateOfPayment
    if (p.timestamp) {
      this.timestamp = this.$fDate(new Date(p.timestamp))
    }
  }
}
