import { ContractExtras } from '@/entities/contractExtras.ts'

export const state = () => ({
  contractExtras: new ContractExtras(),
})

export const getters = {
  getContractExtras: (state) => {
    return state.contractExtras
  },
}

export const mutations = {
  SET_CONTRACT_EXTRAS: (state, payload) => {
    state.contractExtras = payload
  },
}

export const actions = {
  setContractExtras({ commit }, payload) {
    commit('SET_CONTRACT_EXTRAS', payload)
  },

  async getContractExtrasById({ commit }, id) {
    const r = await this.$axios.$get(`/contract-extras/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: contractExtras')
    }
    commit('SET_CONTRACT_EXTRAS', r.data)
  },

  async createContractExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_EXTRAS', payload)
    }
    const r = await this.$axios.$post(
      `/contract-extras`,
      getters.getContractExtras
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: contractExtras')
    }
    commit('SET_CONTRACT_EXTRAS', r.data)
  },

  async saveContractExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_EXTRAS', payload)
    }
    const r = await this.$axios.$put(
      `/contract-extras/${getters.getContractExtras.id}`,
      getters.getContractExtras
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: contractExtras')
    }
    commit('SET_CONTRACT_EXTRAS', r.data)
  },

  async deleteContractExtras({ commit, getters }, payload) {
    if (payload) {
      commit('SET_CONTRACT_EXTRAS', payload)
    }
    await this.$axios.$delete(
      `/contract-extras/${getters.getContractExtras.id}`
    )
    commit('SET_CONTRACT_EXTRAS', null)
  },
}
