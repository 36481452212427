export class ContractParking {
    id: number | undefined
	contract: object | null
	parkingSpot: object | null

	constructor() {
		this.contract = null
		this.parkingSpot = null
	}
}
