import { StatusCompany } from '@/entities/statusCompany.ts'

export const state = () => ({
  statusCompany: new StatusCompany(),
})

export const getters = {
  getStatusCompany: (state) => {
    return state.statusCompany
  },
}

export const mutations = {
  SET_STATUS_COMPANY: (state, payload) => {
    state.statusCompany = payload
  },
}

export const actions = {
  setStatusCompany({ commit }, payload) {
    commit('SET_STATUS_COMPANY', payload)
  },

  async getStatusCompanyById({ commit }, id) {
    const r = await this.$axios.$get(`/status-company/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: statusCompany')
    }
    commit('SET_STATUS_COMPANY', r.data)
  },

  async createStatusCompany({ commit, getters }, payload) {
    if (payload) {
      commit('SET_STATUS_COMPANY', payload)
    }
    const r = await this.$axios.$post(
      `/status-company`,
      getters.getStatusCompany
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: statusCompany')
    }
    commit('SET_STATUS_COMPANY', r.data)
  },

  async saveStatusCompany({ commit, getters }, payload) {
    if (payload) {
      commit('SET_STATUS_COMPANY', payload)
    }
    const r = await this.$axios.$put(
      `/status-company/${getters.getStatusCompany.id}`,
      getters.getStatusCompany
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: statusCompany')
    }
    commit('SET_STATUS_COMPANY', r.data)
  },

  async deleteStatusCompany({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_STATUS_COMPANY', payload)
    await this.$axios.$delete(`/status-company/${getters.getStatusCompany.id}`)
    commit('SET_STATUS_COMPANY', null)
  },
}
