export const state = () => ({
  cost: {
    name: '',
  },
})

export const getters = {
  getCost: (state) => {
    return state.cost
  },
}

export const mutations = {
  SET_COST: (state, payload) => {
    state.cost = payload
  },
}

export const actions = {
  setCost({ commit }, payload) {
    commit('SET_COST', payload)
  },

  async getCostById({ commit }, id) {
    const r = await this.$axios.$get(`/cost/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: cost')
    }
    commit('SET_COST', r.data)
  },

  async createCost({ commit }, payload) {
    if (!payload) {
      throw new Error('payload not sent')
    }
    const r = await this.$axios.$post(`/cost`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: cost')
    }
    commit('SET_COST', r.data)
    return r.data
  },

  async saveCost({ commit, getters }, payload) {
    if (payload) {
      commit('SET_COST', payload)
    }
    const r = await this.$axios.$put(
      `/cost/${getters.getCost.id}`,
      getters.getCost
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: cost')
    }
    commit('SET_COST', r.data)
    return r.data
  },

  async deleteCost({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_COST', payload)
    await this.$axios.$delete(`/cost/${getters.getCost.id}`)
    commit('SET_COST', null)
  },
}
