import Industry from '~/entities/industry'

export const CompanyStatusID = {
  ACTIVE: 1,
}

export class Company {
  id: number | undefined
  name: string
  address: string
  vat: string
  register: string
  email: string
  differentBilling: boolean
  billingName: string
  billingAddress: string
  billingVat: string
  billingRegister: string
  billingCity: string
  billingCountry: string
  comment: string
  userList: object | null
  status: { id: number; name: string } | null
  companyType: object | null
  country: string
  city: string
  industry: Industry | null

  constructor() {
    this.name = ''
    this.address = ''
    this.vat = ''
    this.register = ''
    this.email = ''
    this.differentBilling = false
    this.billingName = ''
    this.billingAddress = ''
    this.billingVat = ''
    this.billingRegister = ''
    this.comment = ''
    this.status = null
    this.companyType = null
    this.country = ''
    this.city = ''
    this.billingCity = ''
    this.billingCountry = ''
  }

  from(d: Company) {
    this.name = d.name
    this.address = d.address
    this.vat = d.vat
    this.register = d.register
    this.email = d.email
    this.differentBilling = d.differentBilling
    this.billingName = d.billingName
    this.billingAddress = d.billingAddress
    this.billingVat = d.billingVat
    this.billingRegister = d.billingRegister
    this.comment = d.comment
    this.userList = d.userList
    this.status = d.status
    this.companyType = d.companyType
    this.country = d.country
    this.city = d.city
    this.billingCity = d.billingCity
    this.billingCountry = d.billingCountry
  }
}
