import { SpacePeriodPricing } from '@/entities/spacePeriodPricing.ts'

export const state = () => ({
  spacePeriodPricing: new SpacePeriodPricing(),
})

export const getters = {
  getSpacePeriodPricing: (state) => {
    return state.spacePeriodPricing
  },
}

export const mutations = {
  SET_SPACE_PERIOD_PRICING: (state, payload) => {
    state.spacePeriodPricing = payload
  },
}

export const actions = {
  setSpacePeriodPricing({ commit }, payload) {
    commit('SET_SPACE_PERIOD_PRICING', payload)
  },

  async getSpacePeriodPricingById({ commit }, id) {
    const r = await this.$axios.$get(`/space-period-pricing/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: spacePeriodPricing')
    }
    commit('SET_SPACE_PERIOD_PRICING', r.data)
  },

  async createSpacePeriodPricing({ commit, getters }, payload) {
    if (payload) {
      commit('SET_SPACE_PERIOD_PRICING', payload)
    }
    const r = await this.$axios.$post(
      `/space-period-pricing`,
      getters.getSpacePeriodPricing
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: spacePeriodPricing'
      )
    }
    commit('SET_SPACE_PERIOD_PRICING', r.data)
  },

  async saveSpacePeriodPricing({ commit, getters }, payload) {
    if (payload) {
      commit('SET_SPACE_PERIOD_PRICING', payload)
    }
    const r = await this.$axios.$put(
      `/space-period-pricing/${getters.getSpacePeriodPricing.id}`,
      getters.getSpacePeriodPricing
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: spacePeriodPricing')
    }
    commit('SET_SPACE_PERIOD_PRICING', r.data)
  },

  async deleteSpacePeriodPricing({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_SPACE_PERIOD_PRICING', payload)
    await this.$axios.$delete(
      `/space-period-pricing/${getters.getSpacePeriodPricing.id}`
    )
    commit('SET_SPACE_PERIOD_PRICING', null)
  },
}
