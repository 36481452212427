import { CompanyType } from '@/entities/companyType.ts'

export const state = () => ({
  companyType: new CompanyType(),
})

export const getters = {
  getCompanyType: (state) => {
    return state.companyType
  },
}

export const mutations = {
  SET_COMPANY_TYPE: (state, payload) => {
    state.companyType = payload
  },
}

export const actions = {
  setCompanyType({ commit }, payload) {
    commit('SET_COMPANY_TYPE', payload)
  },

  async getCompanyTypeById({ commit }, id) {
    const r = await this.$axios.$get(`/company-type/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: companyType')
    }
    commit('SET_COMPANY_TYPE', r.data)
  },

  async createCompanyType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_COMPANY_TYPE', payload)
    }
    const r = await this.$axios.$post(`/company-type`, getters.getCompanyType)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: companyType')
    }
    commit('SET_COMPANY_TYPE', r.data)
  },

  async saveCompanyType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_COMPANY_TYPE', payload)
    }
    const r = await this.$axios.$put(
      `/company-type/${getters.getCompanyType.id}`,
      getters.getCompanyType
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: companyType')
    }
    commit('SET_COMPANY_TYPE', r.data)
  },

  async deleteCompanyType({ commit }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_COMPANY_TYPE', payload)
    await this.$axios.$delete(`/company-type/${payload.id}`)
    commit('SET_COMPANY_TYPE', null)
  },
}
