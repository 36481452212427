export const state = () => ({
  statusCompanies: [],
})

export const getters = {
  getStatusCompanies: (state) => {
    return state.statusCompanies
  },
}

export const mutations = {
  SET_STATUS_COMPANYs: (state, statusCompanies) => {
    state.statusCompanies = statusCompanies
  },
}

export const actions = {
  setStatusCompanies({ commit }, statusCompanies) {
    commit('SET_STATUSCOMPANIES', statusCompanies)
  },

  async setupStatusCompanies({ commit }) {
    const r = await this.$axios.$get(`/status-company`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting statusCompany')
    }
    commit('SET_STATUS_COMPANYs', r.data)
  },
}
