export const state = () => ({
  costCategories: [],
})

export const getters = {
  getCostCategories: (state) => {
    return state.costCategories
  },
}

export const mutations = {
  SET_COST_CATEGORIES: (state, costCategories) => {
    state.costCategories = costCategories
  },
}

export const actions = {
  setCostCategories({ commit }, costCategories) {
    commit('SET_COST_CATEGORIES', costCategories)
  },

  async setupCostCategories({ commit }) {
    const r = await this.$axios.$get(`/cost-category`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting company')
    }
    commit('SET_COST_CATEGORIES', r.data)
  },
}
