export const state = () => ({
  userPosts: [],
})

export const getters = {
  getUserPosts: (state) => {
    return state.userPosts
  },
}

export const mutations = {
  SET_USER_POSTS: (state, userPosts) => {
    state.userPosts = userPosts
  },
}

export const actions = {
  setUserPosts({ commit }, userPosts) {
    commit('SET_USER_POSTS', userPosts)
  },

  async setupUserPosts({ commit }) {
    const r = await this.$axios.$get(`/user-post`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting userPost')
    }
    commit('SET_USER_POSTS', r.data)
  },
}
