export class ParkingSpot {
  id: number | undefined
  name: string
  number: number | null
  parkingPricing: object | null

  constructor() {
    this.name = ''
    this.parkingPricing = null
    this.number = null
  }
}
