export const state = () => ({
  oneOfFeeSpacePeriodPackagePriceOffices: [],
})

export const getters = {
  getOneOfFeeSpacePeriodPackagePriceOffices: (state) => {
    return state.oneOfFeeSpacePeriodPackagePriceOffices
  },
}

export const mutations = {
  SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_OFFICES: (
    state,
    oneOfFeeSpacePeriodPackagePriceOffices
  ) => {
    state.oneOfFeeSpacePeriodPackagePriceOfficeS = oneOfFeeSpacePeriodPackagePriceOffices
  },
}

export const actions = {
  setOneOfFeeSpacePeriodPackagePriceOffices(
    { commit },
    oneOfFeeSpacePeriodPackagePriceOffices
  ) {
    commit(
      'SET_ONEOFFEESPACEPERIODPACKAGEPRICEOFFICES',
      oneOfFeeSpacePeriodPackagePriceOffices
    )
  },

  async setupOneOfFeeSpacePeriodPackagePriceOffices({ commit, getters }) {
    if (
      !getters.getOneOfFeeSpacePeriodPackagePriceOffices ||
      getters.getOneOfFeeSpacePeriodPackagePriceOffices.length === 0
    ) {
      const r = await this.$axios.$get(
        `/one-of-fee-space-period-package-price-office`
      )
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting oneOfFeeSpacePeriodPackagePriceOffice'
        )
      }
      commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_OFFICES', r.data)
    }
  },
}
