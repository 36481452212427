import Vue from 'vue'

export const state = () => ({
  roomTypePeriodPackages: [],
  locationRoomTypePeriodPackages: {},
})

export const getters = {
  getRoomTypePeriodPackages: (state) => {
    return state.roomTypePeriodPackages
  },
}

export const mutations = {
  SET_ROOM_TYPE_PERIOD_PACKAGES: (state, roomTypePeriodPackages) => {
    state.roomTypePeriodPackages = roomTypePeriodPackages
  },
  SET_ROOM_TYPE_PERIOD_PACKAGES_BY_LOCATION_ID(state, { locationID, rooms }) {
    if (!state.locationRoomTypePeriodPackages) {
      state.locationRoomTypePeriodPackages = {}
    }
    Vue.set(state.locationRoomTypePeriodPackages, locationID, rooms)
  },
}

export const actions = {
  setRoomTypePeriodPackages({ commit }, roomTypePeriodPackages) {
    commit('SET_ROOM_TYPE_PERIOD_PACKAGES', roomTypePeriodPackages)
  },

  async setupRoomTypePeriodPackages({ commit }) {
    const r = await this.$axios.$get(`/room-type-period-package`)
    if (!r || !r.data) {
      throw new Error(
        'response format error: error getting roomTypePeriodPackage'
      )
    }
    commit('SET_ROOM_TYPE_PERIOD_PACKAGES', r.data)
  },

  async setupRoomTypePeriodPackagesByLocationID({ commit }, locationID) {
    const { data } = await this.$axios.$get(
      `/location/${locationID}/room-type-period-package`
    )
    commit('SET_ROOM_TYPE_PERIOD_PACKAGES_BY_LOCATION_ID', {
      locationID,
      rooms: data,
    })
  },

  async setupRoomTypePeriodPackagesByLocationAndSpaceType(
    { commit },
    { locationID, spaceTypeID }
  ) {
    const { data } = await this.$axios.$get(
      `/location/${locationID}/space-type/${spaceTypeID}/room-type-period-package`
    )
    commit('SET_ROOM_TYPE_PERIOD_PACKAGES', data)
    return data
  },
}
