import { FundamentalSpaceType } from '@/entities/fundamentalSpaceType.ts'

export const state = () => ({
  fundamentalSpaceType: new FundamentalSpaceType(),
})

export const getters = {
  getFundamentalSpaceType: (state) => {
    return state.fundamentalSpaceType
  },
}

export const mutations = {
  SET_FUNDAMENTAL_SPACE_TYPE: (state, payload) => {
    state.fundamentalSpaceType = payload
  },
}

export const actions = {
  setFundamentalSpaceType({ commit }, payload) {
    commit('SET_FUNDAMENTAL_SPACE_TYPE', payload)
  },

  async getFundamentalSpaceTypeById({ commit }, id) {
    const r = await this.$axios.$get(`/fundamental-space-type/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: fundamentalSpaceType')
    }
    commit('SET_FUNDAMENTAL_SPACE_TYPE', r.data)
  },

  async createFundamentalSpaceType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_FUNDAMENTAL_SPACE_TYPE', payload)
    }
    const r = await this.$axios.$post(
      `/fundamental-space-type`,
      getters.getFundamentalSpaceType
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: fundamentalSpaceType'
      )
    }
    commit('SET_FUNDAMENTAL_SPACE_TYPE', r.data)
  },

  async saveFundamentalSpaceType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_FUNDAMENTAL_SPACE_TYPE', payload)
    }
    const r = await this.$axios.$put(
      `/fundamental-space-type/${getters.getFundamentalSpaceType.id}`,
      getters.getFundamentalSpaceType
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: fundamentalSpaceType'
      )
    }
    commit('SET_FUNDAMENTAL_SPACE_TYPE', r.data)
  },

  async deleteFundamentalSpaceType({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_FUNDAMENTAL_SPACE_TYPE', null)
    await this.$axios.$delete(
      `/fundamental-space-type/${getters.getFundamentalSpaceType.id}`
    )
    commit('SET_FUNDAMENTAL_SPACE_TYPE', null)
  },
}
