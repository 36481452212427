import { Floor } from '@/entities/floor.ts'

export const state = () => ({
  floor: new Floor(),
})

export const getters = {
  getFloor: (state) => {
    return state.floor
  },
}

export const mutations = {
  SET_FLOOR: (state, payload) => {
    state.floor = payload
  },
}

export const actions = {
  setFloor({ commit }, payload) {
    commit('SET_FLOOR', payload)
  },

  async getFloorById({ commit }, id) {
    const r = await this.$axios.$get(`/floor/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: floor')
    }
    commit('SET_FLOOR', r.data)
  },

  async createFloor({ commit, getters }, payload) {
    if (payload) {
      commit('SET_FLOOR', payload)
    }
    const r = await this.$axios.$post(`/floor`, getters.getFloor)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: floor')
    }
    commit('SET_FLOOR', r.data)
  },

  async createFloorByLocationID({ commit }, { locationID, floor }) {
    const r = await this.$axios.$post(`/location/${locationID}/floor`, floor)
    if (!r || !r.data) {
      throw new Error('response error for post floor')
    }
    commit('SET_FLOOR', r.data)
    return r.data
  },

  async saveFloor({ commit }, payload) {
    if (!payload || !payload.id) {
      throw new Error('payload myst be an object and must have an ID')
    }
    const r = await this.$axios.$put(`/floor/${payload.id}`, payload)
    if (!r || !r.data) {
      throw new Error('error in response format on saving: floor')
    }
    commit('SET_FLOOR', r.data)
  },

  async deleteFloor({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_FLOOR', payload)
    await this.$axios.$delete(`/floor/${getters.getFloor.id}`)
    commit('SET_FLOOR', null)
  },
}
