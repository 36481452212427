import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _29bb4df0 = () => interopDefault(import('../pages/available-space.vue' /* webpackChunkName: "pages/available-space" */))
const _725241a7 = () => interopDefault(import('../pages/bookings/index.vue' /* webpackChunkName: "pages/bookings/index" */))
const _1acad5a4 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _72270b1d = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _6048cfab = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0e92eedc = () => interopDefault(import('../pages/logs/index.vue' /* webpackChunkName: "pages/logs/index" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _5284f756 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _bb1ec17a = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _6cfe81d6 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _cd6cfe3c = () => interopDefault(import('../pages/admin/booking/index.vue' /* webpackChunkName: "pages/admin/booking/index" */))
const _7929128d = () => interopDefault(import('../pages/admin/extras.vue' /* webpackChunkName: "pages/admin/extras" */))
const _0b0f6959 = () => interopDefault(import('../pages/bookings/list.vue' /* webpackChunkName: "pages/bookings/list" */))
const _a1cde366 = () => interopDefault(import('../pages/bookings/parking.vue' /* webpackChunkName: "pages/bookings/parking" */))
const _0e308c08 = () => interopDefault(import('../pages/bookings/timeline.vue' /* webpackChunkName: "pages/bookings/timeline" */))
const _d65787ec = () => interopDefault(import('../pages/collaboration/contacts/index.vue' /* webpackChunkName: "pages/collaboration/contacts/index" */))
const _631b5176 = () => interopDefault(import('../pages/collaboration/inbox/index.vue' /* webpackChunkName: "pages/collaboration/inbox/index" */))
const _3a043452 = () => interopDefault(import('../pages/collaboration/posts/index.vue' /* webpackChunkName: "pages/collaboration/posts/index" */))
const _919fa534 = () => interopDefault(import('../pages/collaboration/tickets/index.vue' /* webpackChunkName: "pages/collaboration/tickets/index" */))
const _75b51b96 = () => interopDefault(import('../pages/finance/contracts/index.vue' /* webpackChunkName: "pages/finance/contracts/index" */))
const _7a81b5cc = () => interopDefault(import('../pages/finance/costs/index.vue' /* webpackChunkName: "pages/finance/costs/index" */))
const _6204fa2f = () => interopDefault(import('../pages/finance/fees/index.vue' /* webpackChunkName: "pages/finance/fees/index" */))
const _344398a8 = () => interopDefault(import('../pages/finance/invoices/index.vue' /* webpackChunkName: "pages/finance/invoices/index" */))
const _38601410 = () => interopDefault(import('../pages/finance/one-of-fees/index.vue' /* webpackChunkName: "pages/finance/one-of-fees/index" */))
const _cf9bcfa2 = () => interopDefault(import('../pages/finance/payments/index.vue' /* webpackChunkName: "pages/finance/payments/index" */))
const _c795ba9c = () => interopDefault(import('../pages/finance/preinvoice-generator/index.vue' /* webpackChunkName: "pages/finance/preinvoice-generator/index" */))
const _69ab8142 = () => interopDefault(import('../pages/reports/average-desk-price/index.vue' /* webpackChunkName: "pages/reports/average-desk-price/index" */))
const _472be7ac = () => interopDefault(import('../pages/reports/industry-segmentation/index.vue' /* webpackChunkName: "pages/reports/industry-segmentation/index" */))
const _4d9288e3 = () => interopDefault(import('../pages/reports/invoiced-revenue/index.vue' /* webpackChunkName: "pages/reports/invoiced-revenue/index" */))
const _3b247edc = () => interopDefault(import('../pages/reports/members/index.vue' /* webpackChunkName: "pages/reports/members/index" */))
const _592e953f = () => interopDefault(import('../pages/reports/monthly-costs/index.vue' /* webpackChunkName: "pages/reports/monthly-costs/index" */))
const _164c3f86 = () => interopDefault(import('../pages/reports/monthly-occupancy/index.vue' /* webpackChunkName: "pages/reports/monthly-occupancy/index" */))
const _55f0e76f = () => interopDefault(import('../pages/reports/P&L/index.vue' /* webpackChunkName: "pages/reports/P&L/index" */))
const _6beab13f = () => interopDefault(import('../pages/reports/realization/index.vue' /* webpackChunkName: "pages/reports/realization/index" */))
const _31a3f250 = () => interopDefault(import('../pages/reports/revenue-by-member/index.vue' /* webpackChunkName: "pages/reports/revenue-by-member/index" */))
const _95d10ac4 = () => interopDefault(import('../pages/reports/revenue-by-space/index.vue' /* webpackChunkName: "pages/reports/revenue-by-space/index" */))
const _35dbfcde = () => interopDefault(import('../pages/reports/space-utilization.vue' /* webpackChunkName: "pages/reports/space-utilization" */))
const _5ec6e1e7 = () => interopDefault(import('../pages/settings/apps/index.vue' /* webpackChunkName: "pages/settings/apps/index" */))
const _2912571a = () => interopDefault(import('../pages/settings/booking/index.vue' /* webpackChunkName: "pages/settings/booking/index" */))
const _1d3a44e2 = () => interopDefault(import('../pages/settings/contracts/index.vue' /* webpackChunkName: "pages/settings/contracts/index" */))
const _49957424 = () => interopDefault(import('../pages/settings/currencies/index.vue' /* webpackChunkName: "pages/settings/currencies/index" */))
const _9b5383d0 = () => interopDefault(import('../pages/settings/extras/index.vue' /* webpackChunkName: "pages/settings/extras/index" */))
const _8df05982 = () => interopDefault(import('../pages/settings/prices/index.vue' /* webpackChunkName: "pages/settings/prices/index" */))
const _c9cf693c = () => interopDefault(import('../pages/settings/spaces/index.vue' /* webpackChunkName: "pages/settings/spaces/index" */))
const _6fc483e8 = () => interopDefault(import('../pages/settings/suppliers/index.vue' /* webpackChunkName: "pages/settings/suppliers/index" */))
const _2be3f848 = () => interopDefault(import('../pages/settings/terms/index.vue' /* webpackChunkName: "pages/settings/terms/index" */))
const _8e818546 = () => interopDefault(import('../pages/space/floor-plan.vue' /* webpackChunkName: "pages/space/floor-plan" */))
const _15852a70 = () => interopDefault(import('../pages/admin/booking/parking.vue' /* webpackChunkName: "pages/admin/booking/parking" */))
const _01483383 = () => interopDefault(import('../pages/reports/utilization/offices/index.vue' /* webpackChunkName: "pages/reports/utilization/offices/index" */))
const _05912176 = () => interopDefault(import('../pages/reports/utilization/parkings/index.vue' /* webpackChunkName: "pages/reports/utilization/parkings/index" */))
const _24540044 = () => interopDefault(import('../pages/reports/utilization/rooms/index.vue' /* webpackChunkName: "pages/reports/utilization/rooms/index" */))
const _93acda00 = () => interopDefault(import('../pages/settings/booking/parking.vue' /* webpackChunkName: "pages/settings/booking/parking" */))
const _418f02e2 = () => interopDefault(import('../pages/companies/_id/index.vue' /* webpackChunkName: "pages/companies/_id/index" */))
const _6d658e5a = () => interopDefault(import('../pages/profile/_id.vue' /* webpackChunkName: "pages/profile/_id" */))
const _3c1f5561 = () => interopDefault(import('../pages/companies/_id/contracts/index.vue' /* webpackChunkName: "pages/companies/_id/contracts/index" */))
const _0bce3d2d = () => interopDefault(import('../pages/companies/_id/invoice/index.vue' /* webpackChunkName: "pages/companies/_id/invoice/index" */))
const _4f244788 = () => interopDefault(import('../pages/companies/_id/one-of-fees/index.vue' /* webpackChunkName: "pages/companies/_id/one-of-fees/index" */))
const _5d487be1 = () => interopDefault(import('../pages/companies/_id/usage/index.vue' /* webpackChunkName: "pages/companies/_id/usage/index" */))
const _36dfbfe8 = () => interopDefault(import('../pages/floor/_id/floor-plan.vue' /* webpackChunkName: "pages/floor/_id/floor-plan" */))
const _267ce832 = () => interopDefault(import('../pages/companies/_id/contracts/new/index.vue' /* webpackChunkName: "pages/companies/_id/contracts/new/index" */))
const _5826898d = () => interopDefault(import('../pages/companies/_id/one-of-fees/new/index.vue' /* webpackChunkName: "pages/companies/_id/one-of-fees/new/index" */))
const _065fb844 = () => interopDefault(import('../pages/companies/_id/contracts/_contractID/index.vue' /* webpackChunkName: "pages/companies/_id/contracts/_contractID/index" */))
const _6ced7210 = () => interopDefault(import('../pages/companies/_id/one-of-fees/_oneOfFeeID/index.vue' /* webpackChunkName: "pages/companies/_id/one-of-fees/_oneOfFeeID/index" */))
const _10a0bae3 = () => interopDefault(import('../pages/companies/_id/contracts/_contractID/annex/index.vue' /* webpackChunkName: "pages/companies/_id/contracts/_contractID/annex/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d8a8f054,
    name: "about"
  }, {
    path: "/available-space",
    component: _29bb4df0,
    name: "available-space"
  }, {
    path: "/bookings",
    component: _725241a7,
    name: "bookings"
  }, {
    path: "/companies",
    component: _1acad5a4,
    name: "companies"
  }, {
    path: "/dashboard",
    component: _72270b1d,
    name: "dashboard"
  }, {
    path: "/help",
    component: _6048cfab,
    name: "help"
  }, {
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/logs",
    component: _0e92eedc,
    name: "logs"
  }, {
    path: "/profile",
    component: _4bb7511c,
    name: "profile"
  }, {
    path: "/reset",
    component: _5284f756,
    name: "reset"
  }, {
    path: "/reset-password",
    component: _bb1ec17a,
    name: "reset-password"
  }, {
    path: "/search",
    component: _6cfe81d6,
    name: "search"
  }, {
    path: "/settings",
    component: _2c20a960,
    name: "settings"
  }, {
    path: "/admin/booking",
    component: _cd6cfe3c,
    name: "admin-booking"
  }, {
    path: "/admin/extras",
    component: _7929128d,
    name: "admin-extras"
  }, {
    path: "/bookings/list",
    component: _0b0f6959,
    name: "bookings-list"
  }, {
    path: "/bookings/parking",
    component: _a1cde366,
    name: "bookings-parking"
  }, {
    path: "/bookings/timeline",
    component: _0e308c08,
    name: "bookings-timeline"
  }, {
    path: "/collaboration/contacts",
    component: _d65787ec,
    name: "collaboration-contacts"
  }, {
    path: "/collaboration/inbox",
    component: _631b5176,
    name: "collaboration-inbox"
  }, {
    path: "/collaboration/posts",
    component: _3a043452,
    name: "collaboration-posts"
  }, {
    path: "/collaboration/tickets",
    component: _919fa534,
    name: "collaboration-tickets"
  }, {
    path: "/finance/contracts",
    component: _75b51b96,
    name: "finance-contracts"
  }, {
    path: "/finance/costs",
    component: _7a81b5cc,
    name: "finance-costs"
  }, {
    path: "/finance/fees",
    component: _6204fa2f,
    name: "finance-fees"
  }, {
    path: "/finance/invoices",
    component: _344398a8,
    name: "finance-invoices"
  }, {
    path: "/finance/one-of-fees",
    component: _38601410,
    name: "finance-one-of-fees"
  }, {
    path: "/finance/payments",
    component: _cf9bcfa2,
    name: "finance-payments"
  }, {
    path: "/finance/preinvoice-generator",
    component: _c795ba9c,
    name: "finance-preinvoice-generator"
  }, {
    path: "/reports/average-desk-price",
    component: _69ab8142,
    name: "reports-average-desk-price"
  }, {
    path: "/reports/industry-segmentation",
    component: _472be7ac,
    name: "reports-industry-segmentation"
  }, {
    path: "/reports/invoiced-revenue",
    component: _4d9288e3,
    name: "reports-invoiced-revenue"
  }, {
    path: "/reports/members",
    component: _3b247edc,
    name: "reports-members"
  }, {
    path: "/reports/monthly-costs",
    component: _592e953f,
    name: "reports-monthly-costs"
  }, {
    path: "/reports/monthly-occupancy",
    component: _164c3f86,
    name: "reports-monthly-occupancy"
  }, {
    path: "/reports/P&L",
    component: _55f0e76f,
    name: "reports-P&L"
  }, {
    path: "/reports/realization",
    component: _6beab13f,
    name: "reports-realization"
  }, {
    path: "/reports/revenue-by-member",
    component: _31a3f250,
    name: "reports-revenue-by-member"
  }, {
    path: "/reports/revenue-by-space",
    component: _95d10ac4,
    name: "reports-revenue-by-space"
  }, {
    path: "/reports/space-utilization",
    component: _35dbfcde,
    name: "reports-space-utilization"
  }, {
    path: "/settings/apps",
    component: _5ec6e1e7,
    name: "settings-apps"
  }, {
    path: "/settings/booking",
    component: _2912571a,
    name: "settings-booking"
  }, {
    path: "/settings/contracts",
    component: _1d3a44e2,
    name: "settings-contracts"
  }, {
    path: "/settings/currencies",
    component: _49957424,
    name: "settings-currencies"
  }, {
    path: "/settings/extras",
    component: _9b5383d0,
    name: "settings-extras"
  }, {
    path: "/settings/prices",
    component: _8df05982,
    name: "settings-prices"
  }, {
    path: "/settings/spaces",
    component: _c9cf693c,
    name: "settings-spaces"
  }, {
    path: "/settings/suppliers",
    component: _6fc483e8,
    name: "settings-suppliers"
  }, {
    path: "/settings/terms",
    component: _2be3f848,
    name: "settings-terms"
  }, {
    path: "/space/floor-plan",
    component: _8e818546,
    name: "space-floor-plan"
  }, {
    path: "/admin/booking/parking",
    component: _15852a70,
    name: "admin-booking-parking"
  }, {
    path: "/reports/utilization/offices",
    component: _01483383,
    name: "reports-utilization-offices"
  }, {
    path: "/reports/utilization/parkings",
    component: _05912176,
    name: "reports-utilization-parkings"
  }, {
    path: "/reports/utilization/rooms",
    component: _24540044,
    name: "reports-utilization-rooms"
  }, {
    path: "/settings/booking/parking",
    component: _93acda00,
    name: "settings-booking-parking"
  }, {
    path: "/companies/:id",
    component: _418f02e2,
    name: "companies-id"
  }, {
    path: "/profile/:id",
    component: _6d658e5a,
    name: "profile-id"
  }, {
    path: "/companies/:id/contracts",
    component: _3c1f5561,
    name: "companies-id-contracts"
  }, {
    path: "/companies/:id/invoice",
    component: _0bce3d2d,
    name: "companies-id-invoice"
  }, {
    path: "/companies/:id/one-of-fees",
    component: _4f244788,
    name: "companies-id-one-of-fees"
  }, {
    path: "/companies/:id/usage",
    component: _5d487be1,
    name: "companies-id-usage"
  }, {
    path: "/floor/:id?/floor-plan",
    component: _36dfbfe8,
    name: "floor-id-floor-plan"
  }, {
    path: "/companies/:id/contracts/new",
    component: _267ce832,
    name: "companies-id-contracts-new"
  }, {
    path: "/companies/:id/one-of-fees/new",
    component: _5826898d,
    name: "companies-id-one-of-fees-new"
  }, {
    path: "/companies/:id/contracts/:contractID",
    component: _065fb844,
    name: "companies-id-contracts-contractID"
  }, {
    path: "/companies/:id/one-of-fees/:oneOfFeeID",
    component: _6ced7210,
    name: "companies-id-one-of-fees-oneOfFeeID"
  }, {
    path: "/companies/:id/contracts/:contractID/annex",
    component: _10a0bae3,
    name: "companies-id-contracts-contractID-annex"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
