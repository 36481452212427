export const state = () => ({
  spacePeriodPackagePrice: {},
})

export const getters = {
  setupSpacePeriodPackagePricesByPackageAndSpace: (state) => {
    return state.spacePeriodPackagePrice
  },
}

export const mutations = {
  SET_SPACE_PERIOD_PACKAGE_PRICE: (state, spacePeriodPackagePrice) => {
    state.spacePeriodPackagePrice = spacePeriodPackagePrice
  },
}

export const actions = {
  setSpacePeriodPackagePrice({ commit }, spacePeriodPackagePrice) {
    commit('SET_SPACE_PERIOD_PACKAGE_PRICE', spacePeriodPackagePrice)
  },

  async setupSpacePeriodPackagePricesByPackageAndSpace(
    { commit },
    { packageID, spaceID }
  ) {
    const { data } = await this.$axios.$get(
      `/space-type-period-package/${packageID}/space/${spaceID}/space-period-package-price`
    )
    commit('SET_SPACE_PERIOD_PACKAGE_PRICE', data)
    return data
  },
}
