import { Contract } from '@/entities/contract.ts'

export const state = () => ({
  contract: new Contract(),

  // for contract -> annex transition
  contractEndDate: '',

  contractExtrases: [],
  contractParkings: [],
  contractDesks: [],
  contractPasses: [],
  // contractDesposite
  contractMeetingHours: [],
})

export const getters = {
  getContract: (state) => {
    return state.contract
  },

  getContractExtrases: (state) => state.contractExtrases,
  getContractParkings: (state) => state.contractParkings,
  getContractDesks: (state) => state.contractDesks,
  getContractPasses: (state) => state.contractPasses,
  getContractMeetingHours: (state) => state.contractMeetingHours,
  getContractEndDate: (state) => state.contractEndDate,
}

export const mutations = {
  SET_CONTRACT_END_DATE: (state, p) => {
    state.contractEndDate = p
  },

  SET_CONTRACT: (state, payload) => {
    if (!payload) {
      state.contract = new Contract()
    } else {
      if (typeof payload.startDate === 'string') {
        payload.startDate = payload.startDate.split('T')[0]
      }
      if (payload.endDate && typeof payload.endDate === 'string') {
        payload.endDate = payload.endDate.split('T')[0]
      }
      if (
        typeof payload.depositStatusTimestamp === 'string' &&
        payload.depositStatusTimestamp.length > 10
      ) {
        payload.depositStatusTimestamp = payload.depositStatusTimestamp.split(
          'T'
        )[0]
      }
      state.contract = payload
    }
  },

  SET_CONTRACT_EXTRAS: (state, extrases) => {
    state.contractExtrases = extrases
  },
  SET_CONTRACT_PASSES: (state, passes) => {
    state.contractPasses = passes
  },
  SET_CONTRACT_DESKS: (state, desks) => {
    state.contractDesks = desks
  },
  SET_CONTRACT_PARKINGS: (state, parkings) => {
    state.contractParkings = parkings
  },
  SET_CONTRACT_MEETING_HOURS: (state, meetingHours) => {
    state.contractMeetingHours = meetingHours
  },
}

export const actions = {
  setContract({ commit }, payload) {
    commit('SET_CONTRACT', payload)
  },

  // p should be string or Date
  setContractEndDate({ commit }, p) {
    let v
    if (typeof p === 'string') {
      v = p
    } else if (p instanceof Date) {
      v = p.toISOString()
    } else {
      throw new TypeError('Contract end date invalid')
    }
    commit('SET_CONTRACT_END_DATE', new Date(v))
  },

  async setupContractByID({ commit }, id) {
    const r = await this.$axios.$get(`/contract/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: contract')
    }
    commit('SET_CONTRACT', r.data)
    return r.data
  },

  async setupContractExtras({ commit, getters }) {
    const contract = getters.getContract
    if (!contract || !contract.id || contract.id <= 0) {
      throw new Error('contract not set')
    }
    const { data } = await this.$axios.$get(
      `/contract/${contract.id}/contract-extras`
    )
    commit('SET_CONTRACT_EXTRAS', data)
    return data
  },

  async setupContractParkings({ commit, getters }) {
    const contract = getters.getContract
    if (!contract || !contract.id || contract.id <= 0) {
      throw new Error('contract not set')
    }
    const { data } = await this.$axios.$get(
      `/contract/${contract.id}/contract-parking-spot`
    )
    commit('SET_CONTRACT_PARKINGS', data)
    return data
  },

  async setupContractPasses({ commit, getters }) {
    const contract = getters.getContract
    if (!contract || !contract.id || contract.id <= 0) {
      throw new Error('contract not set')
    }
    const { data } = await this.$axios.$get(
      `/contract/${contract.id}/user-location-pass`
    )
    commit('SET_CONTRACT_PASSES', data)
    return data
  },

  async setupContractDesks({ commit, getters }) {
    const contract = getters.getContract
    if (!contract || !contract.id || contract.id <= 0) {
      throw new Error('contract not set')
    }
    const { data } = await this.$axios.$get(
      `/contract/${contract.id}/contract-space-period-package-price-office`
    )
    commit('SET_CONTRACT_DESKS', data)
    return data
  },

  async setupContractMeetingHours({ commit, getters }) {
    const contract = getters.getContract
    if (!contract || !contract.id || contract.id <= 0) {
      throw new Error('contract not set')
    }
    const { data } = await this.$axios.$get(
      `/contract/${contract.id}/contract-space-period-package-price-room`
    )
    commit('SET_CONTRACT_MEETING_HOURS', data)
    return data
  },

  async createContract({ commit }, payload) {
    if (!payload) {
      throw new Error('payload must be provided')
    }
    const { data } = await this.$axios.$post(`/contract`, payload)
    commit('SET_CONTRACT', data)
    return data
  },

  async createParkingOnContract(_, { p, contractID }) {
    if (!contractID || !p || !p.parkingSpot || !p.parkingSpot.id) {
      return
    }
    try {
      await this.$axios.$post(
        `/contract/${contractID}/parking-spot/${p.parkingSpot.id}/contract-parking-spot`,
        p
      )
    } catch (error) {
      console.error(error)
    }
  },

  async createMeetingHoursOnContract(_, { m, contractID }) {
    try {
      await this.$axios.$post(
        `/contract/${contractID}/room-type-period-package-price/${m.roomTypePeriodPackagePrice.id}/contract-space-period-package-price-room`,
        m
      )
    } catch (error) {
      console.error(error)
    }
  },

  async createDesksOnContract(_, { d, contractID }) {
    if (
      contractID <= 0 ||
      !d ||
      !d.spacePeriodPackagePrice ||
      d.spacePeriodPackagePrice.id <= 0
    ) {
      return
    }
    try {
      await this.$axios.$post(
        `/contract/${contractID}/space-period-package-price/${d.spacePeriodPackagePrice.id}/contract-space-period-package-price-office`,
        d
      )
    } catch (error) {
      console.log(error)
    }
  },

  async createPassesOnContract(_, { p, contractID }) {
    if (contractID <= 0 || !p.locationPass || p.locationPass.id <= 0) {
      return
    }
    await this.$axios.$post(
      `/user/${p.user.id}/location-pass/${p.locationPass.id}/contract/${contractID}/user-location-pass`,
      p
    )
  },

  async createExtras(_, { e, contractID }) {
    if (contractID <= 0 || !e.extras || e.extras.id <= 0) {
      return
    }
    try {
      await this.$axios.$post(
        `/contract/${contractID}/extras/${e.extras.id}/contract-extras`,
        e
      )
    } catch (error) {
      console.error(error)
    }
  },

  async changeDepositStatus(_, { depositStatus, contractID, timestamp }) {
    if (!depositStatus || !depositStatus.id || depositStatus.id <= 0) {
      return
    }
    try {
      await this.$axios.$post(
        `/contract/${contractID}/deposit-status/${depositStatus.id}`,
        {
          timestamp,
        }
      )
    } catch (error) {
      console.error(error)
    }
  },

  // SECTION: update

  async saveContract({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('error: payload not passed to saveContract')
    }
    if (payload.contract.id <= 0) {
      throw new Error('error: payload passed to saveContract has id <= 0')
    }
    const res = await this.$axios.$post(
      `/contract/${payload.contract.id}/contract`,
      {
        ...payload,
        oldContractEnd: getters.getContractEndDate,
      }
    )
    commit('SET_CONTRACT', res.data)
    return res
  },
  async updatePassesOnContract(_, p) {
    await this.$axios.$put(`/contract-user-location-pass/${p.id}`, p)
  },

  async updateParkingOnContract(_, p) {
    await this.$axios.$put(`/contract-parking-spot/${p.id}`, p)
  },

  async updateExtrasOnContract(_, e) {
    await this.$axios.$put(`/contract-extras/${e.id}`, e)
  },

  async updateMeetingHoursOnContract(_, m) {
    await this.$axios.$put(
      `/contract-space-period-package-price-room/${m.id}`,
      m
    )
  },

  async updateDeskOnContract(_, d) {
    await this.$axios.$put(
      `/contract-space-period-package-price-office/${d.id}`,
      d
    )
  },
  // Section end: update
}
