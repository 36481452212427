import Vue from 'vue'
import { Space } from '@/entities/space.ts'

export const state = () => ({
  space: new Space(),
})

export const getters = {
  getSpace: (state) => {
    return state.space
  },
}

export const mutations = {
  SET_SPACE: (state, payload) => {
    state.space = payload
  },

  SET_LOCATION_BY_SPACE_ID: (state, { spaceID, location }) => {
    if (!state.spaceLocation) {
      state.spaceLocation = {}
    }

    Vue.set(state.spaceLocation, spaceID, location)
  },
}

export const actions = {
  setSpace({ commit }, payload) {
    commit('SET_SPACE', payload)
  },

  async getSpaceById({ commit }, id) {
    const r = await this.$axios.$get(`/space/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: space')
    }
    commit('SET_SPACE', r.data)
  },

  async createSpace({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('error: payload not provided')
    }
    const r = await this.$axios.$post(`/space`, getters.getSpace)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: space')
    }
    commit('SET_SPACE', r.data)
    return r.data
  },

  async createSpaceByFloorID({ commit }, { floorID, space }) {
    if (floorID <= 0) {
      throw new Error('floorID must be > 0')
    }
    if (!space) {
      throw new Error('You must pass the space to createSpaceByFloorID')
    }
    const { data } = await this.$axios.$post(`/floor/${floorID}/space`, space)
    console.log('new space', data)
    commit('SET_SPACE', data)
    return data
  },

  async saveSpace({ commit }, payload) {
    if (!payload) {
      throw new Error('You must pass payload to saveSpace')
    }
    const { data } = await this.$axios.$put(`/space/${payload.id}`, payload)
    commit('SET_SPACE', data)
    return data
  },

  async saveSpaceImage({ commit }, { image, space }) {
    if (!image || !space) throw new Error('You must pass payload to saveImage')
    const formData = new FormData()
    formData.append('file', image, image.name)
    await this.$axios.$post(`/space/${space.id}/avatar`, formData)
  },

  async deleteSpace({ commit }, payload) {
    if (!payload || !payload.id || payload.id <= 0) {
      throw new Error('can not remove invalid object')
    }
    await this.$axios.$delete(`/space/${payload.id}`)
    commit('SET_SPACE', null)
  },
}
