export const CompanyTypeID = {
  FREELANCE: 1,
}
export class CompanyType {
  id: number | undefined
  name: string
  companyList: object | null

  constructor() {
    this.name = ''
    this.companyList = []
  }
}
