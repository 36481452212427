export class OneOfFeeExtras {
    id: number | undefined
	oneOfFee: object | null
	extras: object | null
	amount: number 

	constructor() {
		this.oneOfFee = null
		this.extras = null
		this.amount = 0
	}
}
