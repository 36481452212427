export const state = () => ({
  oneOfFeeExtrass: [],
})

export const getters = {
  getOneOfFeeExtrass: (state) => {
    return state.oneOfFeeExtrass
  },
}

export const mutations = {
  SET_ONE_OF_FEE_EXTRASS: (state, oneOfFeeExtrass) => {
    state.oneOfFeeExtrasS = oneOfFeeExtrass
  },
}

export const actions = {
  setOneOfFeeExtrass({ commit }, oneOfFeeExtrass) {
    commit('SET_ONEOFFEEEXTRASS', oneOfFeeExtrass)
  },

  async setupOneOfFeeExtrass({ commit, getters }) {
    if (
      !getters.getOneOfFeeExtrass ||
      getters.getOneOfFeeExtrass.length === 0
    ) {
      const r = await this.$axios.$get(`/one-of-fee-extras`)
      if (!r || !r.data) {
        throw new Error('response format error: error getting oneOfFeeExtras')
      }
      commit('SET_ONE_OF_FEE_EXTRASS', r.data)
    }
  },
}
