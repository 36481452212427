










































































































































































































































































import { Vue, Prop, Component, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Socket } from 'vue-socket.io-extended'
import { initSocketUser } from '@/plugins/socketio.ts'

import { ROLE } from '~/config/constants'

const nsUser = namespace('user')
const nsInbox = namespace('inbox')
const nsCurrencies = namespace('currencies')
const nsNotifications = namespace('notifications')

@Component
export default class DefaultLayout extends Vue {
  @Prop({ type: String, default: '' }) readonly source!: string

  @Socket()
  connect() {
    console.log('connection established')
  }

  @Socket()
  msgToClient(data: any) {
    // eslint-disable-next-line no-unused-vars
    const notification = `@${
      data.userMessageSender.username
    }: ${data.message.substring(0, 10)} ${
      data.message.length > 10 ? '...' : ''
    }`
    this.addMessageForUser({
      uid: data.userMessageSender.id,
      msg: data,
    })
    this.addNotificationMessage(1)
  }

  @Socket()
  successfullySentMessage(msg: any) {
    this.addMessageForUser({
      uid: msg.userMessageReceiver.id,
      msg,
    })
  }

  @nsUser.Action('setUser') setUser: any
  @nsUser.Getter('getUser') getUser: any
  @nsUser.Getter('isAdmin') isAdmin: any
  @nsUser.Getter('isSuperAdmin') isSuperAdmin: any
  @nsUser.Getter('isCommunityAssistant') isCommunityAssistant: any

  @nsCurrencies.Action('setupCurrencies') setupCurrencies: any

  @nsInbox.Action('addMessagesForUser') addMessagesForUser: any
  @nsInbox.Action('addMessageForUser') addMessageForUser: any
  @nsInbox.Getter('getMessagesForUser') getMessagesForUser: any

  @nsNotifications.Action('setupNotifications') setupNotifications: any
  @nsNotifications.Action('addNotificationMessage') addNotificationMessage: any

  @nsNotifications.Getter('getNotificationsMessagesNotSeen')
  getNotificationsMessagesNotSeen: any

  @nsNotifications.Getter('getNotificationsFriendRequestsNotSeen')
  getNotificationsFriendRequestsNotSeen: any

  dialog: boolean = false

  drawer: object | null = null

  notifications: object[] = []

  infoDialogOpen = false
  infoDialog = null

  confirmDialogOpen = false

  confirmDialog = {}

  @Watch('getNotificationsMessagesNotSeen')
  onNewMessage(_newCount) {
    this.$bus.$emit('fetch-inbox')
  }

  // TODO: fix ;x
  get isContainerFluid() {
    // return this.$route.path === '/' && this.isAdminDashboard
    return false
  }

  get isAdminDashboard() {
    return (
      this.getUser &&
      this.getUser.role &&
      (this.getUser.role.roleId === ROLE.ADMIN ||
        this.getUser.role.roleId === ROLE.SUPER_ADMIN)
    )
  }

  get isDev() {
    return (process.env.BASE_URL as string).includes(':8001')
  }

  error: string | null = null

  profileActions = [
    {
      name: 'Help',
      path: '/help',
      disabled: true,
    },
    {
      name: 'Logout',
      path: '/login',
    },
  ]

  get getRoleID() {
    if (this.getUser) {
      return this.getUser && this.getUser.role && this.getUser.role.roleId
    }
    return -1
  }

  get items() {
    if (!this.getUser) {
      return []
    }

    if (this.isMobile) {
      return [
        {
          icon: 'newspaper-toolbar',
          text: 'Dashboard',
          to: '/',
        },
        {
          icon: 'user-group-toolbar',
          text:
            this.isAdmin || this.isSuperAdmin || this.isCommunityAssistant
              ? 'Companies & members'
              : 'My company',
          to:
            !this.isAdmin && !this.isSuperAdmin && !this.isCommunityAssistant
              ? `/companies/${
                  this.getUser &&
                  this.getUser.company &&
                  this.getUser.company.id
                }`
              : '/companies',
        },
        {
          icon: 'calendar-toolbar',
          text: 'Bookings',
          to: '/bookings/list',
          guestTo: '/bookings/list',
        },
        {
          icon: 'megaphone-toolbar',
          'icon-alt': 'megaphone-toolbar',
          appendIcon: 'mdi-chevron-down',
          text: 'Community',
          model: false,
          children: [
            {
              icon: '',
              text: 'Contacts',
              to: '/collaboration/contacts',
            },
            { icon: '', text: 'Inbox', to: '/collaboration/inbox' },
            { icon: '', text: 'Posts', to: '/collaboration/posts' },
            { icon: '', text: 'Issues', to: '/collaboration/tickets' },
          ],
        },
      ]
    }

    return [
      {
        icon: 'newspaper-toolbar',
        text: 'Dashboard',
        to: '/',
      },
      {
        icon: 'user-group-toolbar',
        text:
          this.isAdmin || this.isSuperAdmin || this.isCommunityAssistant
            ? 'Companies & members'
            : 'My company',
        to:
          !this.isAdmin && !this.isSuperAdmin && !this.isCommunityAssistant
            ? `/companies/${
                this.getUser && this.getUser.company && this.getUser.company.id
              }`
            : '/companies',
      },
      {
        icon: 'search-toolbar',
        text: 'Search',
        to: '/search',
        disabled:
          !this.isAdmin && !this.isSuperAdmin && !this.isCommunityAssistant,
      },
      // {
      //   icon: 'car-toolbar',
      //   text: 'Parkings',
      //   to: '/bookings/parking',
      //   disabled:
      //     !this.isAdmin && !this.isSuperAdmin && !this.isCommunityAssistant,
      // },
      {
        icon: 'calendar-toolbar',
        text: 'Bookings',
        to: '/bookings/list',
        guestTo: '/bookings/list',
      },
      {
        icon: 'megaphone-toolbar',
        'icon-alt': 'megaphone-toolbar',
        appendIcon: 'mdi-chevron-down',
        text: 'Community',
        model: false,
        children: [
          {
            icon: '',
            text: 'Contacts',
            to: '/collaboration/contacts',
          },
          { icon: '', text: 'Inbox', to: '/collaboration/inbox' },
          { icon: '', text: 'Posts', to: '/collaboration/posts' },
          { icon: '', text: 'Issues', to: '/collaboration/tickets' },
        ],
      },
      {
        icon: 'file-type-table-toolbar',
        'icon-alt': 'file-type-table-toolbar',
        appendIcon: 'mdi-chevron-down',
        text: 'Reports',
        model: false,
        disabled: !this.isSuperAdmin,
        children: [
          {
            icon: '',
            text: 'Space Utilization',
            to: '/reports/space-utilization',
          },
          { icon: '', text: 'P&L', to: '/reports/P&L' },

          {
            icon: '',
            text: 'Revenue by member',
            to: '/reports/revenue-by-member',
          },
          {
            icon: '',
            text: 'Revenue by space',
            to: '/reports/revenue-by-space',
          },
          {
            icon: '',
            text: 'Average desk price',
            to: '/reports/average-desk-price',
          },
          {
            icon: '',
            text: 'Monthly occupancy',
            to: '/reports/monthly-occupancy',
          },
          {
            icon: '',
            text: 'Monthly costs',
            to: '/reports/monthly-costs',
          },
          {
            icon: '',
            text: 'Industry segmentation',
            to: '/reports/industry-segmentation',
          },
          {
            icon: '',
            text: 'Offices utilization',
            to: '/reports/utilization/offices',
          },
          {
            icon: '',
            text: 'Rooms utilization',
            to: '/reports/utilization/rooms',
          },
          {
            icon: '',
            text: 'Parking utilization',
            to: '/reports/utilization/parkings',
          },
          {
            icon: '',
            text: 'Realization',
            to: '/reports/realization',
          },
        ],
      },
      {
        icon: 'dollars2',
        'icon-alt': 'dollars2',
        appendIcon: 'mdi-chevron-down',
        text: 'Finance',
        disabled: !this.isAdmin && !this.isSuperAdmin,
        model: false,
        children: [
          { icon: '', text: 'Invoices', to: '/finance/invoices' },
          {
            icon: '',
            text: 'Bulk preinvoicing',
            to: '/finance/preinvoice-generator',
          },
          { icon: '', text: 'Payments', to: '/finance/payments' },
          { icon: '', text: 'Costs', to: '/finance/costs' },
          { icon: '', text: 'Contracts', to: '/finance/contracts' },
          { icon: '', text: 'One of fees', to: '/finance/one-of-fees' },
          { icon: '', text: 'Fees', to: '/finance/fees' },
        ],
      },
      {
        icon: 'gear4',
        'icon-alt': 'gear4',
        appendIcon: 'mdi-chevron-down',
        text: 'Settings',
        model: false,
        disabled: !this.isAdmin && !this.isSuperAdmin,
        children: [
          { icon: '', text: 'General', to: '/settings' },
          { icon: '', text: 'Currency', to: '/settings/currencies' },
          { icon: '', text: 'Spaces', to: '/settings/spaces' },
          { icon: '', text: 'Extras', to: '/settings/extras' },
          { icon: '', text: 'Contracts', to: '/settings/contracts' },
          { icon: '', text: 'Prices', to: '/settings/prices' },
          // { icon: '', text: 'Booking', to: '/settings/booking' },
          { icon: '', text: 'Collaboration', soon: true },
          { icon: '', text: 'Suppliers', to: '/settings/suppliers' },
          { icon: '', text: 'Terms of Use', to: '/settings/terms' },
          { icon: '', text: 'Apps', to: '/settings/apps', soon: true },
        ],
      },
      {
        icon: 'check_circle_outline',
        'icon-alt': 'check_circle_outline',
        text: 'Logs',
        to: '/logs',
        disabled: !this.isAdmin && !this.isSuperAdmin,
      },
    ]
  }

  async setupUser(): Promise<boolean> {
    try {
      const res = await this.$axios.$get('/user/current')
      if (!res || !res.data) {
        this.error = 'Invalid login credentials'
        return false
      }
      await this.setUser(res.data)
    } catch {
      this.error = 'Invalid login credentials'
      return false
    }
    return true
  }

  async created() {
    this.$vuetify.theme.dark = localStorage.getItem('color-theme') === 'dark'
    if (
      !localStorage.getItem(process.env.TOKEN_NAME) ||
      !(await this.setupUser())
    ) {
      console.log('no user loggd in, redirecting...')
      window.location.href = `${window.location.origin}/login`
      return
    }
    try {
      await Promise.all([this.setupCurrencies(), this.setupNotifications()])
    } catch (error) {
      console.error(error)
    }
  }

  @Watch('$vuetify.theme.dark')
  onDarkThemeChanged(value) {
    localStorage.setItem('color-theme', value ? 'dark' : 'light')
  }

  fab: boolean = false

  // notification controller
  ns: any[] = []

  yielder = {
    fired: false,
    fn: this.showNotifications,
    timeout: 750,
  }

  addNotification(n) {
    for (const notification of this.ns) {
      if (notification.message === n.message) {
        return
      }
    }
    this.ns.push(n)
  }

  fireNotification(n) {
    this.addNotification(n)
    if (this.yielder.fired) {
      return
    }
    this.yielder.fired = true
    setTimeout(() => {
      this.yielder.fn()
      this.yielder.fired = false
      this.ns = []
    }, this.yielder.timeout)
  }

  showNotifications() {
    for (const n of this.ns) {
      this.$toast.open(n)
    }
  }

  showDialog(dialog) {
    this.infoDialogOpen = true
    this.infoDialog = dialog
  }

  get isMobile() {
    const vp = this.$vuetify.breakpoint.name

    return vp === 'xs' || vp === 'sm'
  }

  mounted() {
    this.$socket.client.connect()
    initSocketUser()
    this.$bus.$on('send-notification', (notification) => {
      if (this.$route.fullPath === '/login') {
        return
      }
      this.fireNotification(notification)
    })

    this.$bus.$on('show-dialog', (dialog) => {
      this.showDialog(dialog)
    })

    // this.$bus.$on('show-confirm', ({ text, id }) => {
    //   this.confirmDialogOpen = true
    //   this.confirmDialog = {
    //     text,
    //     id,
    //   }
    // })
  }
}
