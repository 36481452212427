export class SpaceType {
  id: number | undefined
  name: string
  fundamentalSpaceType: object | null

  constructor() {
    this.name = ''
    this.fundamentalSpaceType = null
  }
}
