import { PassType } from '@/entities/passType.ts'

export const state = () => ({
  passType: new PassType(),
})

export const getters = {
  getPassType: (state) => {
    return state.passType
  },
}

export const mutations = {
  SET_PASS_TYPE: (state, payload) => {
    state.passType = payload
  },
}

export const actions = {
  setPassType({ commit }, payload) {
    commit('SET_PASS_TYPE', payload)
  },

  async getPassTypeById({ commit }, id) {
    const r = await this.$axios.$get(`/pass-type/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: passType')
    }
    commit('SET_PASS_TYPE', r.data)
  },

  async createPassType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PASS_TYPE', payload)
    }
    const r = await this.$axios.$post(`/pass-type`, getters.getPassType)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: passType')
    }
    commit('SET_PASS_TYPE', r.data)
  },

  async savePassType({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PASS_TYPE', payload)
    }
    const r = await this.$axios.$put(
      `/pass-type/${getters.getPassType.id}`,
      getters.getPassType
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: passType')
    }
    commit('SET_PASS_TYPE', r.data)
  },

  async deletePassType({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_PASS_TYPE', payload)
    await this.$axios.$delete(`/pass-type/${getters.getPassType.id}`)
    commit('SET_PASS_TYPE', null)
  },
}
