export const state = () => ({
  contractStatuses: ['ACTIVE', 'EXPIRED', 'CREATED'],
})

export const getters = {
  getContractStatuses: (state) => state.contractStatuses,
}
export const mutations = {}

export const actions = {}
