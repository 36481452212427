export const state = () => ({
  contractDepositStatuses: [],
})

export const getters = {
  getContractDepositStatuses: (state) => {
    return state.contractDepositStatuses
  },
}

export const mutations = {
  SET_CONTRACT_DEPOSIT_STATUSES: (state, contractDepositStatuses) => {
    state.contractDepositStatusS = contractDepositStatuses
  },
}

export const actions = {
  setContractDepositStatuses({ commit }, contractDepositStatuses) {
    commit('SET_CONTRACTDEPOSITSTATUSES', contractDepositStatuses)
  },

  async setupContractDepositStatuses({ commit, getters }) {
    if (
      !getters.getContractDepositStatuses ||
      getters.getContractDepositStatuses.length === 0
    ) {
      const r = await this.$axios.$get(`/contract-deposit-status`)
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting contractDepositStatus'
        )
      }
      commit('SET_CONTRACT_DEPOSIT_STATUSES', r.data)
    }
  },
}
