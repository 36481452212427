import { UserLocationPass } from '@/entities/userLocationPass.ts'

export const state = () => ({
  userLocationPass: new UserLocationPass(),
})

export const getters = {
  getUserLocationPass: (state) => {
    return state.userLocationPass
  },
}

export const mutations = {
  SET_USER_LOCATION_PASS: (state, payload) => {
    state.userLocationPass = payload
  },
}

export const actions = {
  setUserLocationPass({ commit }, payload) {
    commit('SET_USER_LOCATION_PASS', payload)
  },

  async getUserLocationPassById({ commit }, id) {
    const r = await this.$axios.$get(`/user-location-pass/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: userLocationPass')
    }
    commit('SET_USER_LOCATION_PASS', r.data)
  },

  async createUserLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_USER_LOCATION_PASS', payload)
    }
    const r = await this.$axios.$post(
      `/user-location-pass`,
      getters.getUserLocationPass
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: userLocationPass')
    }
    commit('SET_USER_LOCATION_PASS', r.data)
  },

  async saveUserLocationPass({ commit, getters }, payload) {
    if (payload) {
      commit('SET_USER_LOCATION_PASS', payload)
    }
    const r = await this.$axios.$put(
      `/user-location-pass/${getters.getUserLocationPass.id}`,
      getters.getUserLocationPass
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: userLocationPass')
    }
    commit('SET_USER_LOCATION_PASS', r.data)
  },

  async deleteUserLocationPass({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_USER_LOCATION_PASS', payload)
    await this.$axios.$delete(
      `/user-location-pass/${getters.getUserLocationPass.id}`
    )
    commit('SET_USER_LOCATION_PASS', null)
  },
}
