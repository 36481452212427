import Vue from 'vue'

Vue.prototype.$discount = function (value, discount) {
  const d = Number(discount)
  const v = Number(value)
  if (d > 100 || v < 0) {
    return 0
  }
  return v * ((100 - d) / 100)
}
