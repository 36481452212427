export const state = () => ({
  fundamentalSpaceTypes: [],
})

export const getters = {
  getFundamentalSpaceTypes: (state) => {
    return state.fundamentalSpaceTypes
  },
}

export const mutations = {
  SET_FUNDAMENTAL_SPACE_TYPES: (state, fundamentalSpaceTypes) => {
    state.fundamentalSpaceTypes = fundamentalSpaceTypes
  },
}

export const actions = {
  setFundamentalSpaceTypes({ commit }, fundamentalSpaceTypes) {
    commit('SET_FUNDAMENTALSPACETYPES', fundamentalSpaceTypes)
  },

  async setupFundamentalSpaceTypes({ commit }) {
    const r = await this.$axios.$get(`/fundamental-space-type`)
    if (!r || !r.data) {
      throw new Error(
        'response format error: error getting fundamentalSpaceType'
      )
    }
    commit('SET_FUNDAMENTAL_SPACE_TYPES', r.data)
  },
}
