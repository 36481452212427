export const state = () => ({
  roomPeriodPrices: [],
})

export const getters = {
  getRoomPeriodPrices: (state) => {
    return state.roomPeriodPrices
  },
}

export const mutations = {
  SET_ROOM_PERIOD_PRICES: (state, roomPeriodPrices) => {
    state.roomPeriodPriceS = roomPeriodPrices
  },
}

export const actions = {
  setRoomPeriodPrices({ commit }, roomPeriodPrices) {
    commit('SET_ROOMPERIODPRICES', roomPeriodPrices)
  },

  async setupRoomPeriodPrices({ commit }) {
    const r = await this.$axios.$get(`/room-period-price`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting roomPeriodPrice')
    }
    commit('SET_ROOM_PERIOD_PRICES', r.data)
  },
}
