import { DepositStatus } from '@/entities/depositStatus.ts'

export const state = () => ({
  depositStatus: new DepositStatus(),
})

export const getters = {
  getDepositStatus: (state) => {
    return state.depositStatus
  },
}

export const mutations = {
  SET_DEPOSIT_STATUS: (state, payload) => {
    state.depositStatus = payload
  },
}

export const actions = {
  setDepositStatus({ commit }, payload) {
    commit('SET_DEPOSIT_STATUS', payload)
  },

  async getDepositStatusById({ commit }, id) {
    const r = await this.$axios.$get(`/deposit-status/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: depositStatus')
    }
    commit('SET_DEPOSIT_STATUS', r.data)
  },

  async createDepositStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_DEPOSIT_STATUS', payload)
    }
    const r = await this.$axios.$post(
      `/deposit-status`,
      getters.getDepositStatus
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: depositStatus')
    }
    commit('SET_DEPOSIT_STATUS', r.data)
  },

  async saveDepositStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_DEPOSIT_STATUS', payload)
    }
    const r = await this.$axios.$put(
      `/deposit-status/${getters.getDepositStatus.id}`,
      getters.getDepositStatus
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: depositStatus')
    }
    commit('SET_DEPOSIT_STATUS', r.data)
  },

  async deleteDepositStatus({ commit, getters }, payload) {
    if (payload) {
      commit('SET_DEPOSIT_STATUS', payload)
    }
    await this.$axios.$delete(`/deposit-status/${getters.getDepositStatus.id}`)
    commit('SET_DEPOSIT_STATUS', null)
  },
}
