export class OneOfFeeSpacePeriodPackagePriceOffice {
  id: number | undefined
  active: boolean
  desks: number
  free: boolean
  discount: object | null
  totalPrice: object | null
  deskPrice: object | null
  dateCreated: Date | string
  oneOfFee: object | null
  spacePeriodPackagePrice: object | null

  constructor() {
    this.active = false
    this.desks = 0
    this.free = false
    this.discount = 0
    this.totalPrice = null
    this.deskPrice = null
    this.dateCreated = ''
    this.oneOfFee = null
    this.spacePeriodPackagePrice = null
  }
}
