export const state = () => ({
  industries: [],
})

export const getters = {
  getIndustries: (state) => {
    return state.industries
  },
}

export const mutations = {
  SET_INDUSTRIES: (state, industries) => {
    state.industries = industries
  },
}

export const actions = {
  setIndustries({ commit }, industries) {
    commit('SET_INDUSTRIES', industries)
  },

  async setupIndustries({ commit }) {
    const r = await this.$axios.$get(`/industry`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting industry')
    }
    commit('SET_INDUSTRIES', r.data)
  },
}
