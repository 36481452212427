export class UserPostComment {
  id: number | undefined
  userPost: object | null
  user: object | null
  comment: string
  createDate?: Date | string

  constructor() {
    this.userPost = null
    this.user = null
    this.comment = ''
    this.createDate = ''
  }
}
