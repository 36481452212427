import { PreInvoice } from '@/entities/preInvoice.ts'

export const state = () => ({
  preInvoice: new PreInvoice(),

  preInvoiceContracts: [],
  preInvoiceOneOfFees: [],
})

export const getters = {
  getPreInvoice: (state) => {
    return state.preInvoice
  },

  getPreInvoiceContracts: (state) => state.preInvoiceContracts,
  getPreInvoiceOneOfFees: (state) => state.preInvoiceOneOfFees,
}

export const mutations = {
  SET_PRE_INVOICE: (state, payload) => {
    state.preInvoice = payload
  },

  SET_PRE_INVOICE_CONTRACTS: (state, payload) => {
    state.preInvoiceContracts = payload
  },

  SET_PRE_INVOICE_ONE_OF_FEES: (state, payload) => {
    state.preInvoiceOneOfFees = payload
  },
}

export const actions = {
  setPreInvoice({ commit }, payload) {
    commit('SET_PRE_INVOICE', payload)
  },

  async getPreInvoiceById({ commit }, id) {
    const r = await this.$axios.$get(`/pre-invoice/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: preInvoice')
    }
    commit('SET_PRE_INVOICE', r.data)
  },

  async setupPreInvoiceContracts({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/pre-invoice/contract-period`
    )
    commit('SET_PRE_INVOICE_CONTRACTS', data)
  },

  async setupPreInvoiceOneOfFees({ commit }, companyID) {
    const { data } = await this.$axios.$get(
      `/company/${companyID}/pre-invoice/one-of-fee-period`
    )
    commit('SET_PRE_INVOICE_ONE_OF_FEES', data)
  },

  async createPreInvoice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE', payload)
    }
    const r = await this.$axios.$post(`/pre-invoice`, getters.getPreInvoice)
    if (!r || !r.data) {
      throw new Error('error in response format on creating: preInvoice')
    }
    commit('SET_PRE_INVOICE', r.data)
  },

  async savePreInvoice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE', payload)
    }
    const r = await this.$axios.$put(
      `/pre-invoice/${getters.getPreInvoice.id}`,
      getters.getPreInvoice
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: preInvoice')
    }
    commit('SET_PRE_INVOICE', r.data)
  },

  async deletePreInvoice({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE', payload)
    }
    await this.$axios.$delete(`/pre-invoice/${getters.getPreInvoice.id}`)
    commit('SET_PRE_INVOICE', null)
  },

  async deletePreInvoiceByID(_, id) {
    await this.$axios.$delete(`/pre-invoice/${id}`)
  },

  async patchPreInvoiceStatus(_, { id, statusID }) {
    await this.$axios.$patch(`/pre-invoice/${id}/invoice-status/${statusID}`)
  },
}
