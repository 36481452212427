export const state = () => ({
  suppliers: [],
})

export const getters = {
  getSuppliers: (state) => {
    return state.suppliers
  },
}

export const mutations = {
  SET_COMPANYS: (state, suppliers) => {
    state.suppliers = suppliers
  },
}

export const actions = {
  setSuppliers({ commit }, suppliers) {
    commit('SET_COMPANYS', suppliers)
  },

  async setupSuppliers({ commit }) {
    const r = await this.$axios.$get(`/supplier`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting supplier')
    }
    commit('SET_COMPANYS', r.data)
  },
}
