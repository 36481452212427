export class PassType {
    id: number | undefined
	name: string 
	days: number 
	passList: object | null

	constructor() {
		this.name = ""
		this.days = 0
		this.passList = []
	}
}
