import Vue from 'vue'

import { SPACE_TYPES } from '@/config/constants'

const OFFICE_ID = 1
const ROOM_ID = 2

export const state = () => ({
  spaces: [],
  floorSpaces: {},
  locationSpaces: {},
  spacesWithAdditions: [],
})

export const getters = {
  getSpaces: (state) => {
    return state.spaces
  },

  getSpacesWithAdditions: (state) => {
    return state.spacesWithAdditions
  },

  getRoomsWithAdditions: (state) => {
    return state.spacesWithAdditions.filter(
      (room) => room.spaceType.fundamentalSpaceType.id === SPACE_TYPES.ROOM
    )
  },

  getSpacesByFloorID: (state) => (floorID) => {
    if (!state.floorSpaces) {
      state.floorSpaces = {
        [floorID]: [],
      }
    }
    return state.floorSpaces[floorID]
  },

  getSpacesByType: (state) => (spaceTypeID) => {
    return state.spaces.filter(
      (e) => e.spaceType && e.spaceType.id === spaceTypeID
    )
  },

  getSpacesByLocationID: (state) => (locationID) => {
    if (state.locationSpaces && state.locationSpaces[locationID]) {
      return state.locationSpaces[locationID]
    }
    return []
  },

  getOfficesByLocationID: (_, getters) => (locationID) => {
    return getters.getSpacesByLocationID(locationID).filter((e) => {
      return e?.spaceType?.fundamentalSpaceType?.id === OFFICE_ID
    })
  },
}

export const mutations = {
  SET_SPACES: (state, spaces) => {
    state.spaces = spaces
  },

  SET_SPACES_WITH_ADDITIONS: (state, rooms) => {
    state.spacesWithAdditions = rooms
  },

  SET_SPACES_BY_FLOOR_ID(state, { floorID, spaces }) {
    if (!state.floorSpaces) {
      state.floorSpaces = {}
    }
    Vue.set(state.floorSpaces, floorID, spaces)
  },

  SET_SPACES_BY_LOCATION_ID(state, { locationID, spaces }) {
    Vue.set(state.locationSpaces, locationID, spaces)
  },
}

export const actions = {
  setSpaces({ commit }, spaces) {
    commit('SET_SPACES', spaces)
  },

  async setupSpaces({ commit }) {
    const r = await this.$axios.$get(`/space`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting space')
    }
    commit('SET_SPACES', r.data)
  },

  async setupSpacesByFloorID({ commit }, floorID) {
    const r = await this.$axios.$get(`/floor/${floorID}/space`)
    if (!r || !r.data) {
      alert('response format error')
      throw new Error('error getting spaces by floorID')
    }
    commit('SET_SPACES_BY_FLOOR_ID', { floorID, spaces: r.data })
    return r.data
  },

  async setupSpacesByLocationID({ commit }, locationID) {
    const r = await this.$axios.$get(`/location/${locationID}/space`)
    if (!r || !r.data) {
      alert('response format error')
      throw new Error('error getting spaces by floorID')
    }
    commit('SET_SPACES_BY_LOCATION_ID', { locationID, spaces: r.data })
    return r.data
  },

  async setupOfficesByLocationID({ dispatch }, locationID) {
    const spaces = await dispatch('setupSpacesByLocationID', locationID)
    return spaces.filter(
      (e) => e?.spaceType?.fundamentalSpaceType?.id === OFFICE_ID
    )
  },

  async setupRoomsByLocationID({ dispatch }, locationID) {
    const spaces = await dispatch('setupSpacesByLocationID', locationID)
    return spaces.filter(
      (e) => e?.spaceType?.fundamentalSpaceType?.id === ROOM_ID
    )
  },

  async setupSpacesWithAdditions({ commit }) {
    const r = await this.$axios.$get(`/space/space-room-addition`)
    if (!r || !r.data) {
      throw new Error('error getting spaces with additions')
    }
    commit('SET_SPACES_WITH_ADDITIONS', r.data)
    return r.data
  },
}
