export enum ROLE {
  ADMIN = 1,
  TEAM_LEADER = 2,
  TEAM_MEMBER = 3,
  SUPER_ADMIN = 4,
}

enum SPACE_TYPES {
  OFFICE = 1,
  ROOM,
}

export { SPACE_TYPES }

const invoiceColors = {
  'NOT SENT': 'error',
  SENT: 'success',
}

export function invoiceStatusColor(status: string) {
  return invoiceColors[status] || 'accent'
}

const paymentStatusColorMap = {
  'NOT PAID': 'error',
  'PARTIALLY PAID': 'warning',
  PAID: '#df48ef',
}

export function paymentStatusColor(status: string) {
  return paymentStatusColorMap[status] || 'accent'
}

const contractInvoiceStatus = {
  'NOT INVOICED': 'error',
  INVOICED: 'primary',
}

const sefStatusColorMap = {
  'SENT': 'success',
  'NOT_SENT': 'gray',
  'FAILED_TO_SEND': 'warning',
}

export function sefStatusColor(status: string) {
  return sefStatusColorMap[status] || 'accent'
}

export function contractInvoiceStatusColor(status: string) {
  return contractInvoiceStatus[status] || 'accent'
}

const contractStatus = {
  ACTIVE: 'success',
  CREATED: 'primary',
  EXPIRED: 'accent',
}

export function contractStatusColor(status: string) {
  return contractStatus[status] || 'accent'
}
