export const UserStatusID = {
  ACTIVE: 1,
}

export class User {
  id: number | undefined
  username: string
  password: string
  name: string
  role: object | null
  company: object | null
  location: Location | null
  space: object | null
  email: string
  phone: string
  deskCardId: string
  comment: string
  showInAllContacts: boolean
  bookingPrivilege: boolean
  status: object | null
  startDate: Date | string
  recievedOn: Date | string
  endDate: Date | string
  publicProfile: boolean

  constructor() {
    this.username = ''
    this.password = ''
    this.name = ''
    this.role = null
    this.company = null
    this.location = null
    this.space = null
    this.email = ''
    this.phone = ''
    this.deskCardId = ''
    this.comment = ''
    this.showInAllContacts = false
    this.bookingPrivilege = false
    this.status = null
    this.startDate = ''
    this.recievedOn = ''
    this.endDate = ''
    this.publicProfile = false
  }

  from(u: User): User {
    this.id = u.id
    this.username = u.username
    this.password = u.password
    this.name = u.name
    this.role = u.role
    this.company = u.company
    this.location = u.location
    this.space = u.space
    this.email = u.email
    this.phone = u.phone
    this.deskCardId = u.deskCardId
    this.comment = u.comment
    this.showInAllContacts = u.showInAllContacts
    this.bookingPrivilege = u.bookingPrivilege
    this.status = u.status
    this.startDate = u.startDate ? u.startDate.toString().split('T')[0] : ''
    this.recievedOn = u.recievedOn ? u.recievedOn.toString().split('T')[0] : ''
    this.endDate = u.endDate ? u.endDate.toString().split('T')[0] : ''
    return this
  }
}
