export class ContractDepositStatus {
  id: number | undefined
  depositDate: Date | string
  contract: object | null
  depositStatus: object | null

  constructor() {
    this.depositDate = ''
    this.contract = null
    this.depositStatus = null
  }
}
