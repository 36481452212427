export class Role {
  id: number | undefined
  name: string
  userList: object | null

  constructor() {
    this.name = ''
    this.userList = []
  }
}

export enum Roles {
  Admin = 1,
  'Team leader' = 2,
  'Team member' = 3,
  'Super admin' = 4,
  'Community assistant' = 5,
}
