export class ExtrasCategory {
    id: number | undefined
	name: object | null
	basicUnit: string 
	pricePerUnit: object | null
	extrasList: object | null

	constructor() {
		this.name = null
		this.basicUnit = ""
		this.pricePerUnit = null
		this.extrasList = []
	}
}
