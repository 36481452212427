import { PreInvoiceContract } from '@/entities/preInvoiceContract.ts'

export const state = () => ({
  preInvoiceContract: new PreInvoiceContract(),
})

export const getters = {
  getPreInvoiceContract: (state) => {
    return state.preInvoiceContract
  },
}

export const mutations = {
  SET_PRE_INVOICE_CONTRACT: (state, payload) => {
    state.preInvoiceContract = payload
  },
}

export const actions = {
  setPreInvoiceContract({ commit }, payload) {
    commit('SET_PRE_INVOICE_CONTRACT', payload)
  },

  async getPreInvoiceContractById({ commit }, id) {
    const r = await this.$axios.$get(`/pre-invoice-contract/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: preInvoiceContract')
    }
    commit('SET_PRE_INVOICE_CONTRACT', r.data)
  },

  async createPreInvoiceContract({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE_CONTRACT', payload)
    }
    const r = await this.$axios.$post(
      `/pre-invoice-contract`,
      getters.getPreInvoiceContract
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: preInvoiceContract'
      )
    }
    commit('SET_PRE_INVOICE_CONTRACT', r.data)
  },

  async savePreInvoiceContract({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE_CONTRACT', payload)
    }
    const r = await this.$axios.$put(
      `/pre-invoice-contract/${getters.getPreInvoiceContract.id}`,
      getters.getPreInvoiceContract
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: preInvoiceContract')
    }
    commit('SET_PRE_INVOICE_CONTRACT', r.data)
  },

  async deletePreInvoiceContract({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PRE_INVOICE_CONTRACT', payload)
    }
    await this.$axios.$delete(
      `/pre-invoice-contract/${getters.getPreInvoiceContract.id}`
    )
    commit('SET_PRE_INVOICE_CONTRACT', null)
  },
}
