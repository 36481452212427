import { OneOfFeeSpacePeriodPackagePriceRoom } from '@/entities/oneOfFeeSpacePeriodPackagePriceRoom.ts'

export const state = () => ({
  oneOfFeeSpacePeriodPackagePriceRoom: new OneOfFeeSpacePeriodPackagePriceRoom(),
})

export const getters = {
  getOneOfFeeSpacePeriodPackagePriceRoom: (state) => {
    return state.oneOfFeeSpacePeriodPackagePriceRoom
  },
}

export const mutations = {
  SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM: (state, payload) => {
    state.oneOfFeeSpacePeriodPackagePriceRoom = payload
  },
}

export const actions = {
  setOneOfFeeSpacePeriodPackagePriceRoom({ commit }, payload) {
    commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
  },

  async getOneOfFeeSpacePeriodPackagePriceRoomById({ commit }, id) {
    const r = await this.$axios.$get(
      `/one-of-fee-space-period-package-price-room/${id}`
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format: oneOfFeeSpacePeriodPackagePriceRoom'
      )
    }
    commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', r.data)
  },

  async createOneOfFeeSpacePeriodPackagePriceRoom(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
    }
    const r = await this.$axios.$post(
      `/one-of-fee-space-period-package-price-room`,
      getters.getOneOfFeeSpacePeriodPackagePriceRoom
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on creating: oneOfFeeSpacePeriodPackagePriceRoom'
      )
    }
    commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', r.data)
  },

  async saveOneOfFeeSpacePeriodPackagePriceRoom({ commit, getters }, payload) {
    if (payload) {
      commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
    }
    const r = await this.$axios.$put(
      `/one-of-fee-space-period-package-price-room/${getters.getOneOfFeeSpacePeriodPackagePriceRoom.id}`,
      getters.getOneOfFeeSpacePeriodPackagePriceRoom
    )
    if (!r || !r.data) {
      throw new Error(
        'error in response format on saving: oneOfFeeSpacePeriodPackagePriceRoom'
      )
    }
    commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', r.data)
  },

  async deleteOneOfFeeSpacePeriodPackagePriceRoom(
    { commit, getters },
    payload
  ) {
    if (payload) {
      commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', payload)
    }
    await this.$axios.$delete(
      `/one-of-fee-space-period-package-price-room/${getters.getOneOfFeeSpacePeriodPackagePriceRoom.id}`
    )
    commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOM', null)
  },
}
