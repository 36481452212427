import Vue from 'vue'

export const state = () => ({
  floors: [],
  locationFloors: {},
})

export const getters = {
  getFloors: (state) => {
    return state.floors
  },
  getFloorsByLocationID: (state) => (locationID) => {
    if (!state.locationFloors) {
      return []
    }
    return state.locationFloors[locationID]
  },
}

export const mutations = {
  SET_FLOORs: (state, floors) => {
    state.floors = floors
  },
  SET_FLOORS_BY_LOCATION_ID(state, { locationID, floors }) {
    if (!state.locationFloors) {
      state.locationFloors = {}
    }
    Vue.set(state.locationFloors, locationID, floors)
  },
}

export const actions = {
  setFloors({ commit }, floors) {
    commit('SET_FLOORs', floors)
  },

  async setupFloors({ commit }) {
    const r = await this.$axios.$get(`/floor`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting floor')
    }
    commit('SET_FLOORs', r.data)
  },

  async setupFloorsByLocationID({ commit, getters }, { locationID, force }) {
    if (getters.getFloorsByLocationID(locationID) && !force) {
      return getters.getFloorsByLocationID(locationID)
    }
    try {
      const r = await this.$axios.$get(`/location/${locationID}/floor`)
      if (!r || !r.data) {
        alert('response format error')
        throw new Error('error getting floors by locationID')
      }
      commit('SET_FLOORS_BY_LOCATION_ID', { locationID, floors: r.data })
      return r.data
    } catch (error) {
      commit('SET_FLOORS_BY_LOCATION_ID', { locationID, floors: [] })
      throw error
    }
  },
}
