export const state = () => ({
  oneOfFeeSpacePeriodPackagePriceRooms: [],
})

export const getters = {
  getOneOfFeeSpacePeriodPackagePriceRooms: (state) => {
    return state.oneOfFeeSpacePeriodPackagePriceRooms
  },
}

export const mutations = {
  SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOMS: (
    state,
    oneOfFeeSpacePeriodPackagePriceRooms
  ) => {
    state.oneOfFeeSpacePeriodPackagePriceRoomS = oneOfFeeSpacePeriodPackagePriceRooms
  },
}

export const actions = {
  setOneOfFeeSpacePeriodPackagePriceRooms(
    { commit },
    oneOfFeeSpacePeriodPackagePriceRooms
  ) {
    commit(
      'SET_ONEOFFEESPACEPERIODPACKAGEPRICEROOMS',
      oneOfFeeSpacePeriodPackagePriceRooms
    )
  },

  async setupOneOfFeeSpacePeriodPackagePriceRooms({ commit, getters }) {
    if (
      !getters.getOneOfFeeSpacePeriodPackagePriceRooms ||
      getters.getOneOfFeeSpacePeriodPackagePriceRooms.length === 0
    ) {
      const r = await this.$axios.$get(
        `/one-of-fee-space-period-package-price-room`
      )
      if (!r || !r.data) {
        throw new Error(
          'response format error: error getting oneOfFeeSpacePeriodPackagePriceRoom'
        )
      }
      commit('SET_ONE_OF_FEE_SPACE_PERIOD_PACKAGE_PRICE_ROOMS', r.data)
    }
  },
}
