export const state = () => ({
  depositStatuses: [],
})

export const getters = {
  getDepositStatuses: (state) => {
    return state.depositStatuses
  },
}

export const mutations = {
  SET_DEPOSIT_STATUSES: (state, depositStatuses) => {
    state.depositStatuses = depositStatuses
  },
}

export const actions = {
  setDepositStatuses({ commit }, depositStatuses) {
    commit('SET_DEPOSIT_STATUSES', depositStatuses)
  },

  async setupDepositStatuses({ commit }) {
    const { data } = await this.$axios.$get(`/deposit-status`)

    commit('SET_DEPOSIT_STATUSES', data)
  },
}
