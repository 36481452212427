import { ParkingPricing } from '@/entities/parkingPricing.ts'

export const state = () => ({
  parkingPricing: new ParkingPricing(),
})

export const getters = {
  getParkingPricing: (state) => {
    return state.parkingPricing
  },
}

export const mutations = {
  SET_PARKING_PRICING: (state, payload) => {
    state.parkingPricing = payload
  },
}

export const actions = {
  setParkingPricing({ commit }, payload) {
    commit('SET_PARKING_PRICING', payload)
  },

  async getParkingPricingById({ commit }, id) {
    const r = await this.$axios.$get(`/parking-pricing/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: parkingPricing')
    }
    commit('SET_PARKING_PRICING', r.data)
  },

  async createParkingPricing({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PARKING_PRICING', payload)
    }
    const r = await this.$axios.$post(
      `/parking-pricing`,
      getters.getParkingPricing
    )
    if (!r || !r.data) {
      throw new Error('error in response format on creating: parkingPricing')
    }
    commit('SET_PARKING_PRICING', r.data)
  },

  async saveParkingPricing({ commit, getters }, payload) {
    if (payload) {
      commit('SET_PARKING_PRICING', payload)
    }
    const r = await this.$axios.$put(
      `/parking-pricing/${getters.getParkingPricing.id}`,
      getters.getParkingPricing
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: parkingPricing')
    }
    commit('SET_PARKING_PRICING', r.data)
  },

  async deleteParkingPricing({ commit, getters }, payload) {
    if (!payload) {
      throw new Error('can not remove invalid object')
    }
    commit('SET_PARKING_PRICING', payload)
    await this.$axios.$delete(
      `/parking-pricing/${getters.getParkingPricing.id}`
    )
    commit('SET_PARKING_PRICING', null)
  },
}
