export class Ticket {
  id: number | undefined
  dateCreated: Date | string
  title: string
  content: string
  user: object | null
  location: object | null

  constructor() {
    this.dateCreated = ''
    this.title = ''
    this.content = ''
    this.user = null
    this.location = null
  }
}
