import Vue from 'vue'

export const state = () => ({
  inbox: {},
})

export const getters = {
  getMessagesForUser: (state) => (uid) => {
    return state.inbox[uid] || []
  },
}

export const mutations = {
  APPEND_MESSAGES_FOR_USER(state, { uid, msgs }) {
    if (!state.inbox) {
      state.inbox = {}
    }
    if (!state.inbox[uid]) {
      Vue.set(state.inbox, uid, [])
    }
    state.inbox[uid].push(...msgs)
  },
  CLEAR_MESSAGES_FOR_USER(state, uid) {
    if (!state.inbox) {
      state.inbox = {}
    }
    Vue.set(state.inbox, uid, [])
  },
}

export const actions = {
  addMessagesForUser({ commit }, payload) {
    commit('APPEND_MESSAGES_FOR_USER', payload)
  },

  addMessageForUser({ commit }, { uid, msg }) {
    console.log('uid: ', uid)
    console.log('msg: ', msg)
    commit('APPEND_MESSAGES_FOR_USER', { uid, msgs: [msg] })
  },
  clearMessagesForUser({ commit }, uid) {
    commit('CLEAR_MESSAGES_FOR_USER', uid)
  },
}
