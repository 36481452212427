export const state = () => ({
  invoiceStatuses: [],
})

export const getters = {
  getInvoiceStatuses: (state) => {
    return state.invoiceStatuses
  },
}

export const mutations = {
  SET_INVOICE_STATUSES: (state, invoiceStatuses) => {
    state.invoiceStatuses = invoiceStatuses
  },
}

export const actions = {
  setInvoiceStatuses({ commit }, invoiceStatuses) {
    commit('SET_INVOICE_STATUSES', invoiceStatuses)
  },

  async setupInvoiceStatuses({ commit }) {
    const r = await this.$axios.$get(`/invoice-status`)
    if (!r || !r.data) {
      throw new Error('response format error: error getting invoiceStatus')
    }
    commit('SET_INVOICE_STATUSES', r.data)
  },
}
