export class OneOfFeeUserLocationPassEntry {
  id: number | undefined
  timestamp: Date | string
  oneOfFeeUserLocationPass: object | null

  constructor() {
    this.timestamp = ''
    this.oneOfFeeUserLocationPass = null
  }
}
