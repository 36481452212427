export class DepositStatus {
    id: number | undefined
	name: string 
	contractDepositeStatusSet: object | null

	constructor() {
		this.name = ""
		this.contractDepositeStatusSet = []
	}
}
