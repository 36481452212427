export class Extras {
  id: number | undefined
  name: string
  units: number
  price: object | null
  extrasCategory: object | null
  discount: number

  constructor() {
    this.name = ''
    this.units = 1
    this.price = null
    this.extrasCategory = null
    this.discount = 0
  }
}
