import { UserPost } from '@/entities/userPost.ts'

export const state = () => ({
  userPost: new UserPost(),
})

export const getters = {
  getUserPost: (state) => {
    return state.userPost
  },
}

export const mutations = {
  SET_USER_POST: (state, payload) => {
    state.userPost = payload
  },
}

export const actions = {
  setUserPost({ commit }, payload) {
    commit('SET_USER_POST', payload)
  },

  async getUserPostById({ commit }, id) {
    const r = await this.$axios.$get(`/user-post/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: userPost')
    }
    commit('SET_USER_POST', r.data)
  },

  async createUserPost({ commit }, payload) {
    const { data } = await this.$axios.$post(`/user-post`, payload)
    commit('SET_USER_POST', data)
    return data
  },

  async saveUserPost({ commit, getters }, payload) {
    if (payload) {
      commit('SET_USER_POST', payload)
    }
    const r = await this.$axios.$put(
      `/user-post/${getters.getUserPost.id}`,
      getters.getUserPost
    )
    if (!r || !r.data) {
      throw new Error('error in response format on saving: userPost')
    }
    commit('SET_USER_POST', r.data)
  },

  async deleteUserPost({ commit }, payload) {
    await this.$axios.$delete(`/user-post/${payload.id}`)
    commit('SET_USER_POST', new UserPost())
  },
}
