import { UserPostComment } from '@/entities/userPostComment.ts'

export const state = () => ({
  userPostComment: new UserPostComment(),
})

export const getters = {
  getUserPostComment: (state) => {
    return state.userPostComment
  },
}

export const mutations = {
  SET_USER_POST_COMMENT: (state, payload) => {
    state.userPostComment = payload
  },
}

export const actions = {
  setUserPostComment({ commit }, payload) {
    commit('SET_USER_POST_COMMENT', payload)
  },

  async getUserPostCommentById({ commit }, id) {
    const r = await this.$axios.$get(`/user-post-comment/${id}`)
    if (!r || !r.data) {
      throw new Error('error in response format: userPostComment')
    }
    commit('SET_USER_POST_COMMENT', r.data)
  },

  async createUserPostComment({ commit }, { postID, payload }) {
    if (!payload) {
      throw new Error('paylaod must be provided')
    }
    const { data } = await this.$axios.$post(
      `/user-post/${postID}/user-post-comment`,
      payload
    )
    commit('SET_USER_POST_COMMENT', data)
  },

  async saveUserPostComment({ commit }, payload) {
    if (!payload) {
      throw new Error('paylaod must be provided')
    }
    const { data } = await this.$axios.$put(
      `/user-post-comment/${payload.id}`,
      payload
    )
    commit('SET_USER_POST_COMMENT', data)
  },

  async deleteUserPostComment({ commit }, payload) {
    if (!payload) {
      throw new Error('payload must be provided')
    }
    if (!payload.id) {
      throw new Error('paylaod MUST have id')
    }
    await this.$axios.$delete(`/user-post-comment/${payload.id}`)
    commit('SET_USER_POST_COMMENT', null)
  },
}
